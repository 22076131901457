import { ChartConfigChartPane } from '../../types/shared'
import { TIMEFRAME } from '../constants/common'
import { getCanvasElementByType } from './get-canvas-element-by-type'

export const getChartQuoteFetchBufferConfig = ({
  panes,
  timeframe,
}: {
  panes: ChartConfigChartPane[]
  timeframe: TIMEFRAME
}) => {
  const maxNumOfBarsBuffer = panes
    .flatMap(({ elements }) => elements)
    .reduce(
      (acc: { barsBuffer: number; dateFrom: number | undefined }, element) => {
        const canvasElement = getCanvasElementByType(element.type)!
        const dateFrom = canvasElement.getDateFromBuffer({
          ...element,
          positionTimestamps: element.positionTimestamps ?? {},
        })
        const barsBuffer = canvasElement.getNumOfBarsBuffer({
          ...element,
          overlays: element.overlays!,
          // There has to be || because some e.g. ideas may have empty string periods
          period: element.period || '0',
          timeframe,
        })
        // If element period is edited to '', value will be parsed as NaN, and Math.max(NaN, {anyNumber}) will return NaN
        return {
          barsBuffer: Number.isNaN(barsBuffer) ? acc.barsBuffer : Math.max(barsBuffer, acc.barsBuffer),
          dateFrom: dateFrom !== undefined ? Math.min(dateFrom, acc.dateFrom ?? Infinity) : acc.dateFrom,
        }
      },
      { barsBuffer: 0, dateFrom: undefined }
    )

  // we add 1 to barsBuffer so we can ensure lines continuity on offscreen charts
  return { barsBuffer: maxNumOfBarsBuffer.barsBuffer + 1, dateFrom: maxNumOfBarsBuffer.dateFrom }
}
