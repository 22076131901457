import {
  ChartConfigChartPaneElement,
  DrawingBorder,
  PaneArea,
  RequireByKey,
  ResizeByThumbFuncProps,
} from '../../../types/shared'
import { CanvasElementType, TextBaseline } from '../../constants/common'
import math from '../../helpers/math'
import { VWapCalculation } from '../../indicator-calculation/vwap'
import Chart from '../../models/chart'
import { elementDarkTheme } from '../../models/constants'
import PaneModel from '../../models/pane'
import { convertColorToHEX, getIsColorTooLightOrTransparent, hexStringToRGBA } from '../../utils/colors'
import Element from '../element'
import Text from '../text'
import Thumb from '../thumb'

const DEFAULT_DECIMAL_PLACES = 2

export interface IAVWAPAttrs {
  x: number
  y: number
  lineWidth: number
  border: DrawingBorder
}

class AVWAP<Attrs extends IAVWAPAttrs = IAVWAPAttrs> extends Element<IAVWAPAttrs> {
  static type = CanvasElementType.avwapV1

  name = 'Anchored VWAP'
  decimalPlaces = DEFAULT_DECIMAL_PLACES
  avwapPath: [number, number][] = []
  scaledAvwapPath: [number, number][] = []
  declare vwapCalculation: VWapCalculation
  declare prevX: number
  declare quoteFetchedAt: number
  declare labelColor: {
    fillStyle: string
    background: string
  }

  static getDateFromBuffer(element: RequireByKey<ChartConfigChartPaneElement, 'positionTimestamps'>) {
    return element.positionTimestamps.x
  }

  constructor(values: Partial<Attrs>, model: PaneModel) {
    super(values, model)

    this.updateDecimalPlaces()
    this.computeAvwapPath()
  }

  set(obj: Partial<IAVWAPAttrs>) {
    super.set(obj)

    const labelBackgroundColor = convertColorToHEX(this.attrs.border.color, false)
    const isTooLight = getIsColorTooLightOrTransparent(hexStringToRGBA(labelBackgroundColor))
    this.labelColor = {
      fillStyle: isTooLight ? '#000' : '#fff',
      background: labelBackgroundColor,
    }

    return this
  }

  updateDecimalPlaces() {
    const quote = this.model.chart().quote()
    this.decimalPlaces = quote
      ? math.getInstrumentDecimalPlaces({
          instrument: quote.instrument,
          value: quote.lastClose,
        })
      : DEFAULT_DECIMAL_PLACES
  }

  computeAvwapPath() {
    const quote = this.model.chart().quote()
    if (!quote || (this.prevX === this.attrs.x && this.quoteFetchedAt === quote.fetchedAt)) return

    this.avwapPath = []
    this.quoteFetchedAt = quote.fetchedAt
    this.prevX = this.attrs.x
    const barIndex = Math.max(Math.round(this.model.scale.x.invert(this.fx(this.attrs.x))), 0) // depending on timeframe and attrs.x, barIndex may be negative but we have lower boundary of 0
    const dataIndex = quote.barToDataIndex[Math.min(barIndex, quote.barToDataIndex.length - 1)]

    this.vwapCalculation = new VWapCalculation({
      quote,
      options: { period: 5 }, // VWAP period is unused parameter, TS requires it due MainCalculation
    })
    this.vwapCalculation.calculateFrom(dataIndex, false)

    for (let i = barIndex; i < quote.barToDataIndex.length; i++) {
      this.avwapPath.push([i, this.vwapCalculation.calculatedValues.vwap[quote.getDataIndexByBarIndex(i)]])
    }

    if (this.avwapPath[0]) this.attrs.y = this.avwapPath[0][1]

    this.scaleAvwapPath(true)
  }

  scaleAvwapPath(forceRecalculate = false) {
    const lastAvwapPointIndex = this.avwapPath.length - 1
    if (
      !forceRecalculate &&
      this.scaledAvwapPath?.[lastAvwapPointIndex]?.[1] === this.fy(this.avwapPath?.[lastAvwapPointIndex]?.[1]) &&
      this.scaledAvwapPath?.[lastAvwapPointIndex]?.[0] === this.fx(this.avwapPath?.[lastAvwapPointIndex]?.[0])
    )
      return

    this.scaledAvwapPath = this.avwapPath.map(([x, y]) => [this.fx(x), this.fy(y)])
  }

  getDefaults() {
    return {
      border: {
        width: 1,
        color: elementDarkTheme.border,
      },
    } as Partial<Attrs>
  }

  getBoundingPointKeys = () => ({ x: ['x'], y: ['y'] })

  renderContent(context: CanvasRenderingContext2D) {
    this.computeAvwapPath()
    this.scaleAvwapPath() // Called again after computeAvwapPath to handle axis scale changes that may have occurred

    context.beginPath()
    context.set('lineWidth', this.attrs.border.width)
    context.set('strokeStyle', this.attrs.border.color)
    this.scaledAvwapPath.forEach(([x, y]) => context.lineTo(x, y))
    context.stroke()

    if (this.getShouldRenderThumbs() || this.avwapPath.length === 0) {
      this.renderThumbs(context)
    }
  }

  renderLabels(context: CanvasRenderingContext2D) {
    const lastAvwapPointIndex = this.scaledAvwapPath.length - 1
    if (lastAvwapPointIndex > 0) {
      this.updateDecimalPlaces()
      const { ChartSettings } = this.getChartLayoutSettings()
      const settings = ChartSettings
      context.translate(this.model.chart().width - settings.right.width, settings.top.height)
      new Text(
        {
          x: 6,
          y: this.scaledAvwapPath[lastAvwapPointIndex][1],
          font: { size: 8, weight: 'bold' },
          lineHeight: 8,
          padding: {
            top: 2,
            right: 2,
            bottom: 2,
            left: 2,
          },
          fillStyle: this.labelColor.fillStyle,
          background: this.labelColor.background,
          textBaseline: TextBaseline.middle,
          text: this.avwapPath[lastAvwapPointIndex][1].toFixed(this.decimalPlaces).toString(),
        },
        this.model
      ).render(context)
    }
  }

  getThumbs() {
    return [
      new Thumb(
        'avwap',
        () => this.attrs.x,
        () => this.attrs.y,
        this.moveByThumb,
        this.model
      ),
    ]
  }

  isInArea(area: PaneArea) {
    if (super.isDrawingElementLockedOrInvisible()) return false

    for (let i = 0; i < this.scaledAvwapPath.length - 1; i++) {
      if (
        math.distanceToSegment(area.scaled, {
          x1: this.scaledAvwapPath[i][0],
          y1: this.scaledAvwapPath[i][1],
          x2: this.scaledAvwapPath[i + 1][0],
          y2: this.scaledAvwapPath[i + 1][1],
        }) <= 10
      ) {
        return true
      }
    }

    return super.isInArea(area)
  }

  moveByThumb = ({ difX }: RequireByKey<ResizeByThumbFuncProps, 'difX'>) => this.moveBy(difX)

  moveBy(x: number) {
    this.attrs.x += x
  }

  getIsInChartView(_: Chart) {
    return true
  }
}

AVWAP.prototype.modalConfig = {
  inputs: [{ type: 'line', name: 'border' }],
}

export default AVWAP
