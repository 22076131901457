import { format } from 'date-fns'

import { ObjectHash } from '../../types/shared'
import {
  FUNDAMENTAL_INDICATOR_TYPES,
  FundamentalPeriod,
  FundamentalPeriodLabel,
  IndicatorType,
  QuoteFundamentalAttachmentType,
} from '../constants/common'
import { dateFromUnixTimestamp } from '../utils'
import {
  Attrs,
  FundamentalIndicatorBaseConfig,
  FundamentalIndicatorsConfigs,
  FundamentalUnit,
} from './configs/fundamentalIndicators'
import FinancialIndicator from './financialIndicator'

function getDefaultPeriod(classConstructor: unknown) {
  const config = (classConstructor as typeof FundamentalIndicator).config as typeof FundamentalIndicatorBaseConfig
  return config.periods.includes(FundamentalPeriod.Quarterly) ? FundamentalPeriod.Quarterly : FundamentalPeriod.Annual
}

export class FundamentalIndicator<Attrs extends ObjectHash = ObjectHash> extends FinancialIndicator<Attrs> {
  period: FundamentalPeriod = getDefaultPeriod(this.constructor)

  static getNumOfBarsBuffer() {
    return 0
  }

  static getQuoteFinancialAttachments<Attrs extends ObjectHash = ObjectHash>(
    indicator: Pick<FundamentalIndicator<Attrs>, 'period'>
  ) {
    return [`${this.config.type.replace('indicators/', '')}_${indicator.period}`] as QuoteFundamentalAttachmentType[]
  }

  getComputedValuesAtDateIndex(dateIndex: number) {
    const config = (this.constructor as typeof FundamentalIndicator).config as typeof FundamentalIndicatorBaseConfig
    const attachment = (this.constructor as typeof FundamentalIndicator).getQuoteFinancialAttachments(this)[0]
    const { timestampToFinancialsIndexesMap, alignedFinancialAttachmentsData } = this.data
    const timestampKey = this.data.date[dateIndex]?.toString() ?? ''
    const sourceIndexes = timestampToFinancialsIndexesMap[attachment]?.[timestampKey] ?? []
    const alignedData = alignedFinancialAttachmentsData[attachment] ?? []

    let isBeforeData = false
    if (sourceIndexes.length === 0 && dateIndex === 0) {
      const lastBeforeDateFinancialsDataIndex = alignedData.findLastIndex(
        ({ timestamp }) => timestamp < this.data.date[dateIndex]
      )
      if (lastBeforeDateFinancialsDataIndex >= 0) {
        sourceIndexes.push(lastBeforeDateFinancialsDataIndex)
        isBeforeData = true
      }
    }

    return sourceIndexes.map((si) => {
      const data = alignedData[si]
      let value = data.fundamentalValue
      if (config.unitType === FundamentalUnit.Millions && Number.isFinite(value)) {
        value *= 1_000_000
      }
      return {
        isBeforeData,
        timestamp: data.timestamp,
        value,
        isVirtualMatch: data.isVirtualMatch,
        sourceIndex: si,
      }
    })
  }

  getValueLabelsAtIndex(index: number) {
    const dataIndex = this.data.barToDataIndex[index]
    const values = this.values[dataIndex]
    if (!values) {
      return []
    }

    const color = this.getChartLayoutSettings().IndicatorSettings.general.Colors.line!
    return values.map(({ timestamp, value }) => {
      const valueLabel = this.getValueLabel(value)

      return {
        color,
        text:
          valueLabel !== null ? `${valueLabel} (${format(dateFromUnixTimestamp(timestamp), 'MMM dd, yyyy')})` : null,
      }
    })
  }

  getModalConfig() {
    const config = (this.constructor as typeof FundamentalIndicator).config as typeof FundamentalIndicatorBaseConfig
    const options = {
      period: {
        type: 'buttonSwitch',
        label: 'Fundamental Period',
        name: 'period',
        value: this.period ?? getDefaultPeriod(this.constructor),
        required: true,
        items: [
          {
            value: FundamentalPeriod.Annual,
            label: FundamentalPeriodLabel[FundamentalPeriod.Annual],
            disabled: !config.periods.includes(FundamentalPeriod.Annual),
          },
          {
            value: FundamentalPeriod.Quarterly,
            label: FundamentalPeriodLabel[FundamentalPeriod.Quarterly],
            disabled: !config.periods.includes(FundamentalPeriod.Quarterly),
          },
        ].filter(({ disabled }) => !disabled),
      },
    }

    return {
      title: config.label,
      inputs: FundamentalIndicatorBaseConfig.inputsOrder.map((item) => options[item]),
      inputsErrorMessages: {
        period: '',
      },
    }
  }

  getValueUnit() {
    const config = (this.constructor as typeof FundamentalIndicator).config as typeof FundamentalIndicatorBaseConfig
    if (config.unitType === FundamentalUnit.Percent) {
      return '%'
    }

    return undefined
  }

  renderYAxis(context: CanvasRenderingContext2D, options: { lastValueSuffix?: string } = {}) {
    return super.renderYAxis(context, { ...options, lastValueSuffix: this.getValueUnit() })
  }

  getIsValid() {
    return true
  }
}

function createFundamentalIndicator(config: typeof FundamentalIndicatorBaseConfig) {
  return class extends FundamentalIndicator<Attrs> {
    static config = config
  }
}

export function generateFundamentalIndicators() {
  const indicators: Partial<Record<IndicatorType, typeof FinancialIndicator>> = {}

  FundamentalIndicatorsConfigs.forEach((config) => {
    indicators[config.type] = createFundamentalIndicator(config) as unknown as typeof FinancialIndicator
  })

  return indicators as Record<(typeof FUNDAMENTAL_INDICATOR_TYPES)[number], typeof FinancialIndicator>
}
