import type { ObjectHash } from '../../../types/shared'
import { FundamentalPeriod, FundamentalPeriodShortLabel, IndicatorType } from '../../constants/common'
import IndicatorBaseConfig from './indicatorBaseConfig'

type Options = {
  period: number
}

export enum FundamentalUnit {
  Percent,
  Ratio,
  Millions,
  Actual,
}

export type Attrs = Options

export class FundamentalIndicatorBaseConfig extends IndicatorBaseConfig {
  static optionsOrder = ['period'] as const
  static optionsLabels = { period: 'Period' }
  static inputsOrder = this.optionsOrder
  static inputsLabels = this.optionsLabels
  static periods: FundamentalPeriod[] = []
  static unitType: FundamentalUnit = FundamentalUnit.Actual
  static getShortLabelWithAttrs(attrs: ObjectHash) {
    return `${this.label} - ${FundamentalPeriodShortLabel[attrs.period as FundamentalPeriod]}`
  }
}

export class AccountsPayableConfig extends FundamentalIndicatorBaseConfig {
  static type = IndicatorType.Ap
  static label = 'Accounts Payable'
  static shortLabel = 'A/P'
  static seoLabel = 'Accounts Payable, A/P, AP'
  static abbreviation = 'ap'
  static periods = [FundamentalPeriod.Annual, FundamentalPeriod.Quarterly]
  static unitType = FundamentalUnit.Millions
}

export class BookValuePerShareConfig extends FundamentalIndicatorBaseConfig {
  static type = IndicatorType.Bvps
  static label = 'Book Value Per Share'
  static shortLabel = 'BVPS'
  static seoLabel = 'Book Value Per Share, BVPS'
  static abbreviation = 'bvps'
  static periods = [FundamentalPeriod.Annual, FundamentalPeriod.Quarterly]
  static unitType = FundamentalUnit.Actual
}

export class CapitalExpendituresConfig extends FundamentalIndicatorBaseConfig {
  static type = IndicatorType.Capex
  static label = 'Capital Expenditures'
  static shortLabel = 'CAPEX'
  static seoLabel = 'Capital Expenditures, CAPEX'
  static abbreviation = 'capex'
  static periods = [FundamentalPeriod.Annual, FundamentalPeriod.Quarterly]
  static unitType = FundamentalUnit.Millions
}

export class CashDividendsPaidConfig extends FundamentalIndicatorBaseConfig {
  static type = IndicatorType.Cdp
  static label = 'Cash Dividends Paid'
  static shortLabel = 'CDP'
  static seoLabel = 'Cash Dividends Paid, CDP'
  static abbreviation = 'cdp'
  static periods = [FundamentalPeriod.Annual, FundamentalPeriod.Quarterly]
  static unitType = FundamentalUnit.Millions
}

export class CashShortTermInvestmentsConfig extends FundamentalIndicatorBaseConfig {
  static type = IndicatorType.Csti
  static label = 'Cash & Short Term Investments'
  static shortLabel = 'CSTI'
  static seoLabel = 'Cash and Short Term Investments, CSTI'
  static abbreviation = 'csti'
  static periods = [FundamentalPeriod.Annual, FundamentalPeriod.Quarterly]
  static unitType = FundamentalUnit.Millions
}

export class CommonDividendsConfig extends FundamentalIndicatorBaseConfig {
  static type = IndicatorType.Cd
  static label = 'Common Dividends'
  static shortLabel = 'CD'
  static seoLabel = 'Common Dividends, CD'
  static abbreviation = 'cd'
  static periods = [FundamentalPeriod.Annual, FundamentalPeriod.Quarterly]
  static unitType = FundamentalUnit.Millions
}

export class ConsolidatedNetIncomeConfig extends FundamentalIndicatorBaseConfig {
  static type = IndicatorType.Cni
  static label = 'Consolidated Net Income'
  static shortLabel = 'CNI'
  static seoLabel = 'Consolidated Net Income, CNI'
  static abbreviation = 'cni'
  static periods = [FundamentalPeriod.Annual, FundamentalPeriod.Quarterly]
  static unitType = FundamentalUnit.Millions
}

export class CostOfGoodsSoldConfig extends FundamentalIndicatorBaseConfig {
  static type = IndicatorType.Cogs
  static label = 'Cost of Goods Sold (COGS) including Depreciation and Amortization'
  static shortLabel = 'COGS'
  static seoLabel = 'Cost of Goods Sold (COGS) including Depreciation and Amortization, COGS'
  static abbreviation = 'cogs'
  static periods = [FundamentalPeriod.Annual, FundamentalPeriod.Quarterly]
  static unitType = FundamentalUnit.Millions
}

export class CurrentRatioConfig extends FundamentalIndicatorBaseConfig {
  static type = IndicatorType.Cr
  static label = 'Current Ratio'
  static shortLabel = 'CR'
  static seoLabel = 'Current Ratio, CR'
  static abbreviation = 'cr'
  static periods = [FundamentalPeriod.Annual, FundamentalPeriod.Quarterly]
  static unitType = FundamentalUnit.Ratio
}

export class EBITDAConfig extends FundamentalIndicatorBaseConfig {
  static type = IndicatorType.Ebitda
  static label = 'EBITDA'
  static shortLabel = 'EBITDA'
  static seoLabel = 'EBITDA'
  static abbreviation = 'ebitda'
  static periods = [FundamentalPeriod.Annual, FundamentalPeriod.Quarterly]
  static unitType = FundamentalUnit.Millions
}

export class EBITOperatingIncomeConfig extends FundamentalIndicatorBaseConfig {
  static type = IndicatorType.Ebit
  static label = 'EBIT (Operating Income)'
  static shortLabel = 'EBIT'
  static seoLabel = 'EBIT (Operating Income), EBIT'
  static abbreviation = 'ebit'
  static periods = [FundamentalPeriod.Annual, FundamentalPeriod.Quarterly]
  static unitType = FundamentalUnit.Millions
}

export class EPSBasicBeforeExtraordinariesConfig extends FundamentalIndicatorBaseConfig {
  static type = IndicatorType.Epsbbe
  static label = 'EPS - Basic - Before Extraordinarie'
  static shortLabel = 'EPS BBE'
  static seoLabel = 'EPS - Basic - Before Extraordinarie, EPS BBE'
  static abbreviation = 'epsbbe'
  static periods = [FundamentalPeriod.Annual, FundamentalPeriod.Quarterly]
  static unitType = FundamentalUnit.Actual
}

export class EPSDilutedBeforeUnusualExpenseConfig extends FundamentalIndicatorBaseConfig {
  static type = IndicatorType.Epsd
  static label = 'EPS (Recurring)'
  static shortLabel = 'EPS D'
  static seoLabel = 'EPS (Recurring), EPS D'
  static abbreviation = 'epsd'
  static periods = [FundamentalPeriod.Annual, FundamentalPeriod.Quarterly]
  static unitType = FundamentalUnit.Actual
}

export class EPSFullyDilutedConfig extends FundamentalIndicatorBaseConfig {
  static type = IndicatorType.Epsfd
  static label = 'EPS (Diluted)'
  static shortLabel = 'EPS FD'
  static seoLabel = 'EPS (Diluted), EPS FD'
  static abbreviation = 'epsfd'
  static periods = [FundamentalPeriod.Annual, FundamentalPeriod.Quarterly]
  static unitType = FundamentalUnit.Actual
}

export class ExtraordinaryItemConfig extends FundamentalIndicatorBaseConfig {
  static type = IndicatorType.Ei
  static label = 'Extraordinary Item'
  static shortLabel = 'EI'
  static seoLabel = 'Extraordinary Item, EI'
  static abbreviation = 'ei'
  static periods = [FundamentalPeriod.Annual, FundamentalPeriod.Quarterly]
  static unitType = FundamentalUnit.Millions
}

export class FreeCashFlowConfig extends FundamentalIndicatorBaseConfig {
  static type = IndicatorType.Fcf
  static label = 'Free Cash Flow'
  static shortLabel = 'FCF'
  static seoLabel = 'Free Cash Flow, FCF'
  static abbreviation = 'fcf'
  static periods = [FundamentalPeriod.Annual, FundamentalPeriod.Quarterly]
  static unitType = FundamentalUnit.Millions
}

export class FundsFromOperationsConfig extends FundamentalIndicatorBaseConfig {
  static type = IndicatorType.Ffo
  static label = 'Funds From Operations'
  static shortLabel = 'FFO'
  static seoLabel = 'Funds From Operations, FFO'
  static abbreviation = 'ffo'
  static periods = [FundamentalPeriod.Annual, FundamentalPeriod.Quarterly]
  static unitType = FundamentalUnit.Millions
}

export class GrossIncomeConfig extends FundamentalIndicatorBaseConfig {
  static type = IndicatorType.Gi
  static label = 'Gross Income'
  static shortLabel = 'GI'
  static seoLabel = 'Gross Income, GI'
  static abbreviation = 'gi'
  static periods = [FundamentalPeriod.Annual, FundamentalPeriod.Quarterly]
  static unitType = FundamentalUnit.Millions
}

export class GrossIncomeMarginConfig extends FundamentalIndicatorBaseConfig {
  static type = IndicatorType.Gim
  static label = 'Gross Income Margin'
  static shortLabel = 'GIM'
  static seoLabel = 'Gross Income Margin, GIM'
  static abbreviation = 'gim'
  static periods = [FundamentalPeriod.Annual, FundamentalPeriod.Quarterly]
  static unitType = FundamentalUnit.Percent
}

export class IncomeTaxesConfig extends FundamentalIndicatorBaseConfig {
  static type = IndicatorType.It
  static label = 'Income Taxes'
  static shortLabel = 'IT'
  static seoLabel = 'Income Taxes, IT'
  static abbreviation = 'it'
  static periods = [FundamentalPeriod.Annual, FundamentalPeriod.Quarterly]
  static unitType = FundamentalUnit.Millions
}

export class IntangibleAssetsConfig extends FundamentalIndicatorBaseConfig {
  static type = IndicatorType.Ia
  static label = 'Intangible Assets'
  static shortLabel = 'IA'
  static seoLabel = 'Intangible Assets, IA'
  static abbreviation = 'ia'
  static periods = [FundamentalPeriod.Annual, FundamentalPeriod.Quarterly]
  static unitType = FundamentalUnit.Millions
}

export class InterestExpenseConfig extends FundamentalIndicatorBaseConfig {
  static type = IndicatorType.Ie
  static label = 'Interest Expense'
  static shortLabel = 'IE'
  static seoLabel = 'Interest Expense, IE'
  static abbreviation = 'ie'
  static periods = [FundamentalPeriod.Annual, FundamentalPeriod.Quarterly]
  static unitType = FundamentalUnit.Millions
}

export class InterestIncomeConfig extends FundamentalIndicatorBaseConfig {
  static type = IndicatorType.Ii
  static label = 'Interest Income'
  static shortLabel = 'II'
  static seoLabel = 'Interest Income, II'
  static abbreviation = 'ii'
  static periods = [FundamentalPeriod.Annual, FundamentalPeriod.Quarterly]
  static unitType = FundamentalUnit.Millions
}

export class InventoriesConfig extends FundamentalIndicatorBaseConfig {
  static type = IndicatorType.Inv
  static label = 'Inventories'
  static shortLabel = 'INV'
  static seoLabel = 'Inventories, INV'
  static abbreviation = 'inv'
  static periods = [FundamentalPeriod.Annual, FundamentalPeriod.Quarterly]
  static unitType = FundamentalUnit.Millions
}

export class LongTermDebtConfig extends FundamentalIndicatorBaseConfig {
  static type = IndicatorType.Ltd
  static label = 'Long Term Debt'
  static shortLabel = 'LTD'
  static seoLabel = 'Long Term Debt, LTD'
  static abbreviation = 'ltd'
  static periods = [FundamentalPeriod.Annual, FundamentalPeriod.Quarterly]
  static unitType = FundamentalUnit.Millions
}

export class MarketCapitalizationConfig extends FundamentalIndicatorBaseConfig {
  static type = IndicatorType.Mcap
  static label = 'Market Capitalization'
  static shortLabel = 'MCAP'
  static seoLabel = 'Market Capitalization, MCAP'
  static abbreviation = 'mcap'
  static periods = [FundamentalPeriod.Annual, FundamentalPeriod.Quarterly]
  static unitType = FundamentalUnit.Millions
}

export class NetAssetsFromAcquisitionsConfig extends FundamentalIndicatorBaseConfig {
  static type = IndicatorType.Nafa
  static label = 'Net Assets From Acquisitions'
  static shortLabel = 'NAFA'
  static seoLabel = 'Net Assets From Acquisitions, NAFA'
  static abbreviation = 'nafa'
  static periods = [FundamentalPeriod.Annual, FundamentalPeriod.Quarterly]
  static unitType = FundamentalUnit.Millions
}

export class NetChangeInCashConfig extends FundamentalIndicatorBaseConfig {
  static type = IndicatorType.Ncic
  static label = 'Net Change In Cash'
  static shortLabel = 'NCIC'
  static seoLabel = 'Net Change In Cash, NCIC'
  static abbreviation = 'ncic'
  static periods = [FundamentalPeriod.Annual, FundamentalPeriod.Quarterly]
  static unitType = FundamentalUnit.Millions
}

export class NetFinancingCashFlowConfig extends FundamentalIndicatorBaseConfig {
  static type = IndicatorType.Nfcf
  static label = 'Net Financing Cash Flow'
  static shortLabel = 'NFCF'
  static seoLabel = 'Net Financing Cash Flow, NFCF'
  static abbreviation = 'nfcf'
  static periods = [FundamentalPeriod.Annual, FundamentalPeriod.Quarterly]
  static unitType = FundamentalUnit.Millions
}

export class NetIncomeAvailableToCommonBasicConfig extends FundamentalIndicatorBaseConfig {
  static type = IndicatorType.Niacb
  static label = 'Net Income Available To Common'
  static shortLabel = 'NIACB'
  static seoLabel = 'Net Income Available To Common, NIACB'
  static abbreviation = 'niacb'
  static periods = [FundamentalPeriod.Annual, FundamentalPeriod.Quarterly]
  static unitType = FundamentalUnit.Millions
}

export class NetIncomeCashFlowConfig extends FundamentalIndicatorBaseConfig {
  static type = IndicatorType.Ncf
  static label = 'Net Income (Starting Line)'
  static shortLabel = 'NCF'
  static seoLabel = 'Net Income (Starting Line), NCF'
  static abbreviation = 'ncf'
  static periods = [FundamentalPeriod.Annual, FundamentalPeriod.Quarterly]
  static unitType = FundamentalUnit.Millions
}

export class NetIncomeContinuingOperationsConfig extends FundamentalIndicatorBaseConfig {
  static type = IndicatorType.Nico
  static label = 'Net Income From Continuing Operations'
  static shortLabel = 'NICO'
  static seoLabel = 'Net Income From Continuing Operations, NICO'
  static abbreviation = 'nico'
  static periods = [FundamentalPeriod.Annual, FundamentalPeriod.Quarterly]
  static unitType = FundamentalUnit.Millions
}

export class NetInterestIncomeConfig extends FundamentalIndicatorBaseConfig {
  static type = IndicatorType.Neti
  static label = 'Net Interest Income'
  static shortLabel = 'NETI'
  static seoLabel = 'Net Interest Income, NETI'
  static abbreviation = 'neti'
  static periods = [FundamentalPeriod.Annual, FundamentalPeriod.Quarterly]
  static unitType = FundamentalUnit.Millions
}

export class NetInvestingCashFlowConfig extends FundamentalIndicatorBaseConfig {
  static type = IndicatorType.Nif
  static label = 'Net Investing Cash Flow'
  static shortLabel = 'NIF'
  static seoLabel = 'Net Investing Cash Flow, NIF'
  static abbreviation = 'nif'
  static periods = [FundamentalPeriod.Annual, FundamentalPeriod.Quarterly]
  static unitType = FundamentalUnit.Millions
}

export class NetLoansConfig extends FundamentalIndicatorBaseConfig {
  static type = IndicatorType.Nl
  static label = 'Net Loans'
  static shortLabel = 'NL'
  static seoLabel = 'Net Loans, NL'
  static abbreviation = 'nl'
  static periods = [FundamentalPeriod.Annual, FundamentalPeriod.Quarterly]
  static unitType = FundamentalUnit.Millions
}

export class NetMarginConfig extends FundamentalIndicatorBaseConfig {
  static type = IndicatorType.Nm
  static label = 'Net Margin'
  static shortLabel = 'NM'
  static seoLabel = 'Net Margin, NM'
  static abbreviation = 'nm'
  static periods = [FundamentalPeriod.Annual, FundamentalPeriod.Quarterly]
  static unitType = FundamentalUnit.Ratio
}

export class NetOperatingCashFlowConfig extends FundamentalIndicatorBaseConfig {
  static type = IndicatorType.Nocf
  static label = 'Net Operating Cash Flow'
  static shortLabel = 'NOCF'
  static seoLabel = 'Net Operating Cash Flow, NOCF'
  static abbreviation = 'nocf'
  static periods = [FundamentalPeriod.Annual, FundamentalPeriod.Quarterly]
  static unitType = FundamentalUnit.Millions
}

export class NetPropertyPlantEquipmentConfig extends FundamentalIndicatorBaseConfig {
  static type = IndicatorType.Nppe
  static label = 'Net Property, Plant & Equipment'
  static shortLabel = 'NPPE'
  static seoLabel = 'Net Property, Plant & Equipment, NPPE'
  static abbreviation = 'nppe'
  static periods = [FundamentalPeriod.Annual, FundamentalPeriod.Quarterly]
  static unitType = FundamentalUnit.Millions
}

export class NonInterestExpenseConfig extends FundamentalIndicatorBaseConfig {
  static type = IndicatorType.Nie
  static label = 'Non-Interest Expense'
  static shortLabel = 'NIE'
  static seoLabel = 'Non-Interest Expense, NIE'
  static abbreviation = 'nie'
  static periods = [FundamentalPeriod.Annual, FundamentalPeriod.Quarterly]
  static unitType = FundamentalUnit.Millions
}

export class NonInterestIncomeConfig extends FundamentalIndicatorBaseConfig {
  static type = IndicatorType.Noni
  static label = 'Non-Interest Income - Banks'
  static shortLabel = 'NONI'
  static seoLabel = 'Non-Interest Income - Banks, NONI'
  static abbreviation = 'noni'
  static periods = [FundamentalPeriod.Annual, FundamentalPeriod.Quarterly]
  static unitType = FundamentalUnit.Millions
}

export class NumberOfEmployeesConfig extends FundamentalIndicatorBaseConfig {
  static type = IndicatorType.Noe
  static label = 'Number of Employees'
  static shortLabel = 'NOE'
  static seoLabel = 'Number of Employees, NOE'
  static abbreviation = 'noe'
  static periods = [FundamentalPeriod.Annual]
  static unitType = FundamentalUnit.Actual
}

export class OperatingIncomeConfig extends FundamentalIndicatorBaseConfig {
  static type = IndicatorType.OpInc
  static label = 'Operating Income'
  static shortLabel = 'OI'
  static seoLabel = 'Operating Income, OI'
  static abbreviation = 'opinc'
  static periods = [FundamentalPeriod.Annual, FundamentalPeriod.Quarterly]
  static unitType = FundamentalUnit.Millions
}

export class OperatingMarginConfig extends FundamentalIndicatorBaseConfig {
  static type = IndicatorType.Om
  static label = 'Operating Margin'
  static shortLabel = 'OM'
  static seoLabel = 'Operating Margin, OM'
  static abbreviation = 'om'
  static periods = [FundamentalPeriod.Annual, FundamentalPeriod.Quarterly]
  static unitType = FundamentalUnit.Percent
}

export class OrdinaryIncomeConfig extends FundamentalIndicatorBaseConfig {
  static type = IndicatorType.OrdInc
  static label = 'Ordinary Income'
  static shortLabel = 'OI'
  static seoLabel = 'Ordinary Income, OI'
  static abbreviation = 'ordinc'
  static periods = [FundamentalPeriod.Annual, FundamentalPeriod.Quarterly]
  static unitType = FundamentalUnit.Millions
}

export class OtherAssetsConfig extends FundamentalIndicatorBaseConfig {
  static type = IndicatorType.Oa
  static label = 'Other Assets'
  static shortLabel = 'OA'
  static seoLabel = 'Other Assets, OA'
  static abbreviation = 'oa'
  static periods = [FundamentalPeriod.Annual, FundamentalPeriod.Quarterly]
  static unitType = FundamentalUnit.Millions
}

export class OtherCurrentAssetsConfig extends FundamentalIndicatorBaseConfig {
  static type = IndicatorType.Oca
  static label = 'Other Current Assets'
  static shortLabel = 'OCA'
  static seoLabel = 'Other Current Assets, OCA'
  static abbreviation = 'oca'
  static periods = [FundamentalPeriod.Annual, FundamentalPeriod.Quarterly]
  static unitType = FundamentalUnit.Millions
}

export class OtherCurrentLiabilitiesConfig extends FundamentalIndicatorBaseConfig {
  static type = IndicatorType.Ocl
  static label = 'Other Current Liabilities'
  static shortLabel = 'OCL'
  static seoLabel = 'Other Current Liabilities, OCL'
  static abbreviation = 'ocl'
  static periods = [FundamentalPeriod.Annual, FundamentalPeriod.Quarterly]
  static unitType = FundamentalUnit.Millions
}

export class OtherFinancingActivitiesUsesConfig extends FundamentalIndicatorBaseConfig {
  static type = IndicatorType.Ofau
  static label = 'Other Financing Activities Uses'
  static shortLabel = 'OFAU'
  static seoLabel = 'Other Financing Activities Uses, OFAU'
  static abbreviation = 'ofau'
  static periods = [FundamentalPeriod.Annual, FundamentalPeriod.Quarterly]
  static unitType = FundamentalUnit.Millions
}

export class OtherFinancingActivityConfig extends FundamentalIndicatorBaseConfig {
  static type = IndicatorType.Ofa
  static label = 'Net Financing Active Other Cash Flow'
  static shortLabel = 'OFA'
  static seoLabel = 'Net Financing Active Other Cash Flow, OFA'
  static abbreviation = 'ofa'
  static periods = [FundamentalPeriod.Annual, FundamentalPeriod.Quarterly]
  static unitType = FundamentalUnit.Millions
}

export class OtherFundsConfig extends FundamentalIndicatorBaseConfig {
  static type = IndicatorType.Of
  static label = 'Other Funds (Non Cash)'
  static shortLabel = 'OF'
  static seoLabel = 'Other Funds (Non Cash), OF'
  static abbreviation = 'of'
  static periods = [FundamentalPeriod.Annual, FundamentalPeriod.Quarterly]
  static unitType = FundamentalUnit.Millions
}

export class OtherIntangibleAssetsConfig extends FundamentalIndicatorBaseConfig {
  static type = IndicatorType.Oia
  static label = 'Other Intangible Assets'
  static shortLabel = 'OIA'
  static seoLabel = 'Other Intangible Assets, OIA'
  static abbreviation = 'oia'
  static periods = [FundamentalPeriod.Annual, FundamentalPeriod.Quarterly]
  static unitType = FundamentalUnit.Millions
}

export class OtherLiabilitiesConfig extends FundamentalIndicatorBaseConfig {
  static type = IndicatorType.Ol
  static label = 'Other Liabilities'
  static shortLabel = 'OL'
  static seoLabel = 'Other Liabilities, OL'
  static abbreviation = 'ol'
  static periods = [FundamentalPeriod.Annual, FundamentalPeriod.Quarterly]
  static unitType = FundamentalUnit.Millions
}

export class OtherOperatingExpenseConfig extends FundamentalIndicatorBaseConfig {
  static type = IndicatorType.Ooe
  static label = 'Other Operating Expense'
  static shortLabel = 'OOE'
  static seoLabel = 'Other Operating Expense, OOE'
  static abbreviation = 'ooe'
  static periods = [FundamentalPeriod.Annual, FundamentalPeriod.Quarterly]
  static unitType = FundamentalUnit.Millions
}

export class PERatioConfig extends FundamentalIndicatorBaseConfig {
  static type = IndicatorType.Pe
  static label = 'PE Ratio'
  static shortLabel = 'P/E'
  static seoLabel = 'PE Ratio, PE'
  static abbreviation = 'pe'
  static periods = [FundamentalPeriod.Annual, FundamentalPeriod.Quarterly]
  static unitType = FundamentalUnit.Ratio
}

export class PretaxIncomeConfig extends FundamentalIndicatorBaseConfig {
  static type = IndicatorType.Pti
  static label = 'Pretax Income'
  static shortLabel = 'PTI'
  static seoLabel = 'Pretax Income, PTI'
  static abbreviation = 'pti'
  static periods = [FundamentalPeriod.Annual, FundamentalPeriod.Quarterly]
  static unitType = FundamentalUnit.Millions
}

export class PriceToBookConfig extends FundamentalIndicatorBaseConfig {
  static type = IndicatorType.Pb
  static label = 'Price to Book'
  static shortLabel = 'P/B'
  static seoLabel = 'Price to Book, P/B'
  static abbreviation = 'pb'
  static periods = [FundamentalPeriod.Annual, FundamentalPeriod.Quarterly]
  static unitType = FundamentalUnit.Ratio
}

export class PriceToFreeCashFlowConfig extends FundamentalIndicatorBaseConfig {
  static type = IndicatorType.Pfcf
  static label = 'Price to Free Cash Flow'
  static shortLabel = 'P/FCF'
  static seoLabel = 'Price to Free Cash Flow, P/FCF'
  static abbreviation = 'pfcf'
  static periods = [FundamentalPeriod.Annual, FundamentalPeriod.Quarterly]
  static unitType = FundamentalUnit.Actual
}

export class PriceToSalesFiscalConfig extends FundamentalIndicatorBaseConfig {
  static type = IndicatorType.Psf
  static label = 'Price To Sales - Fiscal'
  static shortLabel = 'PSF'
  static seoLabel = 'Price To Sales - Fiscal, PSF'
  static abbreviation = 'psf'
  static periods = [FundamentalPeriod.Annual, FundamentalPeriod.Quarterly]
  static unitType = FundamentalUnit.Actual
}

export class PurchaseOfInvestmentsConfig extends FundamentalIndicatorBaseConfig {
  static type = IndicatorType.Poi
  static label = 'Purchase of Investments'
  static shortLabel = 'POI'
  static seoLabel = 'Purchase of Investments, POI'
  static abbreviation = 'poi'
  static periods = [FundamentalPeriod.Annual, FundamentalPeriod.Quarterly]
  static unitType = FundamentalUnit.Millions
}

export class PurchaseOrSaleOfInvestmentsConfig extends FundamentalIndicatorBaseConfig {
  static type = IndicatorType.Posi
  static label = 'Purchase or Sale of Investments'
  static shortLabel = 'POSI'
  static seoLabel = 'Purchase or Sale of Investments, POSI'
  static abbreviation = 'posi'
  static periods = [FundamentalPeriod.Annual, FundamentalPeriod.Quarterly]
  static unitType = FundamentalUnit.Millions
}

export class QuickRatioConfig extends FundamentalIndicatorBaseConfig {
  static type = IndicatorType.Qr
  static label = 'Quick Ratio'
  static shortLabel = 'QR'
  static seoLabel = 'Quick Ratio, QR'
  static abbreviation = 'qr'
  static periods = [FundamentalPeriod.Annual, FundamentalPeriod.Quarterly]
  static unitType = FundamentalUnit.Ratio
}

export class ResearchAndDevelopmentConfig extends FundamentalIndicatorBaseConfig {
  static type = IndicatorType.Rd
  static label = 'Research & Development'
  static shortLabel = 'R&D'
  static seoLabel = 'Research & Development, R&D'
  static abbreviation = 'rd'
  static periods = [FundamentalPeriod.Annual, FundamentalPeriod.Quarterly]
  static unitType = FundamentalUnit.Millions
}

export class ReturnOnAssetsConfig extends FundamentalIndicatorBaseConfig {
  static type = IndicatorType.Roa
  static label = 'Return On Assets'
  static shortLabel = 'ROA'
  static seoLabel = 'Return On Assets, ROA'
  static abbreviation = 'roa'
  static periods = [FundamentalPeriod.Annual, FundamentalPeriod.Quarterly]
  static unitType = FundamentalUnit.Percent
}

export class ReturnOnAverageTotalEquityConfig extends FundamentalIndicatorBaseConfig {
  static type = IndicatorType.Roate
  static label = 'Return On Average Total Equity'
  static shortLabel = 'ROATE'
  static seoLabel = 'Return On Average Total Equity, ROATE'
  static abbreviation = 'teq'
  static periods = [FundamentalPeriod.Annual, FundamentalPeriod.Quarterly]
  static unitType = FundamentalUnit.Percent
}

export class ROIConfig extends FundamentalIndicatorBaseConfig {
  static type = IndicatorType.Roi
  static label = 'Return on Investment'
  static shortLabel = 'ROI'
  static seoLabel = 'Return on Investment, ROI'
  static abbreviation = 'roi'
  static periods = [FundamentalPeriod.Annual, FundamentalPeriod.Quarterly]
  static unitType = FundamentalUnit.Percent
}

export class SalesOrRevenueConfig extends FundamentalIndicatorBaseConfig {
  static type = IndicatorType.Rev
  static label = 'Total Sales (or Revenue)'
  static shortLabel = 'REV'
  static seoLabel = 'Total Sales (or Revenue), REV'
  static abbreviation = 'rev'
  static periods = [FundamentalPeriod.Annual, FundamentalPeriod.Quarterly]
  static unitType = FundamentalUnit.Millions
}

export class SellingGeneralAdministrativeExpensesConfig extends FundamentalIndicatorBaseConfig {
  static type = IndicatorType.Sgae
  static label = 'SG&A Excluding Other'
  static shortLabel = 'SGAE'
  static seoLabel = 'SG&A Excluding Other, SGAE'
  static abbreviation = 'sgae'
  static periods = [FundamentalPeriod.Annual, FundamentalPeriod.Quarterly]
  static unitType = FundamentalUnit.Millions
}

export class SellingGeneralAdministrativeExpensesAndOtherConfig extends FundamentalIndicatorBaseConfig {
  static type = IndicatorType.Sgaeo
  static label = 'Selling General and Administrative Expenses SG&A And Other'
  static shortLabel = 'SGAEO'
  static seoLabel = 'Selling General and Administrative Expenses SG&A And Other, SGAEO'
  static abbreviation = 'sgaeo'
  static periods = [FundamentalPeriod.Annual, FundamentalPeriod.Quarterly]
  static unitType = FundamentalUnit.Millions
}

export class SharesOutstandingConfig extends FundamentalIndicatorBaseConfig {
  static type = IndicatorType.So
  static label = 'Shares Outstanding'
  static shortLabel = 'SO'
  static seoLabel = 'Shares Outstanding, SO'
  static abbreviation = 'so'
  static periods = [FundamentalPeriod.Annual, FundamentalPeriod.Quarterly]
  static unitType = FundamentalUnit.Millions
}

export class ShortTermDebtConfig extends FundamentalIndicatorBaseConfig {
  static type = IndicatorType.Std
  static label = 'Short Term Debt (including Current Portion of Long Term Debt)'
  static shortLabel = 'STD'
  static seoLabel = 'Short Term Debt (including Current Portion of Long Term Debt), STD'
  static abbreviation = 'std'
  static periods = [FundamentalPeriod.Annual, FundamentalPeriod.Quarterly]
  static unitType = FundamentalUnit.Millions
}

export class ShortTermReceivablesConfig extends FundamentalIndicatorBaseConfig {
  static type = IndicatorType.Str
  static label = 'Short Term Receivables'
  static shortLabel = 'STR'
  static seoLabel = 'Short Term Receivables, STR'
  static abbreviation = 'str'
  static periods = [FundamentalPeriod.Annual, FundamentalPeriod.Quarterly]
  static unitType = FundamentalUnit.Millions
}

export class TangibleBookValuePerShareConfig extends FundamentalIndicatorBaseConfig {
  static type = IndicatorType.Tbvps
  static label = 'Tangible Book Value Per Share'
  static shortLabel = 'TBVPS'
  static seoLabel = 'Tangible Book Value Per Share, TBVPS'
  static abbreviation = 'tbvps'
  static periods = [FundamentalPeriod.Annual, FundamentalPeriod.Quarterly]
  static unitType = FundamentalUnit.Actual
}

export class TotalAssetsConfig extends FundamentalIndicatorBaseConfig {
  static type = IndicatorType.Ta
  static label = 'Total Assets'
  static shortLabel = 'TA'
  static seoLabel = 'Total Assets, TA'
  static abbreviation = 'ta'
  static periods = [FundamentalPeriod.Annual, FundamentalPeriod.Quarterly]
  static unitType = FundamentalUnit.Millions
}

export class TotalCashConfig extends FundamentalIndicatorBaseConfig {
  static type = IndicatorType.Tc
  static label = 'Total Cash'
  static shortLabel = 'TC'
  static seoLabel = 'Total Cash, TC'
  static abbreviation = 'tc'
  static periods = [FundamentalPeriod.Annual, FundamentalPeriod.Quarterly]
  static unitType = FundamentalUnit.Millions
}

export class TotalCashDueFromBanksConfig extends FundamentalIndicatorBaseConfig {
  static type = IndicatorType.Tcdfb
  static label = 'Cash & Due from Banks'
  static shortLabel = 'TCDFB'
  static seoLabel = 'Cash & Due from Banks, TCDFB'
  static abbreviation = 'tcdfb'
  static periods = [FundamentalPeriod.Annual, FundamentalPeriod.Quarterly]
  static unitType = FundamentalUnit.Millions
}

export class TotalCommonEquityConfig extends FundamentalIndicatorBaseConfig {
  static type = IndicatorType.Tce
  static label = 'Common Equity'
  static shortLabel = 'TCE'
  static seoLabel = 'Common Equity, TCE'
  static abbreviation = 'tce'
  static periods = [FundamentalPeriod.Annual, FundamentalPeriod.Quarterly]
  static unitType = FundamentalUnit.Millions
}

export class TotalCurrentAssetsConfig extends FundamentalIndicatorBaseConfig {
  static type = IndicatorType.Tca
  static label = 'Total Current Assets'
  static shortLabel = 'TCA'
  static seoLabel = 'Total Current Assets, TCA'
  static abbreviation = 'tca'
  static periods = [FundamentalPeriod.Annual, FundamentalPeriod.Quarterly]
  static unitType = FundamentalUnit.Millions
}

export class TotalCurrentLiabilitiesConfig extends FundamentalIndicatorBaseConfig {
  static type = IndicatorType.Tcl
  static label = 'Total Current Liabilities'
  static shortLabel = 'TCL'
  static seoLabel = 'Total Current Liabilities, TCL'
  static abbreviation = 'tcl'
  static periods = [FundamentalPeriod.Annual, FundamentalPeriod.Quarterly]
  static unitType = FundamentalUnit.Millions
}

export class TotalDebtConfig extends FundamentalIndicatorBaseConfig {
  static type = IndicatorType.Td
  static label = 'Total Debt'
  static shortLabel = 'TD'
  static seoLabel = 'Total Debt, TD'
  static abbreviation = 'td'
  static periods = [FundamentalPeriod.Annual, FundamentalPeriod.Quarterly]
  static unitType = FundamentalUnit.Millions
}

export class TotalEquityConfig extends FundamentalIndicatorBaseConfig {
  static type = IndicatorType.Teq
  static label = 'Total Equity'
  static shortLabel = 'TEQ'
  static seoLabel = 'Total Equity, TEQ'
  static abbreviation = 'teq'
  static periods = [FundamentalPeriod.Annual, FundamentalPeriod.Quarterly]
  static unitType = FundamentalUnit.Millions
}

export class TotalExpensesConfig extends FundamentalIndicatorBaseConfig {
  static type = IndicatorType.Texp
  static label = 'Total Expenses'
  static shortLabel = 'TEXP'
  static seoLabel = 'Total Expenses, TEXP'
  static abbreviation = 'texp'
  static periods = [FundamentalPeriod.Annual, FundamentalPeriod.Quarterly]
  static unitType = FundamentalUnit.Millions
}

export class TotalInterestExpenseConfig extends FundamentalIndicatorBaseConfig {
  static type = IndicatorType.Tie
  static label = 'Total Interest Expense - Banks'
  static shortLabel = 'TIE'
  static seoLabel = 'Total Interest Expense - Banks, TIE'
  static abbreviation = 'tie'
  static periods = [FundamentalPeriod.Annual, FundamentalPeriod.Quarterly]
  static unitType = FundamentalUnit.Millions
}

export class TotalInvestmentsAndAdvancesConfig extends FundamentalIndicatorBaseConfig {
  static type = IndicatorType.Tia
  static label = 'Total Investments And Advances'
  static shortLabel = 'TIA'
  static seoLabel = 'Total Investments And Advances, TIA'
  static abbreviation = 'tia'
  static periods = [FundamentalPeriod.Annual, FundamentalPeriod.Quarterly]
  static unitType = FundamentalUnit.Millions
}

export class TotalInvestmentsBanksConfig extends FundamentalIndicatorBaseConfig {
  static type = IndicatorType.Tib
  static label = 'Investments'
  static shortLabel = 'TIB'
  static seoLabel = 'Investments, TIB'
  static abbreviation = 'tib'
  static periods = [FundamentalPeriod.Annual, FundamentalPeriod.Quarterly]
  static unitType = FundamentalUnit.Millions
}

export class TotalLiabilitiesConfig extends FundamentalIndicatorBaseConfig {
  static type = IndicatorType.Tl
  static label = 'Total Liabilities'
  static shortLabel = 'TL'
  static seoLabel = 'Total Liabilities, TL'
  static abbreviation = 'tl'
  static periods = [FundamentalPeriod.Annual, FundamentalPeriod.Quarterly]
  static unitType = FundamentalUnit.Millions
}

export class TotalLiabilitiesStockholdersEquityConfig extends FundamentalIndicatorBaseConfig {
  static type = IndicatorType.Tlse
  static label = 'Total Liabilities & Stockholders Equity'
  static shortLabel = 'TLSE'
  static seoLabel = 'Total Liabilities & Stockholders Equity, TLSE'
  static abbreviation = 'tlse'
  static periods = [FundamentalPeriod.Annual, FundamentalPeriod.Quarterly]
  static unitType = FundamentalUnit.Millions
}

export class TotalShareholdersEquityConfig extends FundamentalIndicatorBaseConfig {
  static type = IndicatorType.Tse
  static label = 'Total Shareholders Equity'
  static shortLabel = 'TSE'
  static seoLabel = 'Total Shareholders Equity, TSE'
  static abbreviation = 'tse'
  static periods = [FundamentalPeriod.Annual, FundamentalPeriod.Quarterly]
  static unitType = FundamentalUnit.Millions
}

export class UnusualExpenseConfig extends FundamentalIndicatorBaseConfig {
  static type = IndicatorType.Ue
  static label = 'Unusual Expense'
  static shortLabel = 'UE'
  static seoLabel = 'Unusual Expense, UE'
  static abbreviation = 'ue'
  static periods = [FundamentalPeriod.Annual, FundamentalPeriod.Quarterly]
  static unitType = FundamentalUnit.Millions
}

export class NonOperatingIncomeExpenseConfig extends FundamentalIndicatorBaseConfig {
  static type = IndicatorType.Noie
  static label = 'Non-Operating Income (Expense)'
  static shortLabel = 'NOIE'
  static seoLabel = 'Non-Operating Income (Expense), NOIE'
  static abbreviation = 'noie'
  static periods = [FundamentalPeriod.Annual]
  static unitType = FundamentalUnit.Millions
}

export class OperatingLeaseExpenseConfig extends FundamentalIndicatorBaseConfig {
  static type = IndicatorType.Ole
  static label = 'Operating Lease Expense'
  static shortLabel = 'OLE'
  static seoLabel = 'Operating Lease Expense, OLE'
  static abbreviation = 'ole'
  static periods = [FundamentalPeriod.Annual]
  static unitType = FundamentalUnit.Millions
}

export class RealEstateAssetsConfig extends FundamentalIndicatorBaseConfig {
  static type = IndicatorType.Rea
  static label = 'Real Estate Assets'
  static shortLabel = 'REA'
  static seoLabel = 'Real Estate Assets, REA'
  static abbreviation = 'rea'
  static periods = [FundamentalPeriod.Annual]
  static unitType = FundamentalUnit.Millions
}

export const FundamentalIndicatorsConfigs = [
  AccountsPayableConfig,
  BookValuePerShareConfig,
  CapitalExpendituresConfig,
  CashDividendsPaidConfig,
  CashShortTermInvestmentsConfig,
  CommonDividendsConfig,
  ConsolidatedNetIncomeConfig,
  CostOfGoodsSoldConfig,
  CurrentRatioConfig,
  EBITDAConfig,
  EBITOperatingIncomeConfig,
  EPSBasicBeforeExtraordinariesConfig,
  EPSDilutedBeforeUnusualExpenseConfig,
  EPSFullyDilutedConfig,
  ExtraordinaryItemConfig,
  FreeCashFlowConfig,
  FundsFromOperationsConfig,
  GrossIncomeConfig,
  GrossIncomeMarginConfig,
  IncomeTaxesConfig,
  IntangibleAssetsConfig,
  InterestExpenseConfig,
  InterestIncomeConfig,
  InventoriesConfig,
  LongTermDebtConfig,
  MarketCapitalizationConfig,
  NetAssetsFromAcquisitionsConfig,
  NetChangeInCashConfig,
  NetFinancingCashFlowConfig,
  NetIncomeAvailableToCommonBasicConfig,
  NetIncomeCashFlowConfig,
  NetIncomeContinuingOperationsConfig,
  NetInterestIncomeConfig,
  NetInvestingCashFlowConfig,
  NetLoansConfig,
  NetMarginConfig,
  NetOperatingCashFlowConfig,
  NetPropertyPlantEquipmentConfig,
  NonInterestExpenseConfig,
  NonInterestIncomeConfig,
  NumberOfEmployeesConfig,
  OperatingIncomeConfig,
  OperatingMarginConfig,
  OrdinaryIncomeConfig,
  OtherAssetsConfig,
  OtherCurrentAssetsConfig,
  OtherCurrentLiabilitiesConfig,
  OtherFinancingActivitiesUsesConfig,
  OtherFinancingActivityConfig,
  OtherFundsConfig,
  OtherIntangibleAssetsConfig,
  OtherLiabilitiesConfig,
  OtherOperatingExpenseConfig,
  PERatioConfig,
  PretaxIncomeConfig,
  PriceToBookConfig,
  PriceToFreeCashFlowConfig,
  PriceToSalesFiscalConfig,
  PurchaseOfInvestmentsConfig,
  PurchaseOrSaleOfInvestmentsConfig,
  QuickRatioConfig,
  ResearchAndDevelopmentConfig,
  ReturnOnAssetsConfig,
  ReturnOnAverageTotalEquityConfig,
  ROIConfig,
  SalesOrRevenueConfig,
  SellingGeneralAdministrativeExpensesConfig,
  SellingGeneralAdministrativeExpensesAndOtherConfig,
  SharesOutstandingConfig,
  ShortTermDebtConfig,
  ShortTermReceivablesConfig,
  TangibleBookValuePerShareConfig,
  TotalAssetsConfig,
  TotalCashConfig,
  TotalCashDueFromBanksConfig,
  TotalCommonEquityConfig,
  TotalCurrentAssetsConfig,
  TotalCurrentLiabilitiesConfig,
  TotalDebtConfig,
  TotalEquityConfig,
  TotalExpensesConfig,
  TotalInterestExpenseConfig,
  TotalInvestmentsAndAdvancesConfig,
  TotalInvestmentsBanksConfig,
  TotalLiabilitiesConfig,
  TotalLiabilitiesStockholdersEquityConfig,
  TotalShareholdersEquityConfig,
  UnusualExpenseConfig,
  NonOperatingIncomeExpenseConfig,
  OperatingLeaseExpenseConfig,
  RealEstateAssetsConfig,
]
