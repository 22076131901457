import { Button } from '@finviz/website'
import * as React from 'react'

import Utils, { getExitFullscreen, getFullscreenStatus, getRequestFullscreen } from '../../utils'

const isIPad = Utils.isIPad()
const IPAD_FULLSCREEN_CLASS = 'is-ipad-fullscreen'

export function FullscreenButton() {
  const [isFullscreen, setIsFullscreen] = React.useState(getFullscreenStatus())
  const requestFullscreen = getRequestFullscreen(document.documentElement)
  const exitFullscreen = getExitFullscreen()

  const onClick = React.useRef(() => {
    if (getFullscreenStatus()) {
      void exitFullscreen.call(document)
    } else {
      void requestFullscreen.call(document.documentElement)
    }
  })

  React.useEffect(() => {
    const checkFullscreen = () => {
      if (isIPad) {
        if (getFullscreenStatus()) {
          document.body.classList.add(IPAD_FULLSCREEN_CLASS)
        } else {
          document.body.classList.remove(IPAD_FULLSCREEN_CLASS)
        }
      }
      setIsFullscreen(getFullscreenStatus())
    }

    document.addEventListener('webkitfullscreenchange', checkFullscreen)
    document.addEventListener('fullscreenchange', checkFullscreen)
    return () => {
      document.removeEventListener('webkitfullscreenchange', checkFullscreen)
      document.removeEventListener('fullscreenchange', checkFullscreen)
    }
  }, [])

  if (!requestFullscreen) return null

  return (
    <Button
      theme="headerItem"
      data-testid="chart-layout-fullscreen"
      leftContent={isFullscreen ? 'exitFullscreen' : 'fullscreen'}
      contentClass="hidden lg:flex"
      title={isFullscreen ? 'Exit Full screen' : 'Full screen'}
      onClick={onClick.current}
    >
      {isFullscreen ? 'Exit Full screen' : 'Full screen'}
    </Button>
  )
}
