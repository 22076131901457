import {
  ButtonRoundingType,
  ButtonSizeType,
  CustomDateRange,
  Heading,
  TimeframeBar,
  ZIndexContext,
  useCustomDateRangeDialog,
  useFavoriteTimeframes,
} from '@finviz/website'
import classnames from 'classnames'
import React from 'react'
import { useNavigate } from 'react-router-dom'

import { ChartEditorEnum, SpecificChartFunctionality, TIMEFRAME } from '../../constants/common'
import { useModelState } from '../../model-hooks/use-model-state'
import Chart from '../../models/chart'
import { IdeasDropdown } from '../header/ideas_dropdown'
import { Publish } from '../header/publish'
import { TourButton } from '../header/tour_button'
import { ChartTypesSelect } from './chart-type-select'
import { DailyChangeDisplay } from './daily-change-display'
import { DrawButton } from './draw-button'
import { FullscreenButton } from './fullscreen-button'
import Search from './search'
import { ChartSettingsButton } from './settings-button'
import { useChartControls } from './use-chart-controls'
import { useChartModelCustomDateRangeValues } from './use-chart-model-custom-date-range-values'

interface SettingsComponentProps {
  chartModel: Chart
  chartIndex: number
}

const isPremium = window.FinvizSettings.hasUserPremium
const hasRedesignEnabled = window.FinvizSettings.hasRedesignEnabled

export function ChartControls({ chartIndex, chartModel: unwatchedChartModel }: SettingsComponentProps) {
  const rounding: ButtonRoundingType | undefined = hasRedesignEnabled ? undefined : 'none'
  // This memo is required for publish, otherwise it ends up in a loop
  const chartLayout = React.useMemo(() => unwatchedChartModel.chart_layout(), [unwatchedChartModel])
  const chartModel = useModelState(unwatchedChartModel, {
    watchProperties: ['ticker', 'quote', 'dateRange'],
  })
  const { instrument } = chartModel?.quote() || {}
  const isQuotePage = chartLayout.specificChartFunctionality === SpecificChartFunctionality.quotePage
  const isQuoteFinancials = chartLayout.specificChartFunctionality === SpecificChartFunctionality.quoteFinancials

  const navigate = useNavigate()
  const { favoriteTimeframes, toggleFavoriteTimeframe } = useFavoriteTimeframes()
  const { isLoading } = useChartControls({
    chartModel,
    isInteractive: true,
  })
  const {
    anchorDate,
    generateChartLayoutRouterPath,
    timeframeGroupsWithHref,
    dateRangeGroupsWithHref,
    isFetching,
    handleCustomDateRangeValidation,
  } = useChartModelCustomDateRangeValues({ chartModel, isPremium })
  const { onDialogSubmit, customDateRangeDialogState, handleDateRangeSelect, getDateRangeTimeframe } =
    useCustomDateRangeDialog({
      instrument,
      anchorDate,
      pathGenerator: generateChartLayoutRouterPath,
    })

  const handleTickerSelect = React.useCallback(
    (ticker: string) => {
      const upperCaseTicker = ticker.toUpperCase()

      navigate(
        generateChartLayoutRouterPath({
          ticker: upperCaseTicker,
          timeframe: chartModel.timeframe,
          dateRange: chartModel.dateRange,
        })
      )
    },
    [chartModel, generateChartLayoutRouterPath, navigate]
  )

  async function handleCustomRangeSubmit(values: { dateRange: string | null }) {
    const dateRangeTimeframe = values.dateRange
      ? getDateRangeTimeframe(values.dateRange)
      : { value: '', timeframe: chartModel.timeframe }

    if (dateRangeTimeframe === null) {
      return
    } else if (dateRangeTimeframe.value) {
      const isValid = await handleCustomDateRangeValidation(dateRangeTimeframe)
      if (!isValid) {
        return
      }
    }

    onDialogSubmit(dateRangeTimeframe)
  }

  const timeframeBarProps = React.useMemo(
    () => ({
      size: 'medium' as ButtonSizeType,
      chartIndex: chartIndex,
      timeFrameGroups: timeframeGroupsWithHref(isPremium),
      dateRangeGroups: dateRangeGroupsWithHref({ isPremium, customRange: chartModel.dateRange, instrument }),
      selectedTimeframe: chartModel.timeframe,
      onTimeframeSelect: () => {},
      selectedDateRange: chartModel.dateRange,
      favoriteTimeframes: favoriteTimeframes,
      loadingTimeframe: isLoading ? chartModel.timeframe : undefined,
      onDateRangeSelect: handleDateRangeSelect,
      onCustomDateRangeClick: () => {
        if (isPremium) customDateRangeDialogState.show()
      },
      isFavoritesEnabled: isPremium,
      onFavoriteTimeframeToggle: ({ value }: { value: TIMEFRAME | 'intraday' }) => {
        toggleFavoriteTimeframe(value)
      },
    }),
    [
      chartIndex,
      chartModel.dateRange,
      chartModel.timeframe,
      customDateRangeDialogState,
      dateRangeGroupsWithHref,
      favoriteTimeframes,
      handleDateRangeSelect,
      instrument,
      isLoading,
      timeframeGroupsWithHref,
      toggleFavoriteTimeframe,
    ]
  )

  if (chartLayout.specificChartFunctionality === SpecificChartFunctionality.chartPage) {
    return (
      <ZIndexContext.Provider value="z-dialog">
        <div className="relative flex h-11 space-x-2 bg-white px-2 dark:bg-gray-800">
          {chartModel.chart_layout().canChangeTicker && (
            <Search
              ticker={chartModel.ticker}
              instrument={chartModel.instrument}
              onTickerSelected={handleTickerSelect}
              chartIndex={chartIndex}
            />
          )}

          <TimeframeBar {...timeframeBarProps} />

          <DailyChangeDisplay chartIndex={chartIndex} />

          <CustomDateRange
            dialogState={customDateRangeDialogState}
            dateRange={chartModel.dateRange}
            anchorDate={anchorDate}
            onDialogSubmit={handleCustomRangeSubmit}
            isFetching={isFetching}
          />
        </div>
      </ZIndexContext.Provider>
    )
  }

  const hasTimeframe = chartLayout.editors.includes(ChartEditorEnum.timeframe)

  return (
    <div
      className={classnames('flex items-center space-x-1.5 p-1.5', {
        'justify-between': hasTimeframe,
        'border border-[#f3f3f3] font-sans border-b-none': !hasRedesignEnabled,
      })}
    >
      <div className={classnames('flex items-center space-x-1.5', { 'flex-1': isQuoteFinancials })}>
        {!isQuotePage && !isQuoteFinancials && (
          <Heading level={3} className="my-0 ml-1 truncate uppercase text-gray-600 dark:text-gray-200">
            {chartModel.quote().name ?? chartModel.ticker}
          </Heading>
        )}
        {chartLayout.editors.includes(ChartEditorEnum.tools) && (
          <DrawButton
            theme="chip"
            size="medium"
            active={chartLayout.editable}
            isPremium={isPremium}
            rounding={rounding}
            specificChartFunctionality={chartLayout.specificChartFunctionality}
          >
            Draw
          </DrawButton>
        )}
        {chartLayout.editors.includes(ChartEditorEnum.ideas) && (
          <IdeasDropdown model={chartLayout} theme="chip" size="medium" rounding={rounding}>
            Ideas
          </IdeasDropdown>
        )}
        {!hasTimeframe && (
          <div>
            <ChartTypesSelect
              specificFunctionality={chartLayout.specificChartFunctionality}
              isPremium={isPremium}
              theme="chip"
              size="medium"
              rounding={rounding}
            />
          </div>
        )}
      </div>

      {hasTimeframe && (
        <div className="relative w-full max-w-[612px]">
          <div className="absolute inset-0 flex h-full w-full items-center justify-center">
            {!isQuoteFinancials && (
              <div>
                <ChartTypesSelect
                  specificFunctionality={chartLayout.specificChartFunctionality}
                  isPremium={isPremium}
                  size="medium"
                  rounding={rounding}
                />
              </div>
            )}
            <div className="-m-1.5 -mr-2 ml-0 overflow-hidden p-1.5">
              <TimeframeBar
                compactViewMaxBreakpointPx={0}
                {...timeframeBarProps}
                className="space-x-1.5"
                rounding={rounding}
              />
              <CustomDateRange
                dialogState={customDateRangeDialogState}
                dateRange={chartModel.dateRange}
                anchorDate={anchorDate}
                onDialogSubmit={handleCustomRangeSubmit}
                isFetching={isFetching}
              />
            </div>
          </div>
        </div>
      )}

      <div
        className={classnames('flex items-center justify-end space-x-1.5', {
          grow: !hasTimeframe,
          'flex-1': isQuoteFinancials,
        })}
      >
        {chartLayout.editors.includes(ChartEditorEnum.publish) && (
          <Publish
            model={chartLayout}
            theme="chip"
            size="medium"
            rounding={rounding}
            data-testid="chart-toolbar-publish"
          >
            Share
          </Publish>
        )}
        {isQuotePage && (
          <TourButton page={chartLayout.specificChartFunctionality} rounding={rounding} theme="chip" size="medium" />
        )}
        {!isQuoteFinancials && (
          <FullscreenButton model={chartLayout} isPremium={isPremium} rounding={rounding} theme="chip" size="medium">
            {!isQuotePage && 'Fullscreen'}
          </FullscreenButton>
        )}
        {chartLayout.editors.includes(ChartEditorEnum.settings) && (
          <ChartSettingsButton
            model={chartLayout}
            isPremium={isPremium}
            rounding={rounding}
            theme="chip"
            size="medium"
            focusRing={false}
          >
            {!isQuotePage && 'Settings'}
          </ChartSettingsButton>
        )}
        {!isQuotePage && !isQuoteFinancials && (
          <div className="flex shrink-0 grow justify-end">
            <DailyChangeDisplay chartIndex={0} />
          </div>
        )}
      </div>
    </div>
  )
}
