import { PaneArea, RequireByKey, TextAttrs } from '../../types/shared'
import { CanvasElementType, TextAlign, TextBaseline } from '../constants/common'
import Element, { TodoModalConfig } from './element'

export interface Area {
  x: number
  y: number
}

export type ITextAttrs = RequireByKey<TextAttrs, 'text' | 'textAlign' | 'fillStyle'> & Partial<Area>

const DEFAULTS: Partial<TextAttrs> = {
  text: '',
  font: {
    size: 20,
    family: 'Arial, sans-serif',
    style: 'normal',
    weight: 'normal',
  },
  lineHeight: 20,
  // left | right | center | start | end
  textAlign: TextAlign.left,
  // top | hanging | middle | alphabetic | ideographic | bottom
  textBaseline: TextBaseline.alphabetic,
  padding: {
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
  },
}

class Text<Attrs extends ITextAttrs = ITextAttrs> extends Element<Attrs> {
  static type = CanvasElementType.text

  static getMergedPropsWithDefaults<T extends keyof Pick<TextAttrs, 'font' | 'padding'>>(
    propKey: T,
    props: Partial<TextAttrs[T]> = {}
  ) {
    return {
      ...DEFAULTS[propKey],
      ...Object.entries(props).reduce((acc, [key, value]) => (value ? { ...acc, [key]: value } : acc), {}),
    }
  }

  name = 'Anchored text'
  declare context?: CanvasRenderingContext2D
  declare width: number
  declare height: number
  declare font: string

  static initClass() {
    Object.defineProperty(this.prototype, 'width', {
      get() {
        // TODO: revisit this getter, when you call `this.width` you don't expect/want context change
        if (this.context) {
          this.setupContext(this.context)
        }
        return this.measure() + this.attrs.padding.left + this.attrs.padding.right
      },
    })

    Object.defineProperty(this.prototype, 'height', {
      get() {
        const count = this.getLines().length
        return this.attrs.lineHeight * count + this.attrs.padding.top + this.attrs.padding.bottom
      },
    })

    Object.defineProperty(this.prototype, 'font', {
      get() {
        const f = this.attrs.font
        return `${f.style} ${f.weight} ${f.size}pt ${f.family}`
      },
    })
  }

  getModalConfigBase() {
    return {
      inputs: [
        { type: 'multiline_string', name: 'text', required: true },
        { type: 'font', name: 'font' },
        {
          type: 'background',
          name: 'fillStyle',
          label: 'Color',
          default: this.getChartLayoutSettings().ElementSettings.Colors.textWithoutBackground,
        },
        {
          type: 'background',
          name: 'background',
          label: 'Background',
          default: 'rgba(0,0,0,0)',
        },
        {
          type: 'border',
          name: 'border',
          min: 0,
          default: { color: 'rgba(0,0,0)', width: 0 },
        },
      ],
    } as unknown as TodoModalConfig
  }

  getDefaults() {
    return {
      ...DEFAULTS,
      fillStyle: this.getChartLayoutSettings().ElementSettings.Colors.textWithoutBackground,
    } as Partial<Attrs>
  }

  getBoundingPointKeys = () => ({ x: ['x'], y: ['y'] })

  set(obj: Partial<Attrs>) {
    super.set(obj)
    if (obj.font?.size && !obj.lineHeight) {
      this.attrs.lineHeight = Math.max(obj.font.size, this.attrs.lineHeight)
    }
    return this
  }

  render(context: CanvasRenderingContext2D) {
    this.setupContext(context)
    if (this.attrs.angle && this.context) {
      // TODO background & multiline text
      this.context.save()
      this.context.translate(this.attrs.x!, this.attrs.y!)
      this.context.rotate((this.attrs.angle * Math.PI) / 180)
      this.context.fillText(this.attrs.text, 0, 0)
      this.context.restore()
    } else {
      this.renderBackground()
      this.renderText()
    }
  }

  measure(context?: CanvasRenderingContext2D) {
    if (context) {
      this.setupContext(context)
    }
    const lines = this.getLines()
    let max = 0
    for (const line of lines) {
      max = Math.max(this.context?.measureText(line).width ?? Number.MIN_SAFE_INTEGER, max)
    }
    return ~~max
  }

  isInArea(testArea: PaneArea) {
    const area = this.convertArea(testArea)
    // TODO iny align
    if (
      this.attrs.x! < area.x &&
      area.x < this.attrs.x! + this.width &&
      this.attrs.y! - this.attrs.font.size < area.y &&
      area.y < this.attrs.y! - this.attrs.font.size + this.height
    ) {
      return true
    }
    return super.isInArea(testArea)
  }

  setupContext(context: CanvasRenderingContext2D) {
    this.context = context
    context.set('font', this.font)
    context.set('fillStyle', this.attrs.fillStyle)
    context.set('textAlign', this.attrs.textAlign)
    context.set('textBaseline', this.attrs.textBaseline)
  }

  getLines() {
    return this.attrs.text.toString().split('\n')
  }

  renderText() {
    if (!this.context) return
    let { y } = this.attrs // + @attrs.padding.top
    for (const line of this.getLines()) {
      this.context.fillText(line, this.attrs.x! + this.attrs.padding.left, y!)
      y! += this.attrs.lineHeight
    }
  }

  renderBackground() {
    if (!this.attrs.background || !this.context) {
      return
    }

    const { width } = this
    const { height } = this
    const rawHight = height - this.attrs.padding.top - this.attrs.padding.bottom
    const { x } = this.attrs
    let y = this.attrs.y! - height

    const getFontHeight = () => {
      // @todo - This is needed to be able to verticaly center background behind the text, the better would be to always use TextBaseline.middle and in case of options having different TextBaseline we should move text manually on y axis
      const { fontBoundingBoxAscent = 0, fontBoundingBoxDescent = 0 } = this.context?.measureText(this.attrs.text) ?? {}
      return fontBoundingBoxAscent + fontBoundingBoxDescent
    }

    if (this.attrs.textBaseline === TextBaseline.bottom) {
      y = this.attrs.y! - (height - (height - getFontHeight()) / 2)
    } else if (this.attrs.textBaseline === TextBaseline.top) {
      y = this.attrs.y! - this.attrs.padding.top - 1 - (height - getFontHeight()) / 2
    } else if (this.attrs.textBaseline === TextBaseline.middle) {
      y = this.attrs.y! - rawHight / 2 - this.attrs.padding.top - 1
    } else if (this.attrs.textBaseline === TextBaseline.alphabetic) {
      y = this.attrs.y! - this.attrs.lineHeight
    }

    this.context.beginPath()
    this.context.set('fillStyle', this.attrs.background)
    this.context.rect(x!, y, width, height)
    this.context.fill()
    this.context.set('fillStyle', this.attrs.fillStyle)
    this.renderBorder()
    this.context.closePath()
  }

  renderBorder() {
    const { lineWidth = 0, strokeStyle, border } = this.attrs
    const borderWidth = border?.width ?? 0

    if ((lineWidth || this.attrs.strokeStyle || borderWidth > 0) && this.context) {
      this.context.set('strokeStyle', strokeStyle ?? border?.color ?? '')
      this.context.set('lineWidth', Math.max(lineWidth, borderWidth))
      this.context.stroke()
    }
  }

  private convertArea(area: PaneArea) {
    const { ChartSettings } = this.getChartLayoutSettings()
    return {
      x: this.model.scale.x(area.x) + this.model.chart().leftOffset + ChartSettings.left.width,
      y: this.model.scale.y(area.y) + ChartSettings.top.height,
    }
  }

  moveBy(x: number, y: number) {
    this.attrs.x! += this.model.scale.x(x) + 1
    this.attrs.y! += this.model.scale.y(y) - this.model.scale.y(0)
  }

  getHeight() {
    return this.height
  }
}

Text.initClass()

export default Text
