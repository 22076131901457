import { IndicatorType } from '../constants/common'
import adx from './adx'
import aro from './aro'
import aroosc from './aroosc'
import atr from './atr'
import cci from './cci'
import cot from './cot'
import fi from './fi'
import { generateFundamentalIndicators } from './fundamentalGeneric'
import indicator from './indicator'
import macd from './macd'
import mfi from './mfi'
import none from './none'
import obv from './obv'
import perf from './perf'
import rmi from './rmi'
import roc from './roc'
import rsi from './rsi'
import rvol from './rvol'
import rwi from './rwi'
import shrtfl from './shrtfl'
import shrtra from './shrtra'
import stofa from './stofa'
import stofu from './stofu'
import stosl from './stosl'
import trix from './trix'
import ult from './ult'
import vol from './vol'
import wr from './wr'

const generatedFundamentalIndicators = generateFundamentalIndicators()

// TODO check if this doesn't break saved charts
export const indicatorsByType = Object.freeze({
  [IndicatorType.Indicator]: indicator,
  [IndicatorType.None]: none,
  [IndicatorType.Adx]: adx,
  [IndicatorType.Aro]: aro,
  [IndicatorType.Aroosc]: aroosc,
  [IndicatorType.Atr]: atr,
  [IndicatorType.Cci]: cci,
  [IndicatorType.Cot]: cot,
  [IndicatorType.Fi]: fi,
  [IndicatorType.Macd]: macd,
  [IndicatorType.Mfi]: mfi,
  [IndicatorType.Obv]: obv,
  [IndicatorType.Perf]: perf,
  [IndicatorType.Rmi]: rmi,
  [IndicatorType.Roc]: roc,
  [IndicatorType.Rsi]: rsi,
  [IndicatorType.Rvol]: rvol,
  [IndicatorType.Rwi]: rwi,
  [IndicatorType.Stofa]: stofa,
  [IndicatorType.Stofu]: stofu,
  [IndicatorType.Stosl]: stosl,
  [IndicatorType.Trix]: trix,
  [IndicatorType.Ult]: ult,
  [IndicatorType.Vol]: vol,
  [IndicatorType.Wr]: wr,
  [IndicatorType.Shrtfl]: shrtfl,
  [IndicatorType.Shrtra]: shrtra,

  ...generatedFundamentalIndicators,
})

export const financialIndicators = Object.freeze([
  IndicatorType.Shrtfl,
  IndicatorType.Shrtra,
  ...(Object.keys(generatedFundamentalIndicators) as IndicatorType[]),
])
export const indicatorsWithoutPeriod = Object.freeze([IndicatorType.Obv, IndicatorType.Vol, ...financialIndicators])
