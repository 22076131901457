import Spine from '@finviz/spine'
import {
  Button,
  Notification,
  NotificationContextType,
  Paragraph,
  hasChartContextMenuGainedFocus,
  withNotificationContext,
} from '@finviz/website'
import classnames from 'classnames'
import Hammer from 'hammerjs'
import debounce from 'lodash.debounce'
import React from 'react'

import { CustomSpineEvents, DrawingSpineOptionsEvent, EmptyObject, ObjectHash, PaneArea } from '../../../types/shared'
import CanvasElement from '../../canvas/element'
import ChartEvent from '../../chart-events/chart-event'
import { IndicatorType, ScaleType, SetVisibilityTo, SpecificChartFunctionality } from '../../constants/common'
import IndicatorElement from '../../indicators/indicator'
import { withElementStyleDialogState } from '../../modals/element_style'
import ChartModel from '../../models/chart'
import ChartEventElement from '../../models/chart-event-element'
import ElementModel from '../../models/element'
import MouseModel from '../../models/mouse'
import PaneModel from '../../models/pane'
import Quote from '../../models/quote'
import { handleRemoveIndicatorPane } from '../../models/utils'
import Utils, { isRedesignedPage } from '../../utils'
import { getContextWithCache } from '../../utils/canvas'
import { unmountCanvas } from '../../utils/chart'
import { setElementCursor } from '../../utils/cursor'
import { getValueInRange, isPrimaryClick } from '../../utils/helpers'
import { getInitialStoreDrawings } from '../autosave/utils'
import { CHART_LAYOUT_CHILD_CHANGE_EVENT } from '../chart_layout/constants'
import ContextMenu from '../context_menu'
import { getSettings, recountScale, renderPane } from '../renderUtils'
import { DrawingTool } from '../toolbar/interfaces'
import { getChartAlt } from '../utils'
import { MainChartDataLoader } from './MainChartDataLoader'
import { ChartEventPopoverWithState } from './chart-event-popover-with-state'
import { withPaneModel } from './with-pane-model'
import { ZOOM_CONTROLS_ACTIVE_CLASS, ZOOM_CONTROLS_IS_IN_AREA_CLASS, ZoomControls } from './zoom_controls'

// Indicates how many time lower/higher can new scale be compared to original one
const ALLOWED_MIN_MAX_ZOOM = 10000

interface PaneProps {
  model: PaneModel
  chartModel: ChartModel
  activeTool: DrawingTool
  activeChartInteraction: string | null
  chartIndex: number
  paneIndex: number
  touchEventsDisabled: boolean
  onPaneClick?: (model: PaneModel) => void
  onZoomerReset: () => void
  onZoomerMouseDown: React.MouseEventHandler<HTMLDivElement>
  onZoomerTouchStart: React.TouchEventHandler<HTMLDivElement>
  onAddToZoomFactor: (data: { diff: number; shouldCalculateRightAlign: boolean }) => void
  onResizerMouseDown: (e: React.MouseEvent<HTMLDivElement>, model: PaneModel) => void
  onPaneDestroy: (paneId: string) => void
  setActiveChartInteraction: (value: string | null) => void
  openElementStyleDialog?: (element: CanvasElement | IndicatorElement) => void
  notificationContext: NotificationContextType
  addToLeftOffset: (n: number) => void
}

interface PaneState {
  hasSeenCrossNotification: boolean
}

function toggleE2eRenderInProgress({ id, isInProgress }: { id: number; isInProgress: boolean }) {
  if (process.env.IS_E2E_TESTING) {
    window.rendersInProgress = window.rendersInProgress ?? []

    if (isInProgress) {
      window.isRenderInProgress = true
      window.rendersInProgress.push(id)
    } else {
      window.rendersInProgress = window.rendersInProgress.filter((progressId) => id !== progressId)

      if (window.rendersInProgress.length === 0) {
        window.isRenderInProgress = Date.now()
      }
    }
  }
}

class Pane extends React.Component<PaneProps, PaneState> {
  rafId?: number | null = null
  crossRafId?: number | null = null
  quoteModel: Quote
  paneRef = React.createRef<HTMLDivElement>()
  scalerRef = React.createRef<HTMLDivElement>()
  zoomerRef = React.createRef<HTMLDivElement>()
  zoomControlsRef = React.createRef<HTMLDivElement>()
  canvasWrapperRef = React.createRef<HTMLDivElement>()
  canvasRef = React.createRef<HTMLCanvasElement>()
  crossCanvasRef = React.createRef<HTMLCanvasElement>()
  chartLayoutModel = this.props.chartModel.chart_layout()
  paneHammer!: HammerManager
  scalerHammer!: HammerManager
  zoomerHammer!: HammerManager
  canvasWrapperHammer!: HammerManager
  mouseDown = false
  lastClientX?: number
  lastClientY?: number
  currentViewRange: { min: number; max: number } | null = null
  lastX!: number
  lastY!: number
  lastActiveTool: DrawingTool
  scalerLastY: number | null = null
  isElementHovered = false
  hoveredElement: CanvasElement | null = null
  isMobile: boolean
  isDisableTouchCrossAvailable = true

  canvas: HTMLCanvasElement | null = null
  canvasCtx: CanvasRenderingContext2D | null = null
  crossCanvas: HTMLCanvasElement | null = null
  crossCanvasCtx: CanvasRenderingContext2D | null = null
  isIndicatorPane = false

  state: PaneState = {
    hasSeenCrossNotification: !!window.localStorage?.getItem('hasSeenCrossNotification'),
  }

  // expects chartModel, model, onResizerMouseDown, onPaneDestroy
  constructor(props: PaneProps) {
    super(props)

    this.quoteModel = this.props.chartModel.quote()
    props.model.updateAttribute('instance', this)
    props.model.bind('update', this.onModelUpdate)
    props.model
      .elements()
      .bind('create', this.onElementCreate)
      .bind('destroy', this.onElementDestroy)
      .bind('replace', this.onElementReplace)
      .bind('select', this.onElementSelect)
      .bind('makeClone', this.onElementClone)
      .bind('change', this.immediateRecountScaleOnChartIndicatorChange)
      .bind('change', debounce(this.recountScale, 50))
    props.model.bind(CustomSpineEvents.ManualChange, this.recountScale)

    props.model.chartEvents().bind('create', this.onElementCreate).bind('change', debounce(this.recountScale, 50))

    this.props.chartModel.bind('update', this.onChartUpdate)
    this.props.chartModel.bind(`change ${CustomSpineEvents.IndicatorsChange}`, this.recountScale)
    this.quoteModel.bind('change', this.recountScale)
    this.isMobile = !!Utils.isMobile()
    this.lastActiveTool = props.activeTool
    if (!MouseModel.pane()) {
      MouseModel.updateAttribute('pane', props.model)
    }
    this.isIndicatorPane = props.model.getIsIndicatorPane()
    this.recountScale()
  }

  componentDidMount() {
    const { model } = this.props
    this.chartLayoutModel.bind('theme', this.renderAll)
    this.chartLayoutModel.bind('theme', this.renderCross)

    const initialStoreDrawings = getInitialStoreDrawings() ?? []
    const elements = [...model.getAllElements(), ...model.getAllChartEvents(false)]
    elements.forEach((element) => {
      this.onElementCreate(element)
      if (element.isDrawing()) {
        const hasDrawingInInitialCache = initialStoreDrawings.some(({ elementId }) => element.elementId === elementId)
        if (hasDrawingInInitialCache) {
          element.instance?.updateScales()
        }
        element.instance.cachePointPositionTimestamp()
      } else if (element.isChartEvent()) {
        element.instance?.updateScales()
      }
    })

    model?.updateChartEventsZIndexes()

    this.canvas = this.canvasRef.current
    this.canvasCtx = getContextWithCache(this.canvasRef.current)
    this.crossCanvas = this.crossCanvasRef.current
    this.crossCanvasCtx = getContextWithCache(this.crossCanvasRef.current)

    this.paneHammer = new Hammer.Manager(this.paneRef.current!, { touchAction: 'none' })
    this.paneHammer.add(new Hammer.Press({ threshold: 5, time: 500 })) // chrome simulator "long touch" -> "oncontextmenu" is 680ms +- 2ms
    this.paneHammer.on('press', this.onPress)
    this.canvasWrapperHammer = new Hammer(this.canvasWrapperRef.current!, { touchAction: 'none' })
    this.canvasWrapperHammer.on('tap', this.onClick)
    this.canvasWrapperRef.current?.addEventListener('touchmove', this.onTouchMove, { passive: false })

    if (this.chartLayoutModel.scrollable || !this.isMobile) {
      if (this.scalerRef.current) {
        this.scalerHammer = new Hammer(this.scalerRef.current, { touchAction: 'none' })
        this.scalerHammer.on('doubletap', this.onScalerReset)
      }
      if (this.zoomerRef.current) {
        this.zoomerHammer = new Hammer(this.zoomerRef.current, { touchAction: 'none' })
        this.zoomerHammer.on('doubletap', this.props.onZoomerReset)
      }
      this.canvasWrapperHammer.on('doubletap', this.onDoubleClick)
    }

    this.onChartUpdate({ width: this.props.chartModel.width })
    this.onModelUpdate({ height: model.height })
    this.forceUpdate()
    this.chartLayoutModel.trigger(CHART_LAYOUT_CHILD_CHANGE_EVENT)
  }

  componentWillUnmount() {
    this.canvasWrapperRef.current?.removeEventListener('touchmove', this.onTouchMove)
    unmountCanvas(this.canvas)
    unmountCanvas(this.crossCanvas)
    this.props.chartModel.unbind('update', this.onChartUpdate)
    this.props.chartModel.unbind('change', this.recountScale)
    this.quoteModel.unbind('change', this.recountScale)
  }

  render() {
    const { activeTool, touchEventsDisabled, onPaneClick, paneIndex, chartIndex, model, chartModel } = this.props
    if (touchEventsDisabled) {
      this.paneHammer?.set({ touchAction: 'auto' })
      this.scalerHammer?.set({ touchAction: 'auto' })
      this.zoomerHammer?.set({ touchAction: 'auto' })
      this.canvasWrapperHammer?.set({ touchAction: 'auto' })
    } else {
      const touchAction = this.getIsHammerAllowScrollActive() ? 'auto' : 'none'
      this.scalerHammer?.set({ touchAction: 'none' })
      this.zoomerHammer?.set({ touchAction: 'none' })
      this.paneHammer?.set({ touchAction })
      this.canvasWrapperHammer?.set({ touchAction })
    }

    const isChartPane = model.getIsChartPane()

    return (
      <div
        className="pane"
        ref={this.paneRef}
        onMouseMove={this.onPaneMouseMove}
        onMouseLeave={this.onPaneMouseLeave}
        onClick={() => onPaneClick?.(model)}
        onTouchStart={() => onPaneClick?.(model)}
        onContextMenu={this.onContextMenu}
        data-testid={`chart-${chartIndex}-pane-${paneIndex}`}
      >
        {isChartPane && <ChartEventPopoverWithState chartModel={chartModel} />}
        {paneIndex !== 0 && (
          <div
            className="resizer"
            onMouseDown={(e) => this.props.onResizerMouseDown(e, model)}
            data-testid={`chart-${chartIndex}-pane-${paneIndex}-resizer`}
          />
        )}
        {this.chartLayoutModel.initialScrollable && (
          <div
            className={classnames('scaler absolute right-0 z-10 block w-[42px] cursor-[ns-resize] select-none', {
              'bottom-2.5 top-2.5': this.isIndicatorPane,
              'bottom-7.5 top-5': !this.isIndicatorPane,
            })}
            ref={this.scalerRef}
            onMouseDown={this.onScalerMouseDown}
            onMouseEnter={this.onScalerMouseEnter}
            onTouchStart={this.onScalerTouchStart}
            onDoubleClick={this.onScalerReset}
            data-testid={`chart-${chartIndex}-pane-${paneIndex}-scaler`}
          />
        )}

        {this.chartLayoutModel.initialScrollable && isChartPane && (
          <>
            <div
              className="zoomer"
              ref={this.zoomerRef}
              onMouseDown={this.props.onZoomerMouseDown}
              onMouseMove={this.onZoomerMouseMove}
              onTouchStart={this.props.onZoomerTouchStart}
              onDoubleClick={this.props.onZoomerReset}
              data-testid={`chart-${chartIndex}-pane-${paneIndex}-zoomer`}
            />
            <ZoomControls
              chartModel={this.props.chartModel}
              zoomControlsRef={this.zoomControlsRef}
              isVisible={activeTool === DrawingTool.Mouse && model.selection === null}
              onZoomIn={() => {
                this.props.onAddToZoomFactor({ diff: 0.1, shouldCalculateRightAlign: true })
              }}
              onZoomOut={() => {
                this.props.onAddToZoomFactor({ diff: -0.1, shouldCalculateRightAlign: true })
              }}
              onZoomReset={this.props.onZoomerReset}
            />
            <MainChartDataLoader chartModel={chartModel} />
          </>
        )}
        <div
          className="canvas outline-none"
          ref={this.canvasWrapperRef}
          tabIndex={0}
          data-testid={`chart-${chartIndex}-pane-${paneIndex}-canvas`}
          onMouseDown={!this.isMobile ? this.onCanvasInteractionStart : undefined}
          onMouseMove={!this.isMobile ? this.onCanvasInteractionMove : undefined}
          onMouseLeave={this.onMouseLeave}
          onTouchStart={this.isMobile ? this.onTouchStart : undefined}
          onTouchEnd={this.onTouchEnd}
          onBlur={this.onBlur}
        >
          <canvas ref={this.canvasRef}>{getChartAlt(model, this.quoteModel)}</canvas>
          {this.chartLayoutModel.cross && <canvas className="second" ref={this.crossCanvasRef} />}
        </div>
      </div>
    )
  }

  getIsHammerAllowScrollActive = () =>
    this.chartLayoutModel.specificChartFunctionality !== SpecificChartFunctionality.chartPage &&
    !this.chartLayoutModel.isTouchCrossActive &&
    !this.chartLayoutModel.scrollable

  onElementCreate = (el: ElementModel | ChartEventElement) => {
    el.instance.bind('change', this.renderAll)
    this.renderAll()
  }

  onElementReplace = (el: ElementModel, oldElementInstance: CanvasElement) => {
    oldElementInstance.unbind('change', this.renderAll)
    el.instance.bind('change', this.renderAll)
    if (oldElementInstance === this.props.model.selection && !this.chartLayoutModel.isDrawingModeContinuousActive) {
      this.setSelection(el.instance)
    } else {
      this.setSelection(null)
    }
    this.renderAll()
  }

  onElementDestroy = (el: ElementModel) => {
    const { type } = el.instance
    el.instance.unbind('change', this.renderAll)
    if (el.instance === this.props.model.selection) {
      this.setSelection(null)
    }
    if (type.startsWith('charts/') || type.startsWith('indicators/')) {
      this.props.onPaneDestroy(el['pane_id'])
    }
    this.renderAll()
  }

  onElementSelect = (el: ElementModel) => {
    this.setSelectedElement(el.instance)
  }

  onElementClone = (_: unknown, clonedElementInstance: CanvasElement) => {
    this.setSelectedElement(clonedElementInstance)
  }

  onModelUpdate = ({ height }: { height: number }) => {
    this.setCanvasSize({
      width: this.props.chartModel.width,
      height,
    })
  }

  onChartUpdate = ({ width }: { width: number }) => {
    const { model, chartModel } = this.props
    this.setCanvasSize({
      width,
      height: model.height,
    })
    const quote = chartModel.quote()
    if (!this.quoteModel.eql(quote)) {
      this.quoteModel.unbind('change', this.recountScale)
      if (quote) {
        this.quoteModel = quote
        this.quoteModel.bind('change', this.recountScale)
      }
    }
  }

  onZoomerMouseMove: React.MouseEventHandler<HTMLDivElement> = (e) => {
    if (!this.chartLayoutModel.scrollable || (this.isMobile && !this.chartLayoutModel.isTouchCrossActive)) return
    const area = this.getArea(e)
    MouseModel.updateAttributes({
      position: { x: area.x, y: null },
      pane: this.props.model,
    })
  }

  onMouseMoveOnScrolling = (event: TouchEvent | MouseEvent) => {
    if (this.props.activeChartInteraction) {
      return
    }

    let { clientX, clientY } = event as MouseEvent
    const { model } = this.props

    const isTouch = typeof TouchEvent !== 'undefined' && event instanceof TouchEvent
    if (isTouch && event.targetTouches?.length > 0) {
      clientX = event.targetTouches[0].clientX
      clientY = event.targetTouches[0].clientY
    }
    if (!model.selection && !this.chartLayoutModel.activeChartEvent) {
      if (this.lastClientX != null) {
        const dif = this.lastClientX - clientX
        this.props.addToLeftOffset(-dif)
      }
      if (model.scaleRange && this.lastClientY != null) {
        let { min, max } = model.scaleRange
        let difY
        switch (this.props.chartModel.scale) {
          case ScaleType.Logarithmic: {
            difY = this.lastClientY - clientY
            min = model.scale.y.invert(model.scale.y(min) + difY)
            max = model.scale.y.invert(model.scale.y(max) + difY)
            break
          }
          default: {
            difY = (this.lastClientY - clientY) * (model.scale.y.invert(0) - model.scale.y.invert(1))
            min -= difY
            max -= difY
            break
          }
        }

        model.updateAttribute('scaleRange', { min, max })
        this.recountScale()
        this.currentViewRange = this.props.model.getChartOrIndicatorElement()?.instance?.getMinMax?.() ?? null
      }
    }
    this.lastClientX = clientX
    this.lastClientY = clientY
  }

  onScalerTouchStart: React.TouchEventHandler<HTMLDivElement> = (e) => {
    if (!this.chartLayoutModel.scrollable || this.props.touchEventsDisabled) return
    if (this.chartLayoutModel.scrollable && e.targetTouches.length === 1) {
      this.onScalerMouseDown(e.targetTouches[0])
    }
  }

  onScalerTouchMove = (e: TouchEvent) => {
    if (this.chartLayoutModel.scrollable && e.targetTouches.length === 1) {
      this.onScalerMouseMove(e.targetTouches[0])
    }
  }

  onScalerTouchEnd = () => {
    if (this.chartLayoutModel.scrollable) {
      this.onScalerMouseUp()
    }
  }

  onScalerMouseEnter = () => {
    MouseModel.updateAttributes({
      position: null,
      pane: null,
    })
  }

  onScalerMouseDown = (event: React.MouseEvent<HTMLDivElement> | React.Touch) => {
    const { activeChartInteraction, setActiveChartInteraction, model } = this.props
    if ((event instanceof MouseEvent && event.button) || activeChartInteraction || !this.chartLayoutModel.scrollable) {
      return
    }
    setActiveChartInteraction('scaler')

    this.scalerLastY = event.clientY
    // here we need current domain scale or former "nice" value
    const [currentDomainMax, currentDomainMin] = model.scale.y.domain()
    this.currentViewRange = { min: currentDomainMin, max: currentDomainMax }
    document.addEventListener<'mousemove'>('mousemove', this.onScalerMouseMove)
    document.addEventListener('mouseup', this.onScalerMouseUp)
    document.addEventListener('touchmove', this.onScalerTouchMove)
    document.addEventListener('touchend', this.onScalerTouchEnd)
  }

  onScalerMouseMove = (event: MouseEvent | Touch) => {
    const { model, chartModel } = this.props
    if (model.scaleRange === null && this.currentViewRange === null) {
      return
    }
    let { min, max } = model.scaleRange || this.currentViewRange!
    let difY
    switch (this.props.chartModel.scale) {
      case ScaleType.Logarithmic: {
        difY = this.scalerLastY! - event.clientY
        min = model.scale.y.invert(model.scale.y(min) - difY)
        max = model.scale.y.invert(model.scale.y(max) + difY)
        break
      }
      default: {
        difY = -(this.scalerLastY! - event.clientY) * (model.scale.y.invert(0) - model.scale.y.invert(1))
        min -= difY
        max += difY
        break
      }
    }
    this.scalerLastY = event.clientY

    const originalMinMax = model.getChartOrIndicatorElement()?.instance?.getMinMax?.()
    if (originalMinMax) {
      const currentDiff = Math.abs(max - min)
      const originalDiff = Math.abs(originalMinMax.max - originalMinMax.min)

      if (currentDiff > originalDiff * ALLOWED_MIN_MAX_ZOOM || currentDiff < originalDiff / ALLOWED_MIN_MAX_ZOOM) {
        return
      }
    }

    model.updateAttribute('scaleRange', { min, max })
    chartModel.updateAttribute('dateRange', null)
    this.recountScale()
  }

  onScalerMouseUp = () => {
    this.scalerLastY = null

    document.removeEventListener('mousemove', this.onScalerMouseMove)
    document.removeEventListener('mouseup', this.onScalerMouseUp)
    document.removeEventListener('touchmove', this.onScalerTouchMove)
    document.removeEventListener('touchend', this.onScalerTouchEnd)
    this.props.setActiveChartInteraction(null)
  }

  onScalerReset = () => {
    const { model, chartModel } = this.props
    if (!this.chartLayoutModel.scrollable) return
    chartModel.updateAttribute('dateRange', null)
    model.updateAttribute('scaleRange', null)
    this.recountScale()
  }

  onDoubleClick = (event: HammerInput) => {
    event.preventDefault()
    const area = this.getArea(event)
    const element = this.props.model.selection || this.getElement(area)
    if (element && element.type !== IndicatorType.Cot && !element.isCreator) {
      setTimeout(() => {
        this.props.openElementStyleDialog!(element)
      }, 0)
      element.trigger('dblclick', event)
    }
  }

  onPress = (e: ObjectHash) => {
    if (e.pointerType !== 'touch') return

    e.clientX = e.center.x
    e.clientY = e.center.y

    const el = this.props.model.selection || this.getElement(this.getArea(e))
    if (!this.chartLayoutModel.isTouchCrossActive) {
      if (!el) {
        this.onCanvasPress(e as React.MouseEvent)
      } else {
        this.onContextMenu(e as React.MouseEvent, el)
      }
    }
  }

  onContextMenu = (e: React.MouseEvent, element?: IndicatorElement | CanvasElement) => {
    e.preventDefault()
    const area = this.getArea(e)
    const elementInArea = this.getElement(area)
    const el = element ?? elementInArea
    if (!el) {
      return
    }
    const elementModel = ElementModel.findByAttribute<ElementModel>('instance', el)
    if (!elementModel) return
    const hasProperties =
      ['indicator', 'canvas'].some((type) => el.type.startsWith(type)) && el.type !== IndicatorType.Cot
    const isElementDrawing = elementModel.isDrawing()

    this.setSelectedElement(el)
    if (el instanceof CanvasElement) {
      el.setIsEditInProgress(true)
    }

    const onContextMenuClose = () => {
      if (el instanceof CanvasElement) {
        el.setIsEditInProgress(false)
      }
    }

    return ContextMenu.show(
      e,
      [
        hasProperties && {
          id: 'edit',
          label: 'Edit style',
          onClick: () => this.props.openElementStyleDialog!(el),
        },

        !(elementModel.isChart() || elementModel?.isIndicator()) && {
          id: 'clone',
          label: 'Clone',
          onClick: () => elementModel.makeClone(),
        },

        ...(isElementDrawing
          ? [
              { type: 'divider', label: 'Visibility' },

              {
                id: 'visibility-current-and-above',
                label: 'Current interval and above',
                onClick: () => elementModel.instance.setVisibilityTo(SetVisibilityTo.currentAndAbove),
              },

              {
                id: 'visibility-current-and-below',
                label: 'Current interval and below',
                onClick: () => elementModel.instance.setVisibilityTo(SetVisibilityTo.currentAndBelow),
              },

              {
                id: 'visibility-current-only',
                label: 'Current interval only',
                onClick: () => elementModel.instance.setVisibilityTo(SetVisibilityTo.currentOnly),
              },

              {
                id: 'visibility-all-intervals',
                label: 'All intervals',
                onClick: () => elementModel.instance.setVisibilityTo(SetVisibilityTo.all),
              },
            ]
          : []),

        (isElementDrawing || hasProperties) && { type: 'divider' },

        {
          id: 'bring-to-front',
          label: 'Bring to Front',
          onClick: () => elementModel.bringToFront(),
        },
        {
          id: 'send-to-back',
          label: 'Send to Back',
          onClick: () => elementModel.sendToBack(),
        },
        {
          id: 'bring-forward',
          label: 'Bring Forward',
          onClick: () => elementModel.bringForward(),
        },
        {
          id: 'send-backward',
          label: 'Send Backward',
          onClick: () => elementModel.sendBackward(),
        },

        { type: 'divider' },

        {
          id: 'remove',
          label: 'Remove',
          onClick: () => {
            if (elementModel.isIndicator()) {
              handleRemoveIndicatorPane({ paneIndex: this.props.paneIndex, chartLayoutModel: this.chartLayoutModel })
            } else {
              elementModel.destroyCascade({ eventType: DrawingSpineOptionsEvent.Remove })
              this.props.model.normalizeZIndexes()
            }
          },
        },
      ]
        .filter(Boolean)
        .map((item) => ({ ...item, gtag: { element_type: el.type } })),
      { onBeforeItemClick: onContextMenuClose, onFullyClosed: onContextMenuClose }
    )
  }

  immediateRecountScaleOnChartIndicatorChange = (element?: ElementModel) => {
    if (element?.isChart() || element?.isIndicator()) {
      this.recountScale()
    }
  }

  recountScale = (_?: unknown, updateType?: Spine.Event) => {
    const { model } = this.props
    const chartModel = model.chart()
    if (updateType === 'destroy' || !chartModel || !chartModel.quote()) {
      return
    }

    recountScale({ chartModel, paneModel: model })

    if (this.canvasCtx) {
      this.renderAll()
    }
  }

  onBlur = (event: React.FocusEvent<HTMLDivElement> | undefined) => {
    const isContextMenuFocused = hasChartContextMenuGainedFocus(event)
    if (this.props.model.selection?.getIsCreator?.()) {
      this.setSelection(null)
    } else if (!isContextMenuFocused) {
      this.setSelectedElement(null)
    }
    if (this.hoveredElement && !isContextMenuFocused) {
      this.hoveredElement.setIsHovered(false)
      this.hoveredElement = null
    }
    this.props.model.getAllElements().forEach((element) => {
      if (element.instance.getIsCreator?.() && !element.instance.getIsCreatorDialogOpen?.()) {
        element.destroyCascade()
      }
    })
  }

  renderAll = (type?: string) => {
    if (process.env.IS_E2E_TESTING) window.isRenderInProgress = true

    if (type === 'cross' && this.chartLayoutModel.cross) {
      if (this.crossRafId === null) {
        const crossRafId = window.requestAnimationFrame(() => {
          this.renderCross()
          if (process.env.IS_E2E_TESTING) {
            toggleE2eRenderInProgress({ id: crossRafId, isInProgress: false })
          }
        })
        if (process.env.IS_E2E_TESTING) {
          toggleE2eRenderInProgress({ id: crossRafId, isInProgress: true })
        }
        this.crossRafId = crossRafId
      }
    } else if (this.rafId == null && this.canvasCtx) {
      const rafId = window.requestAnimationFrame(() => {
        this._renderAll()
        if (process.env.IS_E2E_TESTING) {
          toggleE2eRenderInProgress({ id: rafId, isInProgress: false })
        }
      })
      if (process.env.IS_E2E_TESTING) {
        toggleE2eRenderInProgress({ id: rafId, isInProgress: true })
      }
      this.rafId = rafId
    }
  }

  _renderAll = () => {
    const { model } = this.props
    // _renderAll may be called after Chart was deleted because of requestAnimationFrame
    if (model.chart() === null || this.canvasRef.current === null || !this.canvasCtx) {
      return
    }
    if (this.getIsUpdatedDesign()) {
      if (this.crossRafId === null) {
        this.crossRafId = window.requestAnimationFrame(this.renderCross)
      }
    }
    this.rafId = undefined

    renderPane({
      chartLayoutModel: this.chartLayoutModel,
      chartModel: this.props.chartModel,
      paneModel: model,
      canvasCtx: this.canvasCtx,
    })
  }

  getIsUpdatedDesign() {
    const { specificChartFunctionality } = this.chartLayoutModel
    const isCharts = specificChartFunctionality === SpecificChartFunctionality.chartPage
    const isRedesignedFuturesForexCrypto = isRedesignedPage({ specificChartFunctionality })

    return isCharts || isRedesignedFuturesForexCrypto
  }

  renderCross = () => {
    const { model } = this.props
    const isMouseInPane = MouseModel.getShouldRenderCrossInPane(model)
    this.crossRafId = null
    if (!this.crossCanvasCtx) {
      return
    }
    this.crossCanvasCtx.clearRect(0, 0, this.props.chartModel.width, model.height)
    const isCrossDisabled =
      PaneModel.select<PaneModel>((p) => !!p.selection && !p.selection.type.startsWith('indicators/')).length !== 0 ||
      this.props.chartModel.getIsDisabled() ||
      !isMouseInPane

    for (const { instance: elementInstance } of model.getAllElements()) {
      if (
        (!isCrossDisabled && elementInstance.renderCross != null) ||
        (isCrossDisabled && elementInstance.renderCrossText != null)
      ) {
        this.crossCanvasCtx.save()
        if (isCrossDisabled) {
          elementInstance.renderCrossText!(this.crossCanvasCtx, Number.NaN)
        } else {
          elementInstance.renderCross!(this.crossCanvasCtx)
        }
        this.crossCanvasCtx.restore()
      }
    }
  }

  getArea = <T extends ObjectHash = EmptyObject>(e: ObjectHash, additionalData?: T): PaneArea<T> => {
    const { model } = this.props
    const clientX = e.clientX || additionalData?.clientX
    const clientY = e.clientY || additionalData?.clientY
    const Settings = getSettings(model)
    const areaRectangle = this.canvas!.getBoundingClientRect()
    const areaRectangleCursorX = clientX - areaRectangle.left
    const areaRectangleCursorY = clientY - areaRectangle.top
    const x = areaRectangleCursorX - model.chart().leftOffset - Settings.left.width
    const y = areaRectangleCursorY - Settings.top.height
    const scale = model.scale
    const chartLeftX = areaRectangle.left + Settings.left.width
    const chartRightX = areaRectangle.right - Settings.right.width
    return {
      ...(additionalData as T),
      // TODO: rewrite if canvas uses padding/border
      x: scale.x.invert(x),
      y: scale.y.invert(y),
      scaled: {
        x,
        y,
      },
      width: 1,
      height: 1,
      cursorX: areaRectangleCursorX,
      cursorY: areaRectangleCursorY,
      isCursorInChartWidthBoundaries: clientX > chartLeftX && clientX < chartRightX,
    }
  }

  setCanvasSize = ({ width, height }: { width: number; height: number }) => {
    const ratio = Utils.getScaleRatio()
    const canvasElement = this.canvasRef.current

    /**
     * It seems that value passed into element attr (e.g. height) is ceiled down,
     * so if you provide canvas element with height 100.5 we'll get  integer 100 from canvasElement.height
     * If the difference in values between "new height" and canvasElement.height is less than 1, I treat it as the same value
     */
    if (
      this.canvasCtx &&
      canvasElement &&
      (Math.abs(height * ratio - canvasElement.height) >= 1 || Math.abs(width * ratio - canvasElement.width) >= 1)
    ) {
      Utils.setSizeOnCanvasElement({ canvasElement, width, height })
      this.canvasCtx.scale(ratio, ratio)
      if (this.chartLayoutModel.cross && this.crossCanvasCtx) {
        Utils.setSizeOnCanvasElement({
          canvasElement: this.crossCanvasRef.current,
          width,
          height,
        })
        this.crossCanvasCtx.scale(ratio, ratio)
      }
      setTimeout(() => {
        this.recountScale()
      })
    }
  }

  onClick = (event: HammerInput) => {
    const area = this.getArea(event, {
      clientX: event.center.x,
      clientY: event.center.y,
    })
    const element = this.props.model.selection || this.getElement(area)
    element?.trigger('click', area)

    if (!this.isDisableTouchCrossAvailable) {
      this.isDisableTouchCrossAvailable = true
      return
    }

    if (this.chartLayoutModel.isTouchCrossActive) {
      MouseModel.updateAttributes({
        position: null,
        pane: null,
      })
      this.chartLayoutModel.updateAttributes({
        scrollable: this.chartLayoutModel.initialScrollable,
        isTouchCrossActive: false,
      })
    } else if (
      this.isMobile &&
      !this.state.hasSeenCrossNotification &&
      this.lastActiveTool === DrawingTool.Mouse &&
      this.chartLayoutModel.specificChartFunctionality !== SpecificChartFunctionality.smallIndex
    ) {
      const isChartsPage = this.chartLayoutModel.specificChartFunctionality === SpecificChartFunctionality.chartPage

      this.setState({ hasSeenCrossNotification: true })
      this.props.notificationContext.show(
        <Notification
          actions={
            <Button
              onClick={() => {
                this.props.notificationContext.hide()
                window.localStorage.setItem('hasSeenCrossNotification', 'true')
              }}
              theme="dark"
            >
              Got it
            </Button>
          }
        >
          <Paragraph className="max-w-40">Long press the chart to show the crosshair.</Paragraph>
        </Notification>,
        { inline: !isChartsPage }
      )
    }

    this.lastActiveTool = this.props.activeTool
  }

  onPaneMouseLeave = () => {
    // timout in onPaneMouseMove may cause racecondition where active class is added right after it's been removed
    setTimeout(() => {
      this.zoomControlsRef.current?.classList.remove(ZOOM_CONTROLS_ACTIVE_CLASS, ZOOM_CONTROLS_IS_IN_AREA_CLASS)
    })
    if (this.chartLayoutModel.specificChartFunctionality === SpecificChartFunctionality.chartPage) {
      this.renderCross()
    }
  }

  onPaneMouseMove = (e: React.MouseEvent | React.Touch, isMouseDownIgnored = false) => {
    const { model, chartModel } = this.props
    const zoomControls = this.zoomControlsRef.current
    const paneHeight = model.height
    if (zoomControls) {
      const area = this.getArea<{ mouseDown: boolean; clientX: number; clientY: number }>(e, {
        mouseDown: !isMouseDownIgnored && this.mouseDown,
        clientX: e.clientX,
        clientY: e.clientY,
      })

      // timeout is needed here as adding a classname on parent element of a button where we are listening on a click is not working properly if done synchrnousely
      setTimeout(() => {
        // 103 is the destance between pane bottom and zoom-controls top
        // zoomer bottom (8 px) + zoomer height (20px) - 13px + zoom controls height (75px) = 90px
        const isInArea = model.height - 90 <= area.cursorY && paneHeight > 210

        if (
          isInArea &&
          !zoomControls.classList.contains(ZOOM_CONTROLS_IS_IN_AREA_CLASS) &&
          !chartModel.getIsDisabled()
        ) {
          zoomControls.classList.add(ZOOM_CONTROLS_IS_IN_AREA_CLASS)
        } else if (!isInArea && zoomControls.classList.contains(ZOOM_CONTROLS_IS_IN_AREA_CLASS)) {
          zoomControls.classList.remove(ZOOM_CONTROLS_IS_IN_AREA_CLASS)
        }

        if (!area.mouseDown && !model.selection && isInArea && !chartModel.getIsDisabled()) {
          if (!zoomControls.classList.contains(ZOOM_CONTROLS_ACTIVE_CLASS)) {
            zoomControls.classList.add(ZOOM_CONTROLS_ACTIVE_CLASS)
          }
        } else if (zoomControls.classList.contains(ZOOM_CONTROLS_ACTIVE_CLASS)) {
          zoomControls.classList.remove(ZOOM_CONTROLS_ACTIVE_CLASS)
        }
      })
    }
  }

  onMouseLeave = () => {
    this.mouseDown = false
  }

  onTouchStart = (e: React.TouchEvent) => {
    if (!this.props.touchEventsDisabled && e.targetTouches.length === 1) {
      const touchEvent = e.targetTouches[0]
      if (this.chartLayoutModel.scrollable) {
        this.onCanvasInteractionStart(touchEvent)
      } else if (this.chartLayoutModel.isTouchCrossActive) {
        this.lastClientX = touchEvent.clientX
        this.lastClientY = touchEvent.clientY
        if (!MouseModel.pane()?.eql(this.props.model)) {
          const area = this.getArea(touchEvent, {
            mouseDown: this.mouseDown,
            clientX: touchEvent.clientX,
            clientY: touchEvent.clientY,
          })
          this.setMousePosition(this.getMousePositionInBoundaries(area))
          this.isDisableTouchCrossAvailable = false
        }
      } else {
        const area = this.getArea(touchEvent, { isTouch: true }) // passing isTouch is pretty confusing, it's used in Tools.jsx -> onMouseDown method
        const element = this.getElement(area)
        if (element?.getIsChartEvent?.() && this.props.activeTool === DrawingTool.Mouse) {
          const chartEventElement = element as ChartEvent
          chartEventElement.toggleIsOpen()
        }
      }
      this.onPaneMouseMove(touchEvent, true)
    }
  }

  onTouchMove = (e: TouchEvent) => {
    if (!this.props.touchEventsDisabled) {
      if (!this.getIsHammerAllowScrollActive()) {
        e.preventDefault()
      }
      if (!this.props.touchEventsDisabled && e.targetTouches.length === 1) {
        this.onCanvasInteractionMove(e.targetTouches[0])
      }
    }
  }

  onTouchEnd = (e: React.TouchEvent) => {
    if (!this.props.touchEventsDisabled && this.chartLayoutModel.scrollable) {
      this.onCanvasInteractionEnd(e)
    }
  }

  onCanvasPress = (e: React.MouseEvent) => {
    if (this.chartLayoutModel.scrollable) {
      this.chartLayoutModel.updateAttribute('scrollable', false)
      document.removeEventListener('touchmove', this.onMouseMoveOnScrolling)
    }
    if (!this.chartLayoutModel.isTouchCrossActive) {
      this.chartLayoutModel.updateAttribute('isTouchCrossActive', true)
      this.onCanvasInteractionMove(e)
    }
  }

  onCanvasInteractionStart = (e: React.Touch | React.MouseEvent) => {
    const { activeTool, activeChartInteraction, chartModel, model } = this.props
    const isTouch = typeof Touch !== 'undefined' && e instanceof Touch
    this.lastActiveTool = activeTool

    if (isPrimaryClick(e) && !activeChartInteraction && !chartModel.getIsDisabled()) {
      const area = this.getArea(e, { isTouch }) // passing isTouch is pretty confusing, it's used in Tools.jsx -> onMouseDown method
      const element = this.getElement(area)

      // if user click outside of drawable area but still inside canvas creator would hang unfinised,
      // it that case we want to cancel element creation, same as we do on click outside of canvas / onBlur
      if (model.selection?.isCreator && !area.isCursorInChartWidthBoundaries && element !== model.selection) {
        model.getAllElements().forEach((element) => {
          if (element.instance.getIsCreator?.() && !element.instance.getIsCreatorDialogOpen?.()) {
            element.destroyCascade()
          }
        })
      }
      if (this.chartLayoutModel.scrollable) {
        setElementCursor({ elementId: this.chartLayoutModel.getHTMLElementId(), cursor: 'grabbing' })
        if (model.scaleRange) {
          this.currentViewRange = model.getChartOrIndicatorElement()?.instance?.getMinMax?.() ?? null
        }
        document.addEventListener(isTouch ? 'touchmove' : 'mousemove', this.onMouseMoveOnScrolling)
        document.addEventListener(isTouch ? 'touchend' : 'mouseup', this.onCanvasInteractionEnd)
      }
      if (element?.getIsChartEvent?.() && activeTool === DrawingTool.Mouse) {
        this.setSelectedElement(null)
        const chartEventElement = element as ChartEvent
        chartEventElement.toggleIsOpen()
      } else if (
        activeTool === DrawingTool.Mouse ||
        model.getAllElements().some(({ instance }) => instance.isCreator)
      ) {
        element?.trigger('mousedown', area)
        this.setSelectedElement(element)
      }
      model.trigger('mousedown', model, area, element)
      this.mouseDown = true
      this.lastX = area.x
      this.lastY = area.y
    }

    this.lastClientX = e.clientX
    this.lastClientY = e.clientY
  }

  onCanvasInteractionMove = (e: React.MouseEvent | React.Touch) => {
    const area = this.getArea(e, {
      mouseDown: this.mouseDown,
      clientX: e.clientX,
      clientY: e.clientY,
    })
    if (!area.isCursorInChartWidthBoundaries) {
      MouseModel.updateAttributes({
        position: null,
        pane: null,
      })
      return
    }

    const { model } = this.props
    const hoveredElement = this.getElement(area)
    const element = model.selection || hoveredElement

    if (!(this.isMobile && !this.chartLayoutModel.isTouchCrossActive)) {
      this.isDisableTouchCrossAvailable = true
      this.setMousePosition(
        this.lastClientX && this.lastClientY && this.chartLayoutModel.isTouchCrossActive && MouseModel.position
          ? this.getMousePositionInBoundaries({
              x: MouseModel.position.x + (model.scale.x.invert(area.clientX) - model.scale.x.invert(this.lastClientX)),
              y: MouseModel.position.y + (model.scale.y.invert(area.clientY) - model.scale.y.invert(this.lastClientY)),
            })
          : { x: area.x, y: area.y }
      )
    }

    if (!this.chartLayoutModel.scrollable) {
      this.lastClientX = area.clientX
      this.lastClientY = area.clientY
    }

    element?.trigger('mousemove', area)
    if (
      this.mouseDown &&
      model.selection instanceof CanvasElement &&
      !model.selection.activeThumb &&
      !this.chartLayoutModel.activeChartEvent
    ) {
      if (!model.selection.getIsCreator?.()) {
        model.selection.moveBy(area.x - this.lastX, area.y - this.lastY)
      }
      this.lastX = area.x
      this.lastY = area.y
      this.renderAll()
    }
    const isElementHovered =
      this.props.activeTool === DrawingTool.Mouse &&
      ((element && element === hoveredElement) || hoveredElement instanceof CanvasElement)

    if (hoveredElement !== this.hoveredElement && this.hoveredElement instanceof CanvasElement) {
      this.hoveredElement.setIsHovered(false)
      this.hoveredElement = null
    }

    if (isElementHovered && hoveredElement instanceof CanvasElement) {
      if (!hoveredElement.isHovered && !hoveredElement.isSelected) {
        hoveredElement.setIsHovered(true)
        this.hoveredElement = hoveredElement
      }
    }

    if (isElementHovered !== this.isElementHovered && this.canvas && hoveredElement?.type !== IndicatorType.Cot) {
      this.canvas.className = isElementHovered ? 'hover' : ''
      if (this.chartLayoutModel.cross && this.crossCanvas) {
        this.crossCanvas.className = `second${isElementHovered ? ' hover' : ''}`
      }
      this.isElementHovered = isElementHovered
    }
  }

  onCanvasInteractionEnd = (event: TouchEvent | MouseEvent | React.TouchEvent) => {
    if (MouseModel.position) {
      setElementCursor({ elementId: this.chartLayoutModel.getHTMLElementId(), cursor: 'crosshair' })
    } else {
      setElementCursor({ elementId: this.chartLayoutModel.getHTMLElementId(), cursor: 'default' })
    }
    const isTouch = typeof TouchEvent !== 'undefined' && event instanceof TouchEvent
    this.mouseDown = false
    const area = this.getArea(event)
    const element = this.props.model.selection || this.getElement(area)
    element?.trigger('mouseup', area)
    document.removeEventListener(isTouch ? 'touchmove' : 'mousemove', this.onMouseMoveOnScrolling)
    document.removeEventListener(isTouch ? 'touchend' : 'mouseup', this.onCanvasInteractionEnd)
  }

  getMousePositionInBoundaries = ({ x, y }: { x: number; y: number }) => {
    const { model } = this.props
    const Settings = getSettings(model)
    const canvasAreaRectangle = this.canvas!.getBoundingClientRect()
    const chartLeft = model.scale.x.invert(-model.chart().leftOffset + 1)
    const chartRight = model.scale.x.invert(
      canvasAreaRectangle.width - Settings.right.width - Settings.left.width - model.chart().leftOffset - 1
    )
    const chartTop = model.scale.y.invert(0)
    const chartBottom = model.scale.y.invert(canvasAreaRectangle.height - Settings.bottom.height - Settings.top.height)

    return {
      x: getValueInRange({
        value: x,
        min: chartLeft,
        max: chartRight,
      }),
      y: getValueInRange({
        value: y,
        min: chartBottom,
        max: chartTop,
      }),
    }
  }

  setMousePosition = ({ x, y }: { x: number; y: number }) => {
    const isSameMouseModel = !!MouseModel.pane()?.eql(this.props.model)
    MouseModel.updateAttributes({
      position: { x, y },
      ...(isSameMouseModel ? {} : { pane: this.props.model }),
    })
  }

  setSelection = (selection: IndicatorElement | CanvasElement | null) => {
    if (selection instanceof CanvasElement) {
      selection?.setIsSelected(true)
    }

    this.props.model.updateAttribute('selection', selection)
  }

  setSelectedElement = (element: IndicatorElement | CanvasElement | null) => {
    const { selection } = this.props.model
    const isSelectionCanvas = selection instanceof CanvasElement
    if (element !== selection || (isSelectionCanvas && element && !selection?.isSelected)) {
      if (isSelectionCanvas) {
        selection?.setIsSelected(false)
      }
      if (isSelectionCanvas && selection?.isCreator) {
        this.setSelection(null)
      } else {
        this.setSelection(element)
      }
    }
  }

  getElement = (area: PaneArea) => {
    const chartEvents = this.props.model.getAllChartEvents(false)

    for (let i = chartEvents.length - 1; i >= 0; i--) {
      const el = chartEvents[i].instance
      if (el.getIsInChartView(this.props.chartModel) && el.isInArea(area)) {
        return el
      }
    }

    const elements = this.props.model.getAllElements()

    for (let i = elements.length - 1; i >= 0; i--) {
      const el = elements[i].instance
      if (el.getIsInChartView(this.props.chartModel) && el.isInArea(area)) {
        return el
      }
    }
    return null
  }

  getCanvasDOMNode = () =>
    // This is used in app/modals/publish/publish.jsx
    this.canvasRef.current
}

export default withNotificationContext(withElementStyleDialogState(withPaneModel(Pane)))
