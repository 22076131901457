import { Badge, Tab, TabList, TabPanel, Tabs } from '@finviz/website'
import React from 'react'
import { useSearchParams } from 'react-router-dom'

import { getInstrument } from '../../utils/chart'
import fetchApi from '../../utils/fetch_api'
import IdeaItem from './IdeaItem'
import { IIdea, IIdeaCategory, categorizeIdeas } from './utils'

export default function IdeasTabs() {
  const [hasError, setHasError] = React.useState(false)
  const [hasFetched, setHasFetched] = React.useState(false)
  const [categorizedIdeas, setCategorizedIdeas] = React.useState<IIdeaCategory[]>([])
  const [params] = useSearchParams()
  const queryTickers = params.get('t')!.split(',') // there always be some t param defined

  React.useEffect(() => {
    async function fetchIdeas() {
      window.gtag?.('event', 'load', { event_category: 'ideas' })
      try {
        const data = await fetchApi({ location: '/api/ideas_load.ashx', throwOnStatusCodes: [404] })
        const ideas = data.ideas.map((idea: IIdea) => {
          const dateParts = idea.date.split(' ')
          return {
            ...idea,
            instrument: getInstrument(idea.ticker),
            date: dateParts[0].replace('-', ' ').replace('-', ', '),
            time: dateParts[1],
          }
        })

        setCategorizedIdeas(categorizeIdeas(ideas, queryTickers))
        setHasFetched(true)
      } catch {
        setHasError(true)
      }
    }

    void fetchIdeas()

    // run only on mount
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleIdeaDeleted = (idea: IIdea) => {
    setCategorizedIdeas((prev) => {
      const modifiedCategories = [...prev]

      // remove from specific ticker category
      const categoryIndex = modifiedCategories.findIndex((c) => c.ticker === idea.ticker)
      if (categoryIndex >= 0) {
        const updatedCategory = {
          ...modifiedCategories[categoryIndex],
          ideas: modifiedCategories[categoryIndex].ideas.filter((i) => i.id !== idea.id),
        }
        modifiedCategories[categoryIndex] = updatedCategory
      }

      // remove from all
      const updatedAll = {
        ...modifiedCategories[0],
        ideas: modifiedCategories[0].ideas.filter((i) => i.id !== idea.id),
      }
      modifiedCategories[0] = updatedAll

      // remove empty categories
      return modifiedCategories.filter((c) => c.ideas.length !== 0)
    })
  }

  if (!hasFetched || hasError) {
    return (
      <div className="flex h-40 items-center justify-center text-sm font-bold">
        {hasError ? 'Unexpected error occurred, please refresh the page.' : 'Loading…'}
      </div>
    )
  }

  if (categorizedIdeas.length === 0) {
    return (
      <div
        data-testid="charts-notice-ideas-no-ideas"
        className="flex h-40 items-center justify-center text-sm font-bold"
      >
        No saved ideas.
      </div>
    )
  }

  return (
    <Tabs>
      <TabList aria-label="idea-tabs" className="overflow-x-auto">
        {categorizedIdeas.map((category) => (
          <Tab key={category.ticker} size="large" rightContent={<Badge>{category.ideas.length}</Badge>}>
            {category.ticker}
          </Tab>
        ))}
      </TabList>
      {categorizedIdeas.map((category) => (
        <TabPanel
          key={category.ticker}
          data-testid={`ideas-tab-panel-${category.ticker.toLowerCase().replace(' ', '_')}`}
        >
          <div className="space-y-0.5">
            {category.ideas.map((idea) => (
              <IdeaItem key={idea.id} idea={idea} onIdeaDeleted={handleIdeaDeleted} />
            ))}
          </div>
        </TabPanel>
      ))}
    </Tabs>
  )
}
