import { intervalCallbackOnWindowVisible } from '@finviz/website'
import React from 'react'

import { CustomSpineEvents } from '../../../types/shared'
import { useModelState } from '../../model-hooks/use-model-state'
import ChartModel from '../../models/chart'
import ChartLayout from '../../models/chart_layout'
import { AutoSaveState } from '../../models/constants'
import Element from '../../models/element'
import { usePrevious } from '../../utils/use-prev-state'
import { DRAWINGS_UPDATE_INTERVAL_MS } from './constants'
import { useDrawingAutoSaveControls } from './use-drawing-auto-save-controls'
import { getTickersAndContainerTypesInLayoutModel } from './utils'

function useDrawingAutoSave(chartLayout: ChartLayout) {
  const {
    onElementChange,
    deleteAllAutoSavedElements,
    checkDrawingUpdate,
    syncChartLayoutDrawings,
    saveLatestChanges,
    isAutoSaveActive,
  } = useDrawingAutoSaveControls(chartLayout)

  const drawingsInternalStoreUnwatched = React.useMemo(() => chartLayout.drawingsInternalStore(), [chartLayout])
  const drawingsInternalStore = useModelState(drawingsInternalStoreUnwatched, { watchProperties: ['elements'] })
  const prevIsAutoSaveActive = usePrevious(isAutoSaveActive)

  const runInitialSaveAndRefetchUpdate = React.useCallback(async () => {
    chartLayout.getAllElements().forEach((element) => {
      // Adds drawings to internal store if isAutoSaveActive and they are not in the store yet
      if (!drawingsInternalStore.elements.some((drawing) => drawing.elementId === element.elementId)) {
        onElementChange(element)
      }
    })

    // We want to save everything except notes because saving notes can overwrite those already saved in db. Each drawing has different id but there is only one note per ticker
    // Notes are merged in checkDrawingUpdate fn
    await saveLatestChanges(0, (item) => item.containerType !== 'note')

    void checkDrawingUpdate(true)
  }, [checkDrawingUpdate, chartLayout, onElementChange, drawingsInternalStore.elements, saveLatestChanges])

  React.useEffect(() => {
    if (isAutoSaveActive) {
      void saveLatestChanges()
    }
  }, [saveLatestChanges, drawingsInternalStore.elements, isAutoSaveActive])

  React.useEffect(() => {
    if (!isAutoSaveActive) {
      chartLayout.drawingsInternalStore().setAutoSaveState(AutoSaveState.Off)
      return
    }
    chartLayout.drawingsInternalStore().setAutoSaveState(AutoSaveState.Saved)
    if (!prevIsAutoSaveActive && isAutoSaveActive) {
      void runInitialSaveAndRefetchUpdate()
    }

    let { tickers, containerTypes } = getTickersAndContainerTypesInLayoutModel(chartLayout)

    const onChartOrIndicatorChange = () => {
      const { tickers: newTickers, containerTypes: newContainerTypes } =
        getTickersAndContainerTypesInLayoutModel(chartLayout)
      if (
        JSON.stringify(tickers) !== JSON.stringify(newTickers) ||
        JSON.stringify(containerTypes) !== JSON.stringify(newContainerTypes)
      ) {
        tickers = newTickers
        containerTypes = newContainerTypes
        void checkDrawingUpdate(true)
      }
    }

    ChartModel.bind(`${CustomSpineEvents.IndicatorsChange} change`, onChartOrIndicatorChange)
    Element.bind('change', onElementChange)

    drawingsInternalStore.elements.forEach(syncChartLayoutDrawings)
    const checkDrawingUpdateSubscription = intervalCallbackOnWindowVisible(
      { interval: DRAWINGS_UPDATE_INTERVAL_MS },
      checkDrawingUpdate
    )

    const unregisterListeners = () => {
      checkDrawingUpdateSubscription()
      ChartModel.unbind(`${CustomSpineEvents.IndicatorsChange} change`, onChartOrIndicatorChange)
      Element.unbind('change', onElementChange)
    }

    ChartLayout.bind('change', (model: ChartLayout) => {
      if (model.willDestroy) {
        unregisterListeners()
      }
    })

    return unregisterListeners
  }, [
    chartLayout,
    drawingsInternalStore,
    checkDrawingUpdate,
    onElementChange,
    syncChartLayoutDrawings,
    isAutoSaveActive,
    prevIsAutoSaveActive,
    runInitialSaveAndRefetchUpdate,
  ])

  return { deleteAllAutoSavedElements, isAutoSaveActive }
}

interface Props {
  chartLayoutModel: ChartLayout
}

export const withDrawingsAutoSave = (Component: any) => (props: Props) => {
  const { deleteAllAutoSavedElements, isAutoSaveActive } = useDrawingAutoSave(props.chartLayoutModel)

  return (
    <Component {...props} deleteAllAutoSavedElements={deleteAllAutoSavedElements} isAutoSaveActive={isAutoSaveActive} />
  )
}
