import * as d3 from 'd3'
import merge from 'lodash.merge'

import {
  ChartConfigChartPaneElement,
  CustomSpineEvents,
  Instrument,
  Maybe,
  RequireByKey,
  TextAttrs,
  Theme,
} from '../../types/shared'
import Cache from '../canvas/cache'
import Element, { NumOfBarsChartPaneElementArgs } from '../canvas/element'
import Line from '../canvas/line'
import Text from '../canvas/text'
import tailwindColors from '../constants/colors'
import {
  CHART_MARGIN,
  FONT_SIZE,
  IndicatorType,
  LINE_HEIGHT,
  MONTHS,
  OFFSET,
  PADDING,
  ScaleType,
  SpecificChartFunctionality,
  TIMEFRAME,
  TIMEFRAMES_WITH_PRE_MARKET,
  TextAlign,
  TextBaseline,
  getTimeframeLabel,
} from '../constants/common'
import {
  getIsSmallIndexChart,
  getPercentageFromValue,
  getTranslate,
  getValueFromPercentage,
} from '../controllers/renderUtils'
import Chart from '../models/chart'
import { getColorOverwrites } from '../models/chart_settings'
import { IChartSettings, ISettings, OHLCType } from '../models/chart_settings/interfaces'
import { darkerWickColors, thinBarColorsOverride } from '../models/constants'
import mouseModel from '../models/mouse'
import PaneModel, { ScaleAxis } from '../models/pane'
import Quote from '../models/quote'
import { getChartLayoutSettings } from '../models/settings'
import Overlay from '../overlays/overlay'
import { overlaysByType } from '../overlays/overlays'
import utils, { isRedesignedPage, numberToStringWithUnitsSuffix } from '../utils'
import {
  ITickerChange,
  getBarWithoutMarginWidth,
  getChangeColor,
  getHalfBarWidth,
  getShouldUseDarkerWickColors,
  getTickerChange,
  getTickerChangeFromCloseValues,
  getVirtualTimeframeTradedDates,
  renderCross,
  round,
  unmountCanvas,
} from '../utils/chart'
import { renderFadeExtendedHours, renderXAxis } from '../utils/chart-grid-render-utils'
import { convertColorToHEX, getHEXWithSpecificAplha, getHSVAFromColor, stringifyHSVAColor } from '../utils/colors'
import { setElementCursor } from '../utils/cursor'
import { drawInVisibleArea, getAreNoBarsVisible, getVisibleBarToRenderIndex } from '../utils/draw_in_visible_area'
import { getChartBreakpoints } from './utils'

export interface BaseChartAttrs {
  overlays: Required<ChartConfigChartPaneElement>['overlays']
}

type OhlcLineElements = Array<{ text: string; color: string } | 'space'>

interface LastOHLCAttrs {
  text: string
  x: number
  y: number
  width: number
  textBaseline: TextBaseline
  textAlign: TextAlign
}

interface IRenderOHLCParameters {
  context: CanvasRenderingContext2D
  date: Maybe<string>
  open?: Maybe<string>
  high?: Maybe<string>
  low?: Maybe<string>
  close?: Maybe<string>
  volume?: Maybe<string>
  afterHour?: string
  isSmallIndexChart?: boolean
  isNewestOHLC?: boolean
  directRender?: boolean
  time?: string
  changePercentage?: Maybe<ITickerChange>
}

const getDateString = ({ date, timeframe }: { date: Date; timeframe: TIMEFRAME }) => {
  let dateString = ''
  const month = date.getMonth() // 20141126
  const year = date.getFullYear()
  const day = date.getDate()
  switch (timeframe) {
    case TIMEFRAME.i1:
    case TIMEFRAME.i2:
    case TIMEFRAME.i3:
    case TIMEFRAME.i5:
    case TIMEFRAME.i10:
    case TIMEFRAME.i15:
    case TIMEFRAME.i30:
    case TIMEFRAME.h:
    case TIMEFRAME.h2:
    case TIMEFRAME.h4:
      const t =
        (((date.getHours() + 11) % 12) + 1).toString().padLeft('00') +
        ':' +
        date.getMinutes().toString().padLeft('00') +
        (date.getHours() < 12 ? 'AM' : 'PM')
      dateString = `${MONTHS[month]} ${day} ${t}`
      break
    case TIMEFRAME.d:
    case TIMEFRAME.w:
      dateString = `${MONTHS[month]} ${day}`
      break
    case TIMEFRAME.m:
      dateString = `${MONTHS[month]} ${year}`
      break
    default:
      break
  }

  return dateString
}

function getClosestDifferentMinMaxValueIndices(
  currentMinIndex: number,
  currentMaxIndex: number,
  high: number[],
  low: number[]
): { minIndex: number; maxIndex: number } {
  const hasPreviousIndex = currentMinIndex > 0
  const hasNextIndex = currentMaxIndex < high.length - 1
  let minIndex = currentMinIndex
  let maxIndex = currentMaxIndex
  if (hasPreviousIndex) {
    minIndex -= 1
  }
  if (hasNextIndex) {
    maxIndex += 1
  }

  if (low[minIndex] === high[maxIndex] && (hasPreviousIndex || hasNextIndex)) {
    return getClosestDifferentMinMaxValueIndices(minIndex, maxIndex, high, low)
  }

  return low[minIndex] < high[maxIndex] ? { minIndex, maxIndex } : { minIndex: maxIndex, maxIndex: minIndex }
}

const gray300 = tailwindColors.gray[300]

class BaseChart<T extends BaseChartAttrs = BaseChartAttrs> extends Element<T> {
  static initClass() {
    Object.defineProperty(this.prototype, 'width', {
      get() {
        const { ChartSettings } = this.getChartLayoutSettings() as ISettings
        if (!this.model.width) return 0
        return this.model.width - ChartSettings.left.width - ChartSettings.right.width
      },
    })

    Object.defineProperty(this.prototype, 'height', {
      get() {
        const { ChartSettings } = this.getChartLayoutSettings() as ISettings
        if (!this.paneModel.height) return 0
        return this.paneModel.height - ChartSettings.top.height - ChartSettings.bottom.height
      },
    })
  }

  static getNumOfBarsBuffer({ overlays, timeframe }: NumOfBarsChartPaneElementArgs) {
    return Math.max(
      0,
      ...overlays.map((overlay) => overlaysByType[overlay.type].getNumOfBarsBuffer({ ...overlay, timeframe }))
    )
  }

  static label = 'Chart'
  static iconName = ''

  paneModel: PaneModel
  model: Chart
  data: Quote
  specificChartFunctionality: SpecificChartFunctionality
  tickerWidth = 0
  changeWidth = 0
  lastOhlc: Array<Partial<LastOHLCAttrs>> = []
  overlays: Overlay[] = []
  isLargeChart?: boolean
  shouldRenderVolume = true
  afterChange: {
    x: number
    y: number
    width: number
  } | null = null

  declare setupAxisTimeFrame: string
  declare volumeFY: d3.ScaleLinear<number, number>
  declare tickerText: Text
  declare baseCache: Cache
  declare leftOffset: number
  declare context: CanvasRenderingContext2D
  declare width: number
  declare height: number
  declare isMobile: boolean

  constructor(values: Partial<T>, paneModel: PaneModel) {
    super(values, paneModel)

    this.paneModel = paneModel
    this.model = this.paneModel.chart()
    this.data = this.model.quote()
    const chartLayoutModel = this.model.chart_layout()
    this.specificChartFunctionality = chartLayoutModel.specificChartFunctionality
    this.isLargeChart = chartLayoutModel.isLargeChart
    this.isMobile = utils.isMobile(true)

    this.overlays = []
    if (values.overlays) {
      for (const overlayDefinition of values.overlays) {
        // overlayDefinition.color coming from DB is always string however overlays
        // internaly didn't use format as in db (type, color, period) but rather
        // more overlay specific parameters eg. (BB: topColor, bottomColor, etc.)
        // thus overlayDefinition.color might be undefined
        // approach could be reworked in https://github.com/finvizhq/charts/issues/770
        // but for now (!overlayDefinition.color) check solve issue as well
        const overlay = overlaysByType[overlayDefinition.type].fromObject<Overlay>(
          {
            ...overlayDefinition,
            color:
              !overlayDefinition.color || overlayDefinition.color.includes('|')
                ? ''
                : convertColorToHEX(overlayDefinition.color),
          },
          this.paneModel
        )
        this.addOverlay(overlay)
      }
      delete values.overlays
    }

    this.renderYAxis = this.renderYAxis.bind(this)
    this.renderText = this.renderText.bind(this)
    this.renderCrossText = this.renderCrossText.bind(this)
    this.setupCache = this.setupCache.bind(this)
    this.renderOverlaysLabels = this.renderOverlaysLabels.bind(this)

    // this.paneModel.bind('update', this.setupAxis.bind(this))
    this.paneModel.bind('update', this.setupCache)
    this.paneModel.bind('change', this.trigger.bind(this, 'change'))
    this.model = this.paneModel.chart()
    // this.model.bind('change', this.setupAxis.bind(this))
    this.model.bind('change', this.setupCache)
    this.model.bind('change', this.trigger.bind(this, 'change'))
    this.model.bind(CustomSpineEvents.IndicatorsChange, this.handleIndicatorsChange.bind(this))
    const mouseModelChangeHandler = this.trigger.bind(this, 'change', 'cross')
    this.model.bind('destroy', () => {
      this.model.unbind('change', this.setupCache)
      this.model.unbind(CustomSpineEvents.IndicatorsChange, this.handleIndicatorsChange.bind(this))
      mouseModel.unbind('change', mouseModelChangeHandler)
      unmountCanvas(this.baseCache.canvas)
    })
    mouseModel.bind('change', mouseModelChangeHandler)

    this.model.chart_layout().bind('theme', this.setupCache)
    this.model.chart_layout().bind('update', this.updateShouldRenderVolume.bind(this))

    this.setupCache()
  }

  renderChart() {
    throw Error('Implement renderChart')
  }

  render(context: CanvasRenderingContext2D) {
    this.data = this.model.quote()
    this.leftOffset = this.model.leftOffset
    this.context = context

    if (this.data.close.length === 0) {
      this.renderChartNotAvailable()
      if (!this.getIsRedesignedChart()) this.renderTicker(context)
      return
    }

    this.context.save()
    this.clip()
    this.renderVolume()
    this.renderChart()
    this.renderOverlays()
    this.renderPrevClose()
    renderFadeExtendedHours({ context: this.context, paneModel: this.paneModel, quote: this.data })
    this.context.restore()
    if (!this.getIsRedesignedChart()) this.renderTicker(context)
  }

  renderGrid(context: CanvasRenderingContext2D) {
    this.data = this.model.quote()
    this.leftOffset = this.model.leftOffset
    this.context = context
    // text, xAxis, volumeAxis
    this.renderXAxis()
    this.baseCache.render(context)
  }

  renderTicker(context: CanvasRenderingContext2D) {
    if (this.tickerText) {
      const { ChartSettings } = this.getChartLayoutSettings()
      context.translate(ChartSettings.left.width, 0)
      this.tickerText.render(context)

      if (getIsSmallIndexChart(this.specificChartFunctionality) && this.data.ticker === 'RUSSELL 2000') {
        const { Colors } = ChartSettings.general
        new Text(
          {
            text: 'ETF',
            x: ChartSettings.top.ticker.margin.left,
            y: ChartSettings.top.ticker.margin.top! + FONT_SIZE.XXS + OFFSET.XXS,
            font: { size: FONT_SIZE.XXS },
            fillStyle: Colors.text,
            textBaseline: ChartSettings.top.baseline,
          },
          this.paneModel
        ).render(context)
      }

      context.translate(-ChartSettings.left.width, 0)
    }
  }

  renderTimeframe(context: CanvasRenderingContext2D) {
    const { ChartSettings } = this.getChartLayoutSettings()
    const { Colors } = ChartSettings.general
    const quote = this.model.quote()
    const y = (this.height - this.volumeFY.range()[1] - 4) / 2

    return new Text(
      {
        text: quote.instrument === Instrument.Group ? 'YEAR PERFORMANCE %' : getTimeframeLabel(quote.timeframe),
        x: ChartSettings.left.timeframe.margin.left,
        y: ChartSettings.top.height + y + (ChartSettings.left.timeframe.margin.top ?? 0),
        angle: -90,
        font: Text.getMergedPropsWithDefaults('font', ChartSettings.left.timeframe.font),
        fillStyle: Colors.textSecondary,
        textAlign: TextAlign.center,
        textBaseline: TextBaseline.alphabetic,
      },
      this.paneModel
    ).render(context)
  }

  initTickerText(chartSettings: IChartSettings) {
    const { Colors } = chartSettings.general
    const text = this.data.name || this.data.ticker
    const isSmallIndexChart = getIsSmallIndexChart(this.specificChartFunctionality)
    const font: TextAttrs['font'] = isSmallIndexChart
      ? { size: FONT_SIZE.L, weight: 'bold' }
      : Text.getMergedPropsWithDefaults('font', chartSettings.top.ticker.font)
    this.tickerText = new Text(
      {
        text,
        x: chartSettings.top.ticker.margin.left,
        y: chartSettings.top.ticker.margin.top,
        fillStyle: Colors.text,
        font,
        ...(!isSmallIndexChart ? { textBaseline: chartSettings.top.baseline } : {}),
      },
      this.paneModel
    )
  }

  renderText(context: CanvasRenderingContext2D) {
    const { ChartSettings, isOurSiteRequest } = this.getChartLayoutSettings()
    context.save()
    context.translate(ChartSettings.left.width, 0)

    this.initTickerText(ChartSettings)
    this.tickerWidth = this.tickerText.measure(context)

    // render watermark on backend chart
    if (this.specificChartFunctionality === SpecificChartFunctionality.offScreen) {
      let yOffset = 0
      if (!isOurSiteRequest) {
        yOffset = this.isLargeChart ? -2 : -3
      }
      new Text(
        {
          text: '© finviz.com',
          x: this.width + ChartSettings.right.width - 2,
          y: 1 + yOffset,
          font: { size: isOurSiteRequest ? FONT_SIZE.XS : FONT_SIZE.M },
          textAlign: TextAlign.right,
          textBaseline: TextBaseline.top,
          fillStyle: isOurSiteRequest ? 'rgb(136, 193, 233)' : tailwindColors.finviz.blue,
        },
        this.paneModel
      ).render(context)
    }

    if (!this.getIsRedesignedChart()) {
      this.renderChange({ context })
      if (!this.lastOhlc[0]) {
        this.getOHLC({ context, directRender: false })
      }
      this.getOHLC({ context })
    }

    context.restore()
    if (!this.getIsRedesignedChart() && !getIsSmallIndexChart(this.specificChartFunctionality)) {
      this.renderTimeframe(context)
    }
  }

  getOHLC({
    context,
    directRender = true,
    data,
    date,
    dateString = null,
    changePercentage = null,
  }: {
    context: CanvasRenderingContext2D
    directRender?: boolean
    data?: {
      open?: string
      high?: string
      low?: string
      close?: string
      volume?: string
    }
    date?: Date
    dateString?: string | null
    changePercentage?: ITickerChange | null
  }) {
    if (!this.data.lastDate) return

    // modulo "parsing" is 100% faster than with str.slice()
    const lastDate = `${MONTHS[(~~(this.data.lastDate / 100) % 100) - 1]} ${this.data.lastDate % 100}` // YYYYMMDD / 20211118 => Nov 18
    if (getIsSmallIndexChart(this.specificChartFunctionality)) {
      this.renderOHLC({
        context,
        date: lastDate,
        isSmallIndexChart: true,
        isNewestOHLC: true,
        directRender,
      })
    } else if (data && date) {
      this.renderOHLC({
        context,
        date: getDateString({ date, timeframe: this.data.timeframe }),
        open: data.open,
        high: data.high,
        low: data.low,
        close: data.close,
        volume: numberToStringWithUnitsSuffix(Number(data.volume)),
        directRender,
        changePercentage,
      })
    } else if (dateString !== null) {
      this.renderOHLC({
        context,
        date: dateString,
        open: null,
        high: null,
        low: null,
        close: null,
        volume: null,
        directRender,
      })
    } else {
      this.renderOHLC({
        context,
        date: lastDate,
        afterHour:
          Number.isFinite(this.data.afterClose) && this.data.afterClose !== this.data.lastClose
            ? this.roundOhlc(this.data.afterClose)
            : undefined,
        volume: numberToStringWithUnitsSuffix(this.data.lastVolume),
        isNewestOHLC: true,
        time: utils.dateFromUnixTimestamp(this.data.date.last()!).toLocaleTimeString('en-US', {
          hour: '2-digit',
          minute: '2-digit',
        }),
        directRender,
        ...this.getRoundedLastData(),
      })
    }
  }

  getRoundedLastData() {
    return {
      open: this.roundOhlc(this.data.lastOpen),
      high: this.roundOhlc(this.data.lastHigh),
      low: this.roundOhlc(this.data.lastLow),
      close: this.roundOhlc(this.data.lastClose),
    }
  }

  renderOverlaysLabels(context: CanvasRenderingContext2D) {
    if (!this.getIsRedesignedChart()) {
      this.renderOverlaysLabelsQuotePage(context)
    } else if (this.data.close.length !== 0) {
      this.renderOverlaysLabelsChartsPage(context)
    }
  }

  renderOverlaysLabelsQuotePage(context: CanvasRenderingContext2D) {
    const { ChartSettings } = this.getChartLayoutSettings()
    const text = new Text(
      {
        x: ChartSettings.left.overlayLabel.margin.left,
        y: ChartSettings.top.height + ChartSettings.left.overlayLabel.margin.top!,
        font: Text.getMergedPropsWithDefaults('font', ChartSettings.left.overlayLabel.font),
        textBaseline: TextBaseline.top,
      },
      this.paneModel
    )
    for (const overlay of this.overlays) {
      if (!overlay.isRenderedOverlaysLabel()) {
        continue
      }
      text
        .set({
          text: overlay.toString(),
          y: text.get('y')! + ChartSettings.left.overlayLabel.labelSpacing,
          fillStyle: overlay.getLabelColor(),
        })
        .render(context)
    }
  }

  getYAxisLeftMargin() {
    if (this.data.instrument === Instrument.Crypto && this.data.lastClose <= 0.001) {
      return 3
    }
    return 8
  }

  getYAxisLastCloseLabel({ lastClose, firstVisibleClose }: { lastClose: number; firstVisibleClose?: number | null }) {
    if (typeof firstVisibleClose === 'number' && this.model.scale === ScaleType.Percentage) {
      return `${this.round(getPercentageFromValue({ number: lastClose, base: firstVisibleClose }), 2)}%`
    }
    return this.round(lastClose)
  }

  renderYAxis(
    context: CanvasRenderingContext2D,
    { lastClose }: RequireByKey<Partial<Quote>, 'lastClose'> = this.data,
    renderLabels = true
  ) {
    const { ChartSettings } = this.getChartLayoutSettings()
    const { Colors } = ChartSettings.general
    let y, lineTicks, textTicks, lastCloseLabel
    const translate = getTranslate({ context, xOffset: ChartSettings.left.width, yOffset: ChartSettings.top.height })
    translate.do()
    const tickCount = Math.floor(this.paneModel.height / 35)
    const minMax = this.getMinMax()
    const { firstVisibleClose } = minMax
    const height = this.height
    let stepPrice = 0
    let placesAxis

    switch (this.model.scale) {
      case ScaleType.Percentage: {
        if (!firstVisibleClose) return

        const scaleRange = this.paneModel.scaleRange
        const { min, max } = scaleRange || minMax

        this.model.updateAttribute('firstBarClose', firstVisibleClose)

        const minPerc = getPercentageFromValue({ number: min, base: firstVisibleClose })
        const maxPerc = getPercentageFromValue({ number: max, base: firstVisibleClose })

        const percScale = d3.scaleLinear().range([0, this.height]).domain([maxPerc, minPerc]).nice(10)
        textTicks = percScale.ticks(Math.min(tickCount, 9))
        lineTicks = textTicks.map((tick) => getValueFromPercentage({ number: tick, base: firstVisibleClose }))
        lastCloseLabel = this.getYAxisLastCloseLabel({ lastClose, firstVisibleClose })
        break
      }
      case ScaleType.Logarithmic: {
        if (!firstVisibleClose) return

        const scaleRange = this.paneModel.scaleRange
        const { min, max } = scaleRange || minMax
        lineTicks = [Math.floor(min)]
        const pixelGap = 30

        const negativeScale = []
        if (min < 0) {
          let previousNegativeTick = Math.min(0, max)
          while (this.paneModel.scale.y(previousNegativeTick) + pixelGap < this.paneModel.scale.y(min)) {
            const tick = this.paneModel.scale.y.invert(this.paneModel.scale.y(previousNegativeTick) + pixelGap)
            if (previousNegativeTick === tick) {
              break
            }
            const range = d3.nice(-previousNegativeTick, -tick, 2)
            previousNegativeTick = -range[range.length - 1]
            negativeScale.unshift(previousNegativeTick)
          }
        }

        const positiveScale = []
        if (max > 0) {
          positiveScale.push(Math.max(this.paneModel.scale.y.invert(this.paneModel.scale.y(min) + pixelGap), 0))
        }
        while (this.paneModel.scale.y(positiveScale[positiveScale.length - 1]) - pixelGap > 0) {
          const previousTick = positiveScale[positiveScale.length - 1]
          const positiveScaleY = this.paneModel.scale.y(positiveScale[positiveScale.length - 1])
          const tick = this.paneModel.scale.y.invert(positiveScaleY - pixelGap)
          if (previousTick === tick) {
            break
          }

          const range = d3.nice(previousTick, tick, 2)
          positiveScale.push(range[range.length - 1])
        }
        lineTicks = [...negativeScale, ...positiveScale]
        textTicks = lineTicks
        lastCloseLabel = this.getYAxisLastCloseLabel({ lastClose })
        break
      }
      default:
        // default to linear scale type
        lineTicks = this.paneModel.scale.y.ticks(Math.min(tickCount, 9))
        textTicks = lineTicks
        lastCloseLabel = this.getYAxisLastCloseLabel({ lastClose })
    }

    if (textTicks.length >= 2) {
      stepPrice = Math.abs(textTicks[1] - textTicks[0])
    }
    if (
      stepPrice > 10 ||
      (getIsSmallIndexChart(this.specificChartFunctionality) && Math.round(textTicks[0]).toString().length > 3) // if small index chart max tick is less than 999 (3 digits) allow for decimals in axis labels
    ) {
      placesAxis = 0
    }

    const getYLineText = (val: number, places?: number) => {
      switch (this.model.scale) {
        case ScaleType.Percentage:
          return `${val}%`
        default:
          return this.round(val, places)
      }
    }

    const yTickText = new Text(
      {
        x: this.width + this.getYAxisLeftMargin() + ChartSettings.right.axis.margin.left!,
        font: Text.getMergedPropsWithDefaults('font', ChartSettings.right.axis.font),
        fillStyle: Colors.text,
        textBaseline: TextBaseline.middle,
      },
      this.paneModel
    )

    let yTickLine = this.getYLine()
    const resetYTicksStyles = () => {
      yTickText.set({
        font: Text.getMergedPropsWithDefaults('font', ChartSettings.right.axis.font),
        fillStyle: Colors.text,
        textBaseline: TextBaseline.middle,
      })
      yTickLine = this.getYLine()
    }
    for (let i = 0; i < lineTicks.length; i++) {
      y = Math.round(this.fy(lineTicks[i]))
      if (y < 0 || y > height) continue
      yTickLine.set({ y1: y, y2: y })
      yTickText.set({
        text: getYLineText(textTicks[i], placesAxis),
        y,
      })
      if (this.model.scale === ScaleType.Percentage && textTicks[i] === 0) {
        yTickLine.set({ strokeStyle: Colors.text })
        yTickText.set({
          font: { weight: 'bold', size: yTickText.attrs.font.size },
          fillStyle: Colors.percentageZeroLineText,
        })
        yTickLine.render(context)
        yTickText.render(context)
        resetYTicksStyles()
      } else {
        yTickLine.render(context)
        yTickText.render(context)
      }
    }
    translate.undo()

    if (renderLabels) {
      this.renderYAxisLabel({ context, yCoordinate: Math.round(this.fy(lastClose)), label: lastCloseLabel })
      // if (typeof this.data.afterClose === 'number') {
      //   this.renderYAxisLabel({
      //     context,
      //     yCoordinate: this.getYAxisSecondaryLabelYCoordinate({
      //       mainLabelValue: lastClose,
      //       secondaryLabelValue: this.data.afterClose,
      //     }),
      //     label: this.getYAxisLastCloseLabel({ lastClose: this.data.afterClose, firstVisibleClose }),
      //     background: this.getSecondaryLabelBackgroundColor(),
      //   })
      // }
    }
  }

  getSecondaryLabelBackgroundColor() {
    return stringifyHSVAColor(
      {
        ...getHSVAFromColor(this.getChartLayoutSettings().ChartSettings.general.Colors.currentBackground),
        s: 0.3,
      },
      'hex'
    )
  }

  getYAxisSecondaryLabelYCoordinate({
    mainLabelValue,
    secondaryLabelValue,
  }: {
    mainLabelValue: number
    secondaryLabelValue: number
  }) {
    const { ChartSettings } = this.getChartLayoutSettings()
    const { lineHeight } = ChartSettings.right.axis.font
    const padding = Text.getMergedPropsWithDefaults('padding', ChartSettings.right.axis.font.padding)

    const direction = Math.sign(mainLabelValue - secondaryLabelValue) || 1
    const offset = (lineHeight ?? LINE_HEIGHT.S) + (padding.top ?? PADDING.XXS) + (padding.bottom ?? PADDING.XXS)
    const mainLabelValuePosition = Math.round(this.fy(mainLabelValue))
    const secondaryLabelValuePosition = Math.round(this.fy(secondaryLabelValue))
    const offsetedY = mainLabelValuePosition + offset * direction

    return Math.abs(secondaryLabelValuePosition - mainLabelValuePosition) > offset
      ? secondaryLabelValuePosition
      : offsetedY
  }

  renderYAxisLabel({
    context,
    yCoordinate,
    label,
    background,
  }: {
    context: CanvasRenderingContext2D
    yCoordinate: number
    label: string
    background?: string
  }) {
    const { ChartSettings } = this.getChartLayoutSettings()
    const translate = getTranslate({ context, xOffset: ChartSettings.left.width, yOffset: ChartSettings.top.height })
    const { Colors } = ChartSettings.general
    const { size, lineHeight } = ChartSettings.right.axis.font
    const padding = Text.getMergedPropsWithDefaults('padding', ChartSettings.right.axis.font.padding)

    translate.do()
    new Text(
      {
        text: label,
        x: this.width + this.getYAxisLeftMargin() - padding.left + ChartSettings.right.axis.margin.left!,
        y: yCoordinate,
        font: Text.getMergedPropsWithDefaults('font', { size, weight: 'bold' }),
        lineHeight,
        padding,
        textBaseline: TextBaseline.middle,
        fillStyle:
          this.specificChartFunctionality === SpecificChartFunctionality.offScreen ? 'black' : Colors.currentText,
        background: background || Colors.currentBackground,
      },
      this.paneModel
    ).render(context)
    translate.undo()
  }

  renderXAxis() {
    const { ChartSettings } = this.getChartLayoutSettings()
    const { Colors } = ChartSettings.general

    this.context.save()
    if (!getIsSmallIndexChart(this.specificChartFunctionality) && this.data.instrument !== Instrument.MarketSentiment) {
      this.clip(true)
    }
    this.context.translate(ChartSettings.left.width, ChartSettings.top.height)
    const textRenderer = new Text(
      {
        y: this.height + ChartSettings.bottom.axis.margin.top!,
        font: Text.getMergedPropsWithDefaults('font', { size: ChartSettings.bottom.axis.font.size }),
        fillStyle: Colors.text,
        textBaseline: TextBaseline.top,
        textAlign: TextAlign.center,
      },
      this.paneModel
    )

    renderXAxis({ context: this.context, quote: this.data, paneModel: this.paneModel, textRenderer, type: this.type })

    this.context.restore()
  }

  handleIndicatorsChange() {
    this.updateShouldRenderVolume()
    this.setupCache()
    this.trigger('change')
  }

  updateShouldRenderVolume() {
    this.shouldRenderVolume = !this.model.chart_layout().getIsIndicatorPresent(IndicatorType.Vol)
  }

  getVolumeTicks(count: number, maxHeight: number, fontHeight: number): number[] {
    const maxCount = 5
    const minCount = 1
    if (count > maxCount) {
      count = maxCount
    }
    const ticks = this.volumeFY.ticks(count).filter((tick) => tick !== 0)
    if (count <= minCount) {
      return [ticks[ticks.length - 1]]
    }
    // ticks are rendered on middle line so we have +- 1/2 of font size above and below range[min,max]
    // so we already have +-1 fontsize space, but in some cases that wouldn't be enough so we need to scale
    // dynamically, we add 3px of space per tick gap
    if (ticks.length * fontHeight + (ticks.length - 1) * 3 > maxHeight) {
      return this.getVolumeTicks(count - 1, maxHeight, fontHeight)
    }
    return ticks
  }

  renderVolumeAxis(context: CanvasRenderingContext2D) {
    const { ChartSettings } = this.getChartLayoutSettings()
    const { Colors } = ChartSettings.general
    if (getIsSmallIndexChart(this.specificChartFunctionality)) {
      context.save()
      const relativeVolume = Math.max(0, Math.min(2, this.data.relativeVolume))

      const x = 20
      const fy = (y: number) => ChartSettings.top.height + this.height - this.height * (0.5 * y)
      const height = this.height * (0.5 * relativeVolume)

      const label = new Text(
        {
          x: 15,
          font: { size: 8 },
          fillStyle: Colors.text,
          textBaseline: TextBaseline.middle,
          textAlign: TextAlign.right,
        },
        this.paneModel
      )
      for (let i = 0.5; i <= 2; i += 0.5) {
        const y = Math.round(fy(i))
        label
          .set({
            text: i.toFixed(1),
            y: y,
          })
          .render(context)

        context.set('strokeStyle', 'rgb(130, 130, 130)')
        context.beginPath()
        context.setLineDash([2, 3])
        context.translate(0, 0.5)
        context.moveTo(x, y)
        context.lineTo(x + 7, y)
        context.translate(0, -0.5)
        context.stroke()
      }

      context.set('fillStyle', 'rgba(0, 135, 255, 0.58)')
      context.set('lineWidth', 1)
      context.set('strokeStyle', Colors.grid)
      context.setLineDash([])

      context.fillRect(x, Math.round(ChartSettings.top.height + this.height - height), 7, Math.round(height))
      context.translate(0.5, 0.5)
      context.strokeRect(x, ChartSettings.top.height, 7, this.height)
      context.translate(-0.5, -0.5)

      new Text(
        {
          text: 'RELATIVE\nVOLUME',
          x: 33,
          y: ChartSettings.top.height + this.height + 6,
          font: { size: FONT_SIZE.XXS },
          lineHeight: LINE_HEIGHT.XS,
          fillStyle: Colors.text,
          textBaseline: TextBaseline.top,
          textAlign: TextAlign.right,
        },
        this.paneModel
      ).render(context)
      context.restore()
      return
    }
    const rangeMax = this.volumeFY.range()[1]
    const volumeAxisFontSize = ChartSettings.left.volumeAxis.font.size!
    const tickCount = Math.floor(rangeMax / volumeAxisFontSize)
    const ticks = this.getVolumeTicks(tickCount, rangeMax, volumeAxisFontSize)
    const format = this.volumeFY.tickFormat(4, 's')
    const text = new Text(
      {
        x: ChartSettings.left.volumeAxis.margin.left,
        font: Text.getMergedPropsWithDefaults('font', { size: ChartSettings.left.volumeAxis.font.size }),
        fillStyle: Colors.text,
        textBaseline: TextBaseline.middle,
        textAlign: TextAlign.right,
      },
      this.paneModel
    )
    ticks.forEach((tick) =>
      text
        .set({
          text: format(tick).replace(/G/, 'B'), // https://github.com/d3/d3-format/blob/master/README.md#locale_formatPrefix
          y: ChartSettings.top.height + this.height - this.volumeFY(tick),
        })
        .render(context)
    )
  }

  renderVolume() {
    if (!this.shouldRenderVolume) return

    const { ChartSettings } = this.getChartLayoutSettings()
    const { Colors } = ChartSettings.general
    // Return if the domain bounds are equal, this would put any value in the middle
    const [domainFrom, domainTo] = this.volumeFY.domain()
    if (this.setupAxisTimeFrame !== this.data.timeframe || domainFrom === domainTo) {
      return
    }
    const translate = getTranslate({
      context: this.context,
      xOffset: this.leftOffset + ChartSettings.left.width,
      yOffset: ChartSettings.top.height + this.height - 59,
    })
    translate.do()
    const halfBarWidth = getHalfBarWidth(this.model)
    const barWidthWithoutMargin = getBarWithoutMarginWidth(this.model)
    drawInVisibleArea({
      quote: this.data,
      paneModel: this.paneModel,
      leftOffset: this.leftOffset,
      width: this.width,
      drawBarCallback: (i, x) => {
        // Skip render if the value is <= 0
        if (this.data.volume[i] <= 0) {
          return
        }

        this.context.fillStyle = this.data.close[i] < this.data.open[i] ? Colors.volumeTrendDown : Colors.volumeTrendUp
        const y = Math.round(this.volumeFY(this.data.volume[i]))
        this.context.fillRect(x - halfBarWidth, 60, barWidthWithoutMargin, -y)
      },
    })
    translate.undo()
  }

  renderOverlays() {
    const { ChartSettings } = this.getChartLayoutSettings()
    const translate = getTranslate({
      context: this.context,
      xOffset: this.leftOffset + ChartSettings.left.width,
      yOffset: ChartSettings.top.height,
    })
    translate.do()
    for (const overlay of this.overlays) {
      overlay.renderContent(this.context)
    }
    translate.undo()
  }

  renderCross(context: CanvasRenderingContext2D) {
    const isRendered = renderCross({
      context,
      mouseModel,
      paneModel: this.paneModel,
      contentWidth: this.width,
      contentHeight: this.height,
      quote: this.data,
      getRoundDecimalPlaces: (price) => this.getPlacesLastClose(price),
      onRenderCrossText: !this.getIsRedesignedChart() ? this.renderCrossText : undefined,
    })
    if (this.getIsRedesignedChart()) {
      this.renderCrossText(context, mouseModel.getCrossIndexForPane(this.paneModel))
    }

    if (isRendered !== undefined) {
      setElementCursor({
        elementId: this.model.chart_layout().getHTMLElementId(),
        cursor: isRendered ? 'crosshair' : 'default',
        conditionalCursor: 'grabbing',
      })
    }
  }

  getDataByCrossIndex(crossIndex: number, key: keyof Quote, shouldReturnRoundedString = true) {
    const n = this.data.getDataByBarIndex(key, crossIndex)
    if (n !== null) {
      return shouldReturnRoundedString ? this.roundOhlc(n) : `${n}`
    }
  }

  getCrossIndexChange(crossIndex: number) {
    const crossDataIndex = this.data.getDataIndexByBarIndex(crossIndex)
    const crossClose = this.data.close[crossDataIndex] ?? null
    const previousClose = this.data.close[crossDataIndex - 1] ?? null
    if (crossClose !== null && previousClose !== null) {
      return getTickerChangeFromCloseValues({ data: this.data, anchorClose: crossClose, prevClose: previousClose })
    }

    return null
  }

  renderCrossText(context: CanvasRenderingContext2D, crossIndex: number) {
    const { ChartSettings } = this.getChartLayoutSettings()
    context.save()
    context.translate(ChartSettings.left.width, 0)

    if (this.getIsRedesignedChart()) {
      this.renderOverlaysLabels(context)
      if (Number.isNaN(crossIndex)) {
        this.getOHLC({ context })
        context.restore()
        return
      }
    }

    const dateByBarIndex = this.data.getDataByBarIndex('date', crossIndex)
    const date = dateByBarIndex ? utils.dateFromUnixTimestamp(dateByBarIndex) : null

    if (date === null) {
      const virtualTradedDates =
        this.data.date.length > 0
          ? getVirtualTimeframeTradedDates({
              dateStart: this.data.date[0],
              dateEnd: this.data.date[this.data.date.length - 1],
              quote: this.data,
            })
          : []
      this.getOHLC({
        context,
        dateString: virtualTradedDates[crossIndex]
          ? getDateString({
              date: utils.dateFromUnixTimestamp(virtualTradedDates[crossIndex]),
              timeframe: this.data.timeframe,
            })
          : null,
      })
      context.restore()
      return
    }

    this.getOHLC({
      context,
      date,
      data: {
        open: this.getDataByCrossIndex(crossIndex, 'open'),
        high: this.getDataByCrossIndex(crossIndex, 'high'),
        low: this.getDataByCrossIndex(crossIndex, 'low'),
        close: this.getDataByCrossIndex(crossIndex, 'close'),
        volume: this.getDataByCrossIndex(crossIndex, 'volume', false),
      },
      changePercentage: this.getCrossIndexChange(crossIndex),
    })

    context.restore()
  }

  renderChange({ context }: { context: CanvasRenderingContext2D }) {
    const CHANGE_SPACER = 10
    const { ChartSettings, isOurSiteRequest } = this.getChartLayoutSettings()
    const { ticker, change, ohlc } = ChartSettings.top
    const { tickerChange, tickerAfterChange } = getTickerChange({ data: this.data })

    if (!tickerChange) {
      return
    }

    const isOffscreen = this.specificChartFunctionality === SpecificChartFunctionality.offScreen
    const isGroupInstrument = this.data.instrument === Instrument.Group
    const finvizWatermarkOffset = !isOffscreen || isOurSiteRequest || this.isLargeChart ? 0 : 15
    const changeText = new Text(
      {
        text: isOffscreen && isGroupInstrument ? tickerChange.percentString : tickerChange.string,
        x: !getIsSmallIndexChart(this.specificChartFunctionality)
          ? this.width - ChartSettings.top.change.margin.right! - finvizWatermarkOffset
          : this.width,
        y: ChartSettings.top.change.margin.top,
        font: Text.getMergedPropsWithDefaults('font', ChartSettings.top.change.font),
        textAlign: TextAlign.right,
        textBaseline: ChartSettings.top.baseline,
        fillStyle: getChangeColor({ change: tickerChange.points, ChartSettings }),
      },
      this.paneModel
    )
    this.changeWidth = changeText.measure(context)
    changeText.render(context)

    if (isOffscreen && (!this.isLargeChart || isGroupInstrument)) {
      return
    }
    if (tickerAfterChange) {
      const afterChangeText = new Text(
        {
          text: tickerAfterChange?.string,
          font: Text.getMergedPropsWithDefaults('font', ChartSettings.top.change.font),
          textAlign: TextAlign.right,
          textBaseline: ChartSettings.top.baseline,
          fillStyle: getChangeColor({ change: tickerAfterChange.points, ChartSettings }),
        },
        this.paneModel
      )
      const afterChangeTextWidth = afterChangeText.measure(context)
      const oneLineAHChangeWidth = this.changeWidth + CHANGE_SPACER + afterChangeTextWidth

      if (
        ChartSettings.left.width + this.tickerWidth + ticker.margin.left! + OFFSET.S <
        ChartSettings.left.width + this.width - oneLineAHChangeWidth - change.margin.right!
      ) {
        afterChangeText
          .set({
            x: this.width - ChartSettings.top.change.margin.right! - this.changeWidth - CHANGE_SPACER,
            y: ChartSettings.top.change.margin.top,
          })
          .render(context)
        this.afterChange = null
        this.changeWidth = oneLineAHChangeWidth
      } else if (
        this.lastOhlc[0] &&
        ChartSettings.left.width +
          this.tickerWidth +
          ticker.margin.left! +
          this.lastOhlc[0].width! +
          ohlc.margin.left! +
          ohlc.margin.right! <
          ChartSettings.left.width + this.width - afterChangeTextWidth - change.margin.right!
      ) {
        afterChangeText
          .set({
            x: this.width - ChartSettings.top.change.margin.right!,
            y: ChartSettings.top.change.margin.top! - ChartSettings.top.change.font.size! - OFFSET.S,
          })
          .render(context)
        this.changeWidth = Math.max(this.changeWidth, afterChangeTextWidth)
        this.afterChange = {
          x: this.width - ChartSettings.top.change.margin.right!,
          y: ChartSettings.top.change.margin.top! - ChartSettings.top.change.font.size! - OFFSET.S,
          width: afterChangeTextWidth,
        }
      }
    }
  }

  renderOHLC(parameters: IRenderOHLCParameters) {
    if (!this.getIsRedesignedChart()) {
      this.renderOHLCQuotePage(parameters)
    } else {
      this.renderOHLCChartsPage(parameters)
    }
  }

  renderOHLCChartsPage({ context, date, open, high, low, close, volume, changePercentage }: IRenderOHLCParameters) {
    const chartBreakpoints = getChartBreakpoints(this.width)
    const { ChartSettings } = this.getChartLayoutSettings()
    const { Colors } = ChartSettings.general

    const OhlcText = new Text(
      {
        font: { size: FONT_SIZE.S },
        lineHeight: 18,
        fillStyle: Colors.text,
        textAlign: TextAlign.left,
        textBaseline: TextBaseline.middle,
        background: getHEXWithSpecificAplha(Colors.canvasFill, 0.8),
      },
      this.paneModel
    )

    const dateElements = date ? [{ text: date, color: gray300 }] : []
    const trendColor = (open ?? 0) > (close ?? 0) ? tailwindColors.red[400] : tailwindColors.green[400]
    const oElements = open
      ? [
          { text: 'O', color: gray300 },
          { text: open, color: trendColor },
        ]
      : []
    const hElements = high
      ? [
          { text: 'H', color: gray300 },
          { text: high, color: trendColor },
        ]
      : []
    const lElements = low
      ? [
          { text: 'L', color: gray300 },
          { text: low, color: trendColor },
        ]
      : []
    const cElements = close
      ? [
          { text: 'C', color: gray300 },
          { text: close, color: trendColor },
        ]
      : []
    const volElements = volume
      ? [
          { text: 'Vol', color: gray300 },
          { text: volume, color: trendColor },
        ]
      : []
    const changeElements = changePercentage != null ? [{ text: changePercentage.string, color: trendColor }] : []

    const lines: OhlcLineElements[] = []

    const getFlatLineArrayWithSpaces = (lineElements: OhlcLineElements[]) =>
      lineElements.flatMap((elements, index) =>
        index > 0 ? ([...(elements.length > 0 ? ['space'] : []), ...elements] as OhlcLineElements) : elements
      )
    if (chartBreakpoints.isM) {
      lines.push(
        getFlatLineArrayWithSpaces([
          dateElements,
          oElements,
          hElements,
          lElements,
          cElements,
          volElements,
          changeElements,
        ])
      )
    } else if (chartBreakpoints.isS) {
      lines.push(getFlatLineArrayWithSpaces([dateElements, changeElements]))
      lines.push(getFlatLineArrayWithSpaces([oElements, hElements, lElements, cElements, volElements]))
    } else {
      lines.push(getFlatLineArrayWithSpaces([dateElements, volElements, changeElements]))
      lines.push(getFlatLineArrayWithSpaces([oElements, hElements, lElements, cElements]))
    }

    const startX = OFFSET.M - ChartSettings.left.width
    let x = startX
    let y = OFFSET.M + OhlcText.attrs.lineHeight / 2

    lines.forEach((line) => {
      x = startX
      line.forEach((lineElement, index, lineElements) => {
        const padding = { left: 1, right: 1, top: 0, bottom: 0 }
        const { text = '', color = undefined } = lineElement === 'space' ? {} : lineElement
        if (index === 0) {
          padding.left = 5
        } else if (index === lineElements.length - 1) {
          padding.right = 5
        } else if (lineElement === 'space') {
          padding.left = 7
          padding.right = 7
          if (!chartBreakpoints.isS) {
            padding.left = 5
            padding.right = 5
          }
        }
        OhlcText.set({ text, fillStyle: color, x, y, padding })
        OhlcText.render(context)
        x += OhlcText.width
      })
      y += OhlcText.attrs.lineHeight
    })
  }

  renderOverlaysLabelsChartsPage(context: CanvasRenderingContext2D) {
    const { ChartSettings } = this.getChartLayoutSettings()
    const chartBreakpoints = getChartBreakpoints(this.width)
    const LabelsText = new Text(
      {
        font: { size: FONT_SIZE.S },
        lineHeight: 18,
        textAlign: TextAlign.left,
        textBaseline: TextBaseline.middle,
        background: getHEXWithSpecificAplha(ChartSettings.general.Colors.canvasFill, 0.8),
        // this function gets (indirectly) called from renderCrossText which is already "translateX" by ChartSettings.left.width
        x: OFFSET.M - ChartSettings.left.width,
        padding: { left: PADDING.S, right: PADDING.S, top: 0, bottom: 0 },
      },
      this.paneModel
    )

    let numberOfNewOhlcLines = 2
    if (chartBreakpoints.isM) {
      numberOfNewOhlcLines = 1
    } else if (chartBreakpoints.isS) {
      numberOfNewOhlcLines = 2
    }

    // We assume lineheight is the same as OHLC text
    const { lineHeight } = LabelsText.attrs
    let y = lineHeight * numberOfNewOhlcLines + lineHeight / 2 + OFFSET.M

    for (const overlay of this.overlays) {
      if (!overlay.isRenderedOverlaysLabel()) {
        continue
      }
      LabelsText.set({
        text: overlay.toString(),
        y,
        fillStyle: overlay.getLabelColor(),
      }).render(context)
      y += lineHeight
    }
  }

  renderOHLCQuotePage({
    context,
    date,
    open,
    high,
    low,
    close,
    volume,
    afterHour,
    isSmallIndexChart = false,
    isNewestOHLC = false,
    time,
    directRender = false,
    changePercentage,
  }: IRenderOHLCParameters) {
    const { ChartSettings } = this.getChartLayoutSettings()
    const { Colors } = ChartSettings.general
    const { ohlc } = ChartSettings.top
    const isOffscreen = this.specificChartFunctionality === SpecificChartFunctionality.offScreen
    const isGroupInstrument = this.data.instrument === Instrument.Group
    if (isOffscreen && isGroupInstrument) {
      ohlc.type = OHLCType.dateOnly
    } else if (isOffscreen && this.data.instrument !== Instrument.Stock && time) {
      ohlc.type = OHLCType.timeOnly
    }

    const lineTopY = ohlc.type ? ohlc.margin.top : ohlc.margin.top! - ChartSettings.top.ticker.font.size!
    const OHLCFontSize = ohlc.font.size!
    const centerXOffset =
      this.tickerWidth +
      ChartSettings.top.ticker.margin.left! +
      (this.width -
        (this.tickerWidth +
          ChartSettings.top.ticker.margin.left! +
          this.changeWidth +
          (isSmallIndexChart ? 0 : ChartSettings.top.change.margin.right!))) / // smallIndexChart don't have change.margin.right
        2
    const leftXOffset = this.tickerWidth + ChartSettings.top.ticker.margin.left! + ohlc.margin.left!
    const hasOhlc = ![open, high, low, close, volume].some((ohlcString) => !ohlcString)
    const OHLC = hasOhlc
      ? {
          date: [date ?? ''],
          singleLine: [
            `${date}      O:${open}  H:${high}  L:${low}  C:${close}${
              afterHour ? `  AH:${afterHour}` : ''
            }      Vol:${volume}      `,
            /*
             * this is needed because of the way we render ohlc text down below in renderOHLCToCanvas
             * undefined is used instead of null because TS had some issues with `null` even if it is filtered out
             * */
            changePercentage ? changePercentage.string : undefined,
          ].filter((value) => value !== undefined),
        }
      : {
          date: [date ?? ''],
          singleLine: [`${date ?? ''}`],
        }

    const OHLC_TEXT = new Text(
      {
        font: { size: OHLCFontSize },
      },
      this.paneModel
    )

    const getOHLCType = () => {
      if (!isSmallIndexChart) {
        return OHLCType.singleLine
      }
      return OHLCType.dateOnly
    }

    const getOHLCSettings = () => {
      switch (ohlc.type || getOHLCType()) {
        case OHLCType.singleLine:
          return {
            text: OHLC.singleLine,
            x: [leftXOffset, leftXOffset],
            y: [ohlc.margin.top],
            textAlign: TextAlign.left,
            textBaseline: [TextBaseline.alphabetic],
          }
        case OHLCType.dateOnly:
          if (isOffscreen && isGroupInstrument) {
            const text = `${OHLC.date}                   Volume: ${volume}`
            const textWidth = OHLC_TEXT.set({ text }).measure(context)
            return {
              text: [text],
              x: [
                this.width - ChartSettings.top.change.margin.right! - this.changeWidth - textWidth - CHART_MARGIN.XXL,
              ],
              y: [ohlc.margin.top],
              textAlign: TextAlign.center,
              textBaseline: [TextBaseline.alphabetic],
            }
          } else if (isSmallIndexChart) {
            return {
              text: OHLC.date,
              x: [centerXOffset],
              y: [ohlc.margin.top],
              textAlign: TextAlign.center,
              textBaseline: [TextBaseline.alphabetic],
            }
          } else {
            const isEnoughSpace =
              this.width -
                (this.tickerWidth +
                  ChartSettings.top.ticker.margin.left! +
                  ohlc.margin.left! +
                  ohlc.margin.right! +
                  this.changeWidth +
                  ChartSettings.top.change.margin.right!) >
              OHLC_TEXT.set({ text: OHLC.date[0] }).measure(context)
            const alignTop = !isEnoughSpace || ohlc.font.baseline === TextBaseline.top
            return {
              text: OHLC.date,
              x: [leftXOffset],
              y: alignTop ? [lineTopY] : [ohlc.margin.top],
              textAlign: TextAlign.left,
              textBaseline: alignTop ? [TextBaseline.top] : [TextBaseline.alphabetic],
            }
          }
        case OHLCType.timeOnly:
          return {
            text: [time],
            x: [centerXOffset - OHLC_TEXT.set({ text: time }).measure(context) / 2],
            y: [lineTopY],
            textAlign: TextAlign.center,
            textBaseline: [ohlc.font.baseline],
          }
        default:
          return
      }
    }

    if (!isNewestOHLC) {
      context.fillStyle = Colors.canvasFill
      this.lastOhlc.forEach((line) => {
        const x = (line.textAlign === TextAlign.left ? line.x : line.x! - line.width! / 2)!
        const y = (line.textBaseline === TextBaseline.top ? line.y : line.y! - OHLCFontSize)!
        context.fillRect(
          x - PADDING.XXS,
          y - PADDING.XXS,
          PADDING.XXS + line.width! + PADDING.XXS,
          PADDING.XXS + OHLCFontSize + PADDING.XXS
        )
      })

      if (this.afterChange) {
        context.fillRect(
          this.afterChange.x - this.afterChange.width - PADDING.XXS,
          this.afterChange.y - ChartSettings.top.change.font.size! - PADDING.XXS,
          PADDING.XXS + this.afterChange.width + PADDING.XXS,
          PADDING.XXS + ChartSettings.top.change.font.size! + PADDING.XS
        )
      }
    } else {
      this.lastOhlc = []
    }

    const ohlcSettings = getOHLCSettings()

    const renderOHLCToCanvas = ({
      ohlcSet,
      render = true,
    }: {
      ohlcSet?: {
        text: (string | undefined)[]
        x: number[]
        y: (number | undefined)[]
        textAlign: TextAlign
        textBaseline: (TextBaseline | undefined)[]
      }
      render?: boolean
    }) => {
      const isSingleLineWithChange = (ohlc.type || getOHLCType()) === OHLCType.singleLine && changePercentage
      ohlcSet?.text.forEach((line: string | undefined, index: number) => {
        let i = index
        let leftOffset = 0
        let fillStyle = Colors.text
        let text = line
        if (index > 0 && isSingleLineWithChange) {
          i -= 1
          leftOffset = OHLC_TEXT.measure(context)
          text = line

          fillStyle = getChangeColor({ change: changePercentage.points, ChartSettings })
        }
        const options = {
          text,
          x: ohlcSet.x[i] + leftOffset,
          y: ohlcSet.y[i],
          textBaseline: ohlc.font.baseline ? ohlc.font.baseline : ohlcSet.textBaseline[i],
          textAlign: ohlc.font.textAlign ? ohlc.font.textAlign : ohlcSet.textAlign,
          fillStyle,
        }
        OHLC_TEXT.set(options)
        if (isNewestOHLC) {
          this.lastOhlc[i] = { width: OHLC_TEXT.measure(context), ...options }
        }
        if (render) {
          OHLC_TEXT.render(context)
        }
      })
    }
    renderOHLCToCanvas({ ohlcSet: ohlcSettings, render: directRender })

    return {
      ohlcSettings,
      renderOHLCToCanvas,
    }
  }

  shouldShowPrevClose() {
    const quote = this.model.quote()
    const isStock = quote.instrument === Instrument.Stock
    const isIntradayWithPreMarket = TIMEFRAMES_WITH_PRE_MARKET.includes(quote.timeframe)
    const isIndexChart = this.specificChartFunctionality === SpecificChartFunctionality.smallIndex

    return isStock && (isIntradayWithPreMarket || isIndexChart)
  }

  renderPrevClose() {
    const { ChartSettings } = this.getChartLayoutSettings()
    const { Colors } = ChartSettings.general
    if (!this.shouldShowPrevClose()) {
      return
    }
    const translate = getTranslate({
      context: this.context,
      xOffset: ChartSettings.left.width,
      yOffset: ChartSettings.top.height,
    })
    translate.do()

    let y = Math.round(this.fy(this.model.quote().prevClose))
    if (y === this.height) y -= 1 // due pixel perfect rendering of lines, line on bottom edge is moved outside of "chart area" because of 0.5px translate, to compensate for this we subtract 1px

    new Line(
      {
        x1: 0,
        x2: this.width,
        y1: y,
        y2: y,
        strokeStyle: Colors.prevClose,
        dashLength: 3,
      },
      this.paneModel
    ).render(this.context)
    translate.undo()
  }

  renderChartNotAvailable() {
    const { ChartSettings } = this.getChartLayoutSettings()
    const { Colors } = ChartSettings.general
    const x = ~~((ChartSettings.left.width + ChartSettings.right.width + this.width) / 2)
    const y = ~~((ChartSettings.top.height + ChartSettings.bottom.height + this.height) / 2)
    new Text(
      {
        text: 'Chart not available',
        x,
        y,
        font: { size: FONT_SIZE.M, weight: 'bold' },
        fillStyle: Colors.text,
        textAlign: TextAlign.center,
        textBaseline: TextBaseline.middle,
      },
      this.paneModel
    ).render(this.context)
  }

  // private
  setupCache() {
    const { ChartSettings } = this.getChartLayoutSettings()
    /*
     * Don't create new Cache instances, because Safari takes too long to free up memory
     * leading to canvas.getContext returning null. https://stackoverflow.com/questions/52532614/total-canvas-memory-use-exceeds-the-maximum-limit-safari-12
     *
     * To further improve performance, cache.set shouldn't be called when unnecessary,
     * because setupCache is called for many model updates. The same applies for indicators/indicator.js.
     */
    this.baseCache = this.baseCache || new Cache()

    this.baseCache.set(
      (context: CanvasRenderingContext2D) => {
        if (this.data.close.length === 0) {
          this.renderText(context)
          return
        }
        this.renderYAxis(context)
        this.renderText(context)
        if (!this.getIsMobileChartPage() && this.shouldRenderVolume) {
          this.renderVolumeAxis(context)
        }
        if (!this.getIsRedesignedChart()) {
          this.renderOverlaysLabels(context)
        }
      },
      this.width + ChartSettings.left.width + ChartSettings.right.width,
      this.height + ChartSettings.top.height + ChartSettings.bottom.height
    )
  }

  // called by controllers/pane on recountScale
  setupAxis(fx: ScaleAxis) {
    this.data = this.model.quote()
    const { volumeHeight } = this.getChartLayoutSettings().ChartSettings.center

    if (typeof fx !== 'function') {
      return
    }

    const firstBarToRenderIndex = getVisibleBarToRenderIndex({
      leftOffset: this.model.leftOffset,
      paneModel: this.paneModel,
      quote: this.data,
    })

    const lastBarToRenderIndex = getVisibleBarToRenderIndex({
      leftOffset: this.model.leftOffset,
      paneModel: this.paneModel,
      quote: this.data,
      chartWidth: this.width,
    })

    const areNoBarsVisible = getAreNoBarsVisible(firstBarToRenderIndex, lastBarToRenderIndex)
    const volumes = areNoBarsVisible
      ? [this.data.volume[firstBarToRenderIndex.dataIndex], this.data.volume[lastBarToRenderIndex.dataIndex]]
      : this.data.volume.slice(firstBarToRenderIndex.dataIndex, lastBarToRenderIndex.dataIndex + 1)

    const max = utils.max(volumes) ?? 0
    const rangeMax = volumeHeight || 60
    this.volumeFY = d3.scaleLinear().range([0, rangeMax]).domain([0, max]).nice(4)
    this.setupAxisTimeFrame = this.data.timeframe
  }

  // private
  clip(addMarginBottom?: boolean) {
    const { ChartSettings } = this.getChartLayoutSettings()
    this.context.beginPath()
    const marginBottom = addMarginBottom ? ChartSettings.bottom.height : 0
    // due pixel perfect rendering of lines, line on bottom edge is moved outside of "chart area" because of 0.5px translate,
    // to compensate for this we clip canvas 1px taller than chart height
    this.context.rect(ChartSettings.left.width, ChartSettings.top.height, this.width, this.height + marginBottom + 1)
    this.context.clip()
  }

  addOverlay(overlay: Overlay) {
    overlay.chart = this
    this.overlays.push(overlay)
    return this.trigger('change')
  }

  setOverlays(overlays: Overlay[]) {
    this.overlays = overlays
    return this.trigger('change')
  }

  removeOverlay(overlay: Overlay) {
    for (let index = 0; index < this.overlays.length; index++) {
      const el = this.overlays[index]
      if (el === overlay) {
        this.attrs.overlays.splice(index, 1)
        this.overlays.splice(index, 1)
        this.trigger('change')
        break
      }
    }
  }

  getMinMax(): { min: number; max: number; firstVisibleClose?: number | null } {
    this.data = this.model.quote()
    let min = Number.MAX_VALUE
    let max = Number.MIN_VALUE
    let minIndex = -1
    let maxIndex = -1
    let firstVisibleClose = null

    const firstBarToRenderIndex = getVisibleBarToRenderIndex({
      leftOffset: this.model.leftOffset,
      paneModel: this.paneModel,
      quote: this.data,
    })
    const lastBarToRenderIndex = getVisibleBarToRenderIndex({
      leftOffset: this.model.leftOffset,
      paneModel: this.paneModel,
      quote: this.data,
      chartWidth: this.width,
    })

    const areNoBarsVisible = getAreNoBarsVisible(firstBarToRenderIndex, lastBarToRenderIndex)

    // If no bars are visible min/max are first available bar before visible and first after
    if (areNoBarsVisible) {
      min = this.data.low[firstBarToRenderIndex.dataIndex]
      max = this.data.high[lastBarToRenderIndex.dataIndex]
      if (min > max) {
        const prevMin = min
        min = max
        max = prevMin
      }
      firstVisibleClose = this.data.close[firstBarToRenderIndex.dataIndex]
    } else {
      for (let i = firstBarToRenderIndex.dataIndex; i <= lastBarToRenderIndex.dataIndex; i++) {
        if (minIndex === -1 || this.data.low[minIndex] > this.data.low[i]) {
          minIndex = i
        }
        if (maxIndex === -1 || this.data.high[maxIndex] < this.data.high[i]) {
          maxIndex = i
        }
      }

      // If only a single bar is visible get min/max index range from closes values outside of visible area
      if (this.data.low[minIndex] === this.data.high[maxIndex]) {
        const closestMinMaxIndices = getClosestDifferentMinMaxValueIndices(
          minIndex,
          maxIndex,
          this.data.high,
          this.data.low
        )
        minIndex = closestMinMaxIndices.minIndex
        maxIndex = closestMinMaxIndices.maxIndex
      }

      min = this.data.low[minIndex]
      max = this.data.high[maxIndex]

      firstVisibleClose = this.data.close[firstBarToRenderIndex.dataIndex]
    }

    // Apply scale min & max buffer if min/max is the same
    if (min === max) {
      min *= 0.99
      max *= 1.01
    }

    // Update min/max so prevClose is in range and can be rendered if it should
    if (this.shouldShowPrevClose() && this.data.prevClose !== null) {
      min = Math.min(min, this.data.prevClose)
      max = Math.max(max, this.data.prevClose)
    }

    // Update min/max so lastClose is in range and can be rendered if it should
    if (this.specificChartFunctionality === SpecificChartFunctionality.offScreen && !this.model.dateRange) {
      min = Math.min(min, this.data.lastClose)
      max = Math.max(max, this.data.lastClose)
    }

    return { min, max, firstVisibleClose }
  }

  moveBy() {}

  isInArea() {
    return false
  }

  fx = (x: number) => this.paneModel.scale.x(this.data.barIndex[x])

  fy = (y: number) => this.paneModel.scale.y(y)

  round(n: number, overridePlaces?: number) {
    return round({ data: this.data, num: n, overridePlaces: overridePlaces ?? this.getPlacesLastClose() })
  }

  roundOhlc(n: number) {
    const placesLastClose = this.getPlacesLastClose()
    return round({
      data: this.data,
      num: n,
      overridePlaces: placesLastClose ? Math.max(placesLastClose, 2) : undefined,
    })
  }

  toObject() {
    const overlays = this.overlays.map((overlay) => overlay.toObject())
    return merge({}, this.attrs, { type: this.type, overlays })
  }

  toConfig() {
    const overlays = this.overlays.map((overlay) => overlay.toConfig())
    return merge({}, this.attrs, { type: this.type, overlays })
  }

  getPlacesLastClose(price?: number) {
    const { lastClose, instrument } = this.data
    const priceAbs = price && Math.abs(price)
    if (lastClose >= 100000) {
      return 0
    } else if (lastClose >= 10000) {
      return 1
    } else if (instrument === Instrument.Stock && (priceAbs ?? lastClose) < 1) {
      return 4
    } else if (lastClose === undefined) {
      // if lastClose is not defined we'll always display 2 decimpal places, e.g. backtests, screener stats
      return 2
    }

    return undefined
  }

  hasOverlay(type: string) {
    return this.overlays.some((x) => x.type === 'overlays/' + type)
  }

  getXLine(): Line {
    return new Line(
      {
        y1: 0,
        y2: this.height,
        strokeStyle: this.getChartLayoutSettings().ChartSettings.general.Colors.grid,
        dashLength: 3,
      },
      this.paneModel
    )
  }

  getYLine(): Line {
    return new Line(
      {
        x1: 0,
        x2: this.width,
        strokeStyle: this.getChartLayoutSettings().ChartSettings.general.Colors.grid,
        dashLength: 3,
      },
      this.paneModel
    )
  }

  getChartLayoutSettings() {
    return getChartLayoutSettings(this.model.chart_layout())
  }

  getIsMobileChartPage() {
    return (
      this.isMobile && this.model.chart_layout().specificChartFunctionality === SpecificChartFunctionality.chartPage
    )
  }

  getCandleLikeChartsRenderingColors() {
    const { ChartSettings } = this.getChartLayoutSettings()
    const { Colors } = ChartSettings.general
    const { theme, specificChartFunctionality } = this.model.chart_layout()
    let colors = Colors
    const overwrittenColors = getColorOverwrites({
      colors,
      theme,
    })
    const overwrittenColorKeys = Object.keys(overwrittenColors)
    const hasOverwrittenCandleColor = ['borderUp', 'wickUp', 'borderDown', 'wickDown', 'trendUp', 'trendDown'].some(
      (colorKey) => overwrittenColorKeys.includes(colorKey)
    )

    const shouldUseDarkerColors =
      theme === Theme.light &&
      getShouldUseDarkerWickColors({
        specificChartFunctionality: specificChartFunctionality,
        totalBarWidth: ChartSettings.center.barWidth + ChartSettings.center.border * 2,
      })

    if (shouldUseDarkerColors && !hasOverwrittenCandleColor) {
      colors = { ...colors, ...darkerWickColors }
    }
    const isNodeChartWithThinBars =
      specificChartFunctionality === SpecificChartFunctionality.offScreen && ChartSettings.center.border === 0
    if (isNodeChartWithThinBars && !hasOverwrittenCandleColor) {
      colors = { ...colors, ...thinBarColorsOverride, ...overwrittenColors }
    }

    return colors
  }

  getIsRedesignedChart() {
    return this.specificChartFunctionality === SpecificChartFunctionality.chartPage || isRedesignedPage(this)
  }
}

BaseChart.initClass()

export default BaseChart
