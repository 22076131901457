import { IndicatorType, QuoteFinancialAttachment, QuoteFinancialAttachmentType } from '../../constants/common'
import IndicatorBaseConfig from './indicatorBaseConfig'

export class ShrtflConfig extends IndicatorBaseConfig {
  static quoteFinancialAttachments: QuoteFinancialAttachmentType[] = [QuoteFinancialAttachment.shortInterest]
  static type = IndicatorType.Shrtfl
  static label = 'Short Float%'
  static shortLabel = 'Short Float%'
  static seoLabel = 'Short Interest, Short Float%'
  static abbreviation = 'shrtfl'
}
