import { format } from 'date-fns'

import { ObjectHash } from '../../types/shared'
import { TIMEFRAME } from '../constants/common'
import { getTranslate } from '../controllers/renderUtils'
import { dateFromUnixTimestamp } from '../utils'
import { getBarWidthWithMargin } from '../utils/chart'
import { drawInVisibleArea } from '../utils/draw_in_visible_area'
import Indicator from './indicator'

type ValuesType = Array<{
  isBeforeData: boolean
  timestamp: number
  value: number | null
  sourceIndex: number
  isVirtualMatch?: boolean
  // It is artificial point after the real data point, used to keep data continuity (mainly for cross value label)
  isDuplicate?: boolean
}>

class FinancialIndicator<Attrs extends ObjectHash = ObjectHash> extends Indicator<Attrs> {
  values: ValuesType[] = []
  applyMinMaxPadding = true
  isStepChart = true

  getComputedValuesAtDateIndex(_: number): ValuesType {
    throw Error('Implement getComputedValuesAtDateIndex method')
  }

  compute() {
    if (!isNaN(this.contentWidth) && this.isComputeNecessary()) {
      this.values = []

      if (this.data.financialAttachmentsData) {
        for (let i = 0; i < this.data.date.length; i++) {
          const computedValues = this.getComputedValuesAtDateIndex(i)
          if (computedValues.length > 0) {
            this.values[i] = computedValues
          } else {
            this.values[i] = (this.values[i - 1] ?? []).map((value) => ({
              ...value,
              isDuplicate: true,
              isVirtualMatch: undefined,
            }))
          }
        }
      }
    }

    this.lastValue = this.getValueAtDataIndex(this.values.length - 1).value
    const { min, max } = this.computeVisibleMinMax()
    this.min = min
    this.max = max
  }

  computeVisibleMinMax() {
    return super.computeVisibleMinMax(this.values.map((values) => values[values.length - 1]?.value ?? Number.NaN))
  }

  getValueAtDataIndex(dataIndex: number) {
    const vals = this.values[dataIndex]
    return vals?.[(vals?.length ?? 0) - 1] ?? {}
  }

  getValueLabelsAtIndex(index: number) {
    const dataIndex = this.data.barToDataIndex[index]
    const values = this.values[dataIndex]
    if (!values) {
      return []
    }

    const shouldShowDate = [TIMEFRAME.i1, TIMEFRAME.i2, TIMEFRAME.i3].includes(this.data.timeframe)
    const color = this.getChartLayoutSettings().IndicatorSettings.general.Colors.line!
    return values.map(({ timestamp, value, isBeforeData }) => {
      const valueLabel = this.getValueLabel(value)

      return {
        color,
        text:
          valueLabel !== null
            ? `${valueLabel} ${
                values.length >= 2 || isBeforeData || shouldShowDate
                  ? `(${format(dateFromUnixTimestamp(timestamp), 'MMM dd')})`
                  : ''
              }`
            : null,
      }
    })
  }

  getDotWidth() {
    if (!this.isStepChart) return 0

    const chart = this.model.chart()
    const halfBarWidth = getBarWidthWithMargin({
      chartLayout: chart.chart_layout(),
      zoomFactor: chart.zoomFactor,
      shouldRound: false,
    })
    return halfBarWidth <= 4 ? 2 : 3
  }

  renderIndicator(context: CanvasRenderingContext2D) {
    const translate = getTranslate({ context, xOffset: 0.5, yOffset: 0.5 })
    translate.do()
    let prevY: number | null = null
    let prevX: number | null = null
    const dotPositions: Array<{ x: number; y: number }> = []

    const lineColor = this.getChartLayoutSettings().IndicatorSettings.general.Colors.line
    context.set('strokeStyle', lineColor)
    context.beginPath()

    drawInVisibleArea({
      quote: this.data,
      paneModel: this.model,
      leftOffset: this.leftOffset,
      width: this.width,
      drawBarCallback: (i: number, x: number) => {
        const { value, isVirtualMatch, isDuplicate } = this.getValueAtDataIndex(i)
        const yValue = Math.round(this.fy(value!))
        if (prevX !== null && prevY !== null && !isDuplicate) {
          let xWithOffet = x
          if (isVirtualMatch) {
            const xDiff = x - prevX
            xWithOffet = Math.round(x - xDiff / 2)
          }
          if (this.isStepChart || isVirtualMatch) {
            context.lineTo(xWithOffet, prevY)
            context.lineTo(xWithOffet, yValue)

            if (this.isStepChart) {
              dotPositions.push({ x: xWithOffet, y: yValue })
            }
          }
        }

        context.lineTo(x, yValue)
        prevY = yValue
        prevX = x
      },
    })

    context.stroke()

    if (this.isStepChart) {
      const dotWidth = this.getDotWidth()
      context.save()
      context.set('fillStyle', lineColor)
      dotPositions.forEach(({ x, y }) => {
        context.beginPath()
        context.arc(x, y, dotWidth, 0, 2 * Math.PI)
        context.fill()
      })
      context.restore()
    }

    translate.undo()
  }

  getValueLabel(value: number | null | undefined) {
    return super.getValueLabel(value, this.getValueUnit())
  }

  formatAxis(value: number) {
    return this.getValueLabel(value) ?? value.toString()
  }
}

export default FinancialIndicator
