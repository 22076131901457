import { ChartConfigChartPaneElement } from '../../types/shared'
import { drawingToolsByType } from '../canvas/drawingTools'
import { chartEventsByType } from '../chart-events'
import { chartsByType } from '../charts/charts'
import { indicatorsByType } from '../indicators/indicators'
import { overlaysByType } from '../overlays/overlays'
import { captureException } from '../utils/helpers'

const canvasElements = {
  ...overlaysByType,
  ...indicatorsByType,
  ...drawingToolsByType,
  ...chartsByType,
  ...chartEventsByType,
}

export function getCanvasElementByType(elementType: ChartConfigChartPaneElement['type']) {
  const elementByType = canvasElements[elementType]
  if (!elementByType) {
    /*
     * This shouldn't happen in production, this mainly happens in development when changing between branches with different indicators
     * If it ends up in Sentry we should investigate the reason and implement removing nonexisting indicators in this case
     * */
    captureException(new Error(`${elementType} is not a canvas element (see in codebase and fix)`))
    return undefined
  }
  return elementByType
}
