import Text from '../canvas/text'
import { TextAlign, TextBaseline } from '../constants/common'
import { getRoundedObject, getXYOffsetFromLine } from '../controllers/renderUtils'
import Quote from '../models/quote/quote'
import { getIsSSr } from '../utils/helpers'
import { PatternsConfig } from './configs/patterns'
import Overlay from './overlay'

type aboveBelowType = 'above' | 'below'

class Patterns extends Overlay {
  static config = PatternsConfig

  patternsMinMaxRange?: { min: number; max: number }
  dataTicker?: string

  static getNumOfBarsBuffer() {
    return 200 // not entirely true, we would only need this as barsCount not as leftBuffer but we don't have logic in place for that
  }

  renderContent(context: CanvasRenderingContext2D) {
    try {
      super.renderContent()
      const { ChartSettings } = this.getChartLayoutSettings()
      const data = this.data
      if (data.patterns.length === 0) {
        return
      }

      if (!this.patternsMinMaxRange || this.dataTicker !== data.ticker) {
        const range = getRange(this.data, true, 'l')
        this.patternsMinMaxRange = { min: range.low, max: range.high }
      }

      this.dataTicker = data.ticker

      const originalScale = {
        width: 600,
        height: 300,
        barsInDay: Math.min(data.close.length, 200),
        high: this.patternsMinMaxRange.max,
        low: this.patternsMinMaxRange.min,
        barWidth: 0,
      }
      originalScale.barWidth = originalScale.width / 200

      const scaleY = (originalScale.high - originalScale.low) / originalScale.height
      const fx = (x: number) => {
        if (x === 0) {
          return this.fx(0)
        }
        return this.fx(data.close.length - 1 - ~~(originalScale.barsInDay - x / originalScale.barWidth))
      }
      const fy = (y: number) => this.fy(originalScale.high - y * scaleY)
      const line = (color: string, x1: number, y1: number, x2: number, y2: number) => {
        const roundedXY = getRoundedObject({
          x1: fx(x1),
          y1: fy(y1),
          x2: fx(x2),
          y2: fy(y2),
        })
        const translateXY = getXYOffsetFromLine({ lineWidth: 1, ...roundedXY })
        context.translate(translateXY.x, translateXY.y)

        context.beginPath()
        context.set('lineWidth', 1)
        context.set('strokeStyle', color)
        context.lineTo(roundedXY.x1, roundedXY.y1)
        context.lineTo(roundedXY.x2, roundedXY.y2)
        context.stroke()

        context.translate(translateXY.x * -1, translateXY.y * -1)
      }
      const text = (text: string, aboveBelow: aboveBelowType, color: string, x1: number, x2: number, y1: number) => {
        new Text(
          {
            x: fx((x1 + x2) / 2),
            y: fy(y1) + (aboveBelow === 'above' ? -1 : 2),
            font: { size: 8, weight: 'bold' },
            textBaseline: aboveBelow === 'above' ? TextBaseline.bottom : TextBaseline.top,
            fillStyle: color,
            textAlign: TextAlign.center,
            text,
          },
          this.model
        ).render(context)
      }
      const textHS = (text: string, aboveBelow: aboveBelowType, color: string, x1: number, y1: number) => {
        new Text(
          {
            x: fx(x1) + 5,
            y: fy(y1) + (aboveBelow === 'above' ? -1 : 2),
            font: { size: 9, weight: 'bold' },
            textBaseline: aboveBelow === 'above' ? TextBaseline.bottom : TextBaseline.top,
            fillStyle: color,
            textAlign: TextAlign.center,
            text,
          },
          this.model
        ).render(context)
      }
      const above = (y: number) =>
        fy(y) + 10 >= this.model.height - ChartSettings.top.height - ChartSettings.bottom.height ? 'above' : 'below'
      const below = (y: number) => (fy(y) <= 15 ? 'below' : 'above')

      const supportColor = 'rgb(0, 150, 220)'
      const resistColor = 'rgb(180, 50, 200)'

      for (const pattern of data.patterns) {
        const { x1, x2, x3, y3 } = pattern
        let { y1, y2 } = pattern
        switch (pattern.kind) {
          case 1: // Horiz S/R
            line('rgba(50, 50, 180, 0.588)', x1, y1, x2, y2)
            break

          case 2: // tl resist
            line(resistColor, x1, y1, x2, y2)
            break

          case 3: // tl support
            if (y1 === originalScale.height && y2 === originalScale.height) {
              y1 = y2 = originalScale.height - 1
              // Maybe just need to check on the new scale?
            } // support "pod grafom"
            line(supportColor, x1, y1, x2, y2)
            break

          case 12: // double top
            text('Double Top', below(y1), resistColor, x1, x2, y1)
            break

          case 13: // double bottom
            text('Double Bottom', above(y1), supportColor, x1, x2, y1)
            break

          case 14: // multi top
            text('Multiple Top', below(y1), resistColor, x1, x2, y1)
            break

          case 15: // multi bottom
            text('Multiple Bottom', above(y1), supportColor, x1, x2, y1)
            break

          case 16: // h&s top
            textHS('L', below(y1), resistColor, x1, y1)
            textHS('H', below(y2), resistColor, x2, y2)
            textHS('R', below(y3), resistColor, x3, y3)
            break

          case 17: // h&s bot
            textHS('L', above(y1), supportColor, x1, y1)
            textHS('H', above(y2), supportColor, x2, y2)
            textHS('R', above(y3), supportColor, x3, y3)
            break
          default:
            break
        }
      }
    } catch (error) {
      // There are cases where drawing may fail because of combination of originalScale & patters fx() & overlays fx()
      // issue is that dataPoint end up outside of data indexes, value became NaN, and canvas draw fails with invalid argument
      // this doesn't happen often, but we may as well be notified about it, there isn't really point to trying to work a fix on FE
      // it would be better to at least check if we wouldn't be able to refactor BE patterns app
      if (!getIsSSr()) {
        window.Sentry?.captureMessage('patterns.ts renderContent', {
          extra: {
            ticker: this.data.ticker,
            error,
          },
        })
      }
    }
  }

  getModalConfig() {
    const options = {
      supportColor: {
        type: 'color',
        label: 'Support Color',
        name: 'supportColor',
        value: this.attrs.supportColor ?? this.getFreeColor(),
      },
      resistColor: {
        type: 'color',
        label: 'Resist Color',
        name: 'resistColor',
        value: this.attrs.resistColor ?? this.getFreeColor(),
      },
    }

    return {
      title: PatternsConfig.label,
      inputs: PatternsConfig.inputsOrder.map((item) => options[item]),
      inputsErrorMessages: {},
    }
  }

  getIsValid() {
    return true
  }

  isRenderedOverlaysLabel() {
    return false
  }
}

Patterns.prototype.defaults = { supportColor: '#69c1ea', resistColor: '#d386df' }

export default Patterns

// FUNCTIONS PORTED FROM QuoteInteractive.cs, to calculate range on FE

function getRange(quotes: Quote, isTA: boolean, size: string) {
  const isLarge = size === 'l'
  const drawBars = isLarge ? (isTA ? 200 : 133) : isTA ? 200 : 65

  let bars = quotes.close.length
  if (bars > drawBars) {
    bars = drawBars
  }

  const range = getHighLow(quotes, bars)
  adjustRangeToChartStepPrice(range, 10)
  return range
}

function getHighLow(quotes: Quote, bars: number) {
  const range = { high: 0, low: 0 }
  const lastIndex = quotes.close.length - 1

  if (quotes.close.length === 0) {
    return range
  }

  if (bars > quotes.close.length) {
    bars = quotes.close.length - 1
  }

  range.high = quotes.high[lastIndex]
  range.low = quotes.low[lastIndex]

  // Just to be sure (yahoo data broken)
  let o = quotes.open[lastIndex]
  let c = quotes.close[lastIndex]
  let pc = lastIndex > 0 ? quotes.close[lastIndex - 1] : c

  if (pc > range.high) range.high = pc
  if (pc < range.low) range.low = pc
  if (o / pc - c / pc > 2) o = c // broken open

  let minOC = o <= c ? o : c
  let maxOC = o >= c ? o : c

  for (let i = lastIndex - 1; i > lastIndex - bars; --i) {
    const hi = quotes.high[i]
    const lo = quotes.low[i]
    o = quotes.open[i]
    c = quotes.close[i]
    pc = i - 1 >= 0 ? quotes.close[i - 1] : c

    if (o / pc - c / pc > 2) o = c // broken open

    const top = o >= c ? o : c
    const bottom = o <= c ? o : c

    if (hi > range.high) range.high = hi
    if (lo < range.low) range.low = lo
    if (top > maxOC) maxOC = top
    if (bottom < minOC) minOC = bottom
  }

  // Fix h/l extrema
  const rangeOC = (maxOC - minOC) / 2
  if (range.high > rangeOC + maxOC) {
    range.high = rangeOC + maxOC
  }
  if (range.low < minOC - rangeOC) {
    range.low = minOC - rangeOC
  }

  return range
}

function adjustRangeToChartStepPrice(range: { high: number; low: number }, count: number): void {
  const step = (range.high - range.low) / count
  const mag = Math.floor(Math.log10(step))
  const magPow = Math.pow(10, mag)
  let magMsd = Math.ceil(step / magPow)

  if (magMsd > 5.0) {
    magMsd = 10.0
  } else if (magMsd > 2.0) {
    magMsd = 5.0
  } else if (magMsd > 1.0) {
    magMsd = 2.0
  }

  const chartStep = magMsd * magPow

  // Round low & high
  range.high = Math.ceil(range.high / chartStep) * chartStep
  range.low = Math.floor(range.low / chartStep) * chartStep
}
