import { ObjectHash } from '../../types/shared'
import { Theme } from '../../types/shared'
import ChartEvent from '../chart-events/chart-event'
import { ChartEventBadgeType } from '../constants/common'
import { chartEventsAsyncModule } from '../controllers/chart-events/async-modules'
import { ChartEventBadgeState, IChartEventBadgeProps } from '../utils/chart-events-utils'
import { captureException } from '../utils/helpers'
import { CHART_EVENT_BADGE_SIZE } from './constants'
import ElementModel from './element'
import Pane from './pane'

let badgeImages: ObjectHash<{ image: HTMLImageElement; isLoaded?: boolean }, string> | null = null

type ChartEventBadgeCommonType = Omit<IChartEventBadgeProps, 'width'> & { badgeType: ChartEventBadgeType }

function getSvgImageCacheKey(props: Pick<ChartEventBadgeCommonType, 'badgeType' | 'theme' | 'state'>) {
  return `${props.badgeType}-${props.theme}-${props.state}`
}

async function fetchAndCacheChartEventBadges({ onBadgeInitLoad }: { onBadgeInitLoad: () => void }) {
  // Currently node charts don't render badges and if the code below is executed on server it will crash because of missing file which is not transpiled
  if (typeof window === 'undefined') {
    return
  }

  if (badgeImages === null) {
    badgeImages = {}
  } else {
    // Early return if cache was already initialized
    return
  }

  // Temp workaround to prevent node charts from crashing
  const { importWithRetry } = await import('@finviz/website')
  const { chartBadgeSvgByType } = await importWithRetry(chartEventsAsyncModule.importFn)

  const handleImageResolve = () => {
    const hasSomeUnresolvedImages = Object.values(badgeImages!).some(({ isLoaded }) => isLoaded === undefined)
    if (!hasSomeUnresolvedImages) {
      onBadgeInitLoad()
    }
  }

  Object.values(ChartEventBadgeState).forEach((badgeState) => {
    Object.values(ChartEventBadgeType).forEach((badgeType) => {
      Object.values(Theme).forEach((theme) => {
        const cacheKey = getSvgImageCacheKey({
          badgeType,
          state: badgeState,
          theme,
        })
        const svgHTML = chartBadgeSvgByType[badgeType]({
          state: badgeState,
          theme,
          width: CHART_EVENT_BADGE_SIZE,
        })
        const img = new Image()
        img.onload = () => {
          badgeImages![cacheKey].isLoaded = true
          handleImageResolve()
        }
        img.onerror = () => {
          badgeImages![cacheKey].isLoaded = false
          handleImageResolve()
          captureException(new Error(`Image with cache key ${cacheKey} is invalid`), { extra: { src: img.src } })
        }
        img.src = `data:image/svg+xml,${encodeURIComponent(svgHTML)}`
        badgeImages![cacheKey] = {
          image: img,
        }
      })
    })
  })
}

class ChartEventElement extends ElementModel {
  static initClass(paneModel: typeof Pane) {
    this.configure('ChartEventElement', 'instance', 'lastChange', 'zIndex', 'yIndex', 'elementId', 'updateOhlcVersion')
    this.belongsTo('pane', paneModel)
  }

  declare instance: ChartEvent
  declare updateOhlcVersion?: number
  declare updateChartEventsVersion?: number
  yIndex = 0

  getChartEventBadgeImage(props: ChartEventBadgeCommonType) {
    const cacheKey = getSvgImageCacheKey(props)
    const badgeImage = badgeImages?.[cacheKey]
    if (badgeImage) {
      // Possible fix for https://finvizcom.sentry.io/issues/4737062163
      if (badgeImage.isLoaded && badgeImage.image.width > 0 && badgeImage.image.height > 0) {
        return badgeImage.image
      }
      return undefined
    }

    void fetchAndCacheChartEventBadges({
      onBadgeInitLoad: () => {
        this.instance.model
          .chart()
          .chart_layout()
          .getAllPanes()
          .forEach((pane) => {
            const chartEventModel = pane.getAllChartEvents(false)[0]
            chartEventModel?.instance.trigger('change', this)
          })
      },
    })
  }

  getChartEventData() {
    return this.instance.model
      .chart()
      .quote()
      ?.chartEvents?.find((stockEvent) => stockEvent.elementId === this?.elementId)
  }

  toObject() {
    return {
      ...super.toObject(),
      yIndex: this.yIndex,
    }
  }

  toConfig() {
    return {
      ...super.toConfig(),
      yIndex: this.yIndex,
    }
  }
}

export default ChartEventElement
