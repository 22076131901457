import classNames from 'classnames'
import merge from 'lodash.merge'
import React from 'react'

import { ObjectHash } from '../../../types/shared'
import { IndicatorType, SpecificChartFunctionality } from '../../constants/common'
import { useModelState } from '../../model-hooks/use-model-state'
import Quote from '../../models/quote'
import { getChartsDimensions, getMaxValue, isRedesignEnabled } from '../../utils'
import { prefetchPerfIndicatorAllQuotes } from '../../utils/chart'
import { getQuoteFinancialAttachmentsFromChartConfig } from '../../utils/getQuoteFinancialAttachmentsFromChartConfig'
import { getLeftOffset, updateZoomAndLeftOffsetByDateRange } from '../renderUtils'
import { ChartSpinnerOverlay } from './chart-spinner-overlay'
import { CHART_CLASS_NAMES, ChartProps, DEFAULT_WRAPPER_COMPONENT } from './constants'

export function withChartInit(Component: React.ComponentType<ChartProps>) {
  return function WrappedComponent(props: ChartProps) {
    const { WrapperComp = DEFAULT_WRAPPER_COMPONENT, gridArea } = props
    const isModelDestroyedRef = React.useRef<null | boolean>(null)
    const model = useModelState(props.chartModel, { watchProperties: ['panes', 'quote'] })
    const layoutModel = useModelState(props.layoutModel, { watchProperties: ['activeChart'] })
    const quote = model.quote()

    const initChartRef = React.useRef(async () => {
      const { chartModel, config } = props
      const { premarket, hasChartEvents, aftermarket, ticker, instrument, timeframe, panes: panesConfig } = config

      /*
       * This has to be in sync with the logic
       * in getChartQuotes in offScreenCanvasRender.ts
       * & initChartRef in with-chart-init.tsx
       * & getQuoteBarsBufferAndCount in chart.ts
       */
      const maxQuoteBarsBufferAndCount = props.layoutModel.getAllCharts().reduce(
        (acc, chart) => {
          if (chartModel.ticker !== chart.ticker || chartModel.timeframe !== chart.timeframe) return acc

          const quoteBarsBufferAndCount = chart.getQuoteBarsBufferAndCount()

          return {
            ...acc,
            dateFrom: getMaxValue(acc.dateFrom, quoteBarsBufferAndCount.dateFrom),
            barsCount: getMaxValue(acc.barsCount, quoteBarsBufferAndCount.barsCount),
            leftBuffer: getMaxValue(acc.leftBuffer, quoteBarsBufferAndCount.leftBuffer),
            isAllData: acc.isAllData || quoteBarsBufferAndCount.leftBuffer === Infinity,
          }
        },
        { dateFrom: undefined, barsCount: undefined, leftBuffer: undefined, isAllData: false } as Record<
          'dateFrom' | 'barsCount' | 'leftBuffer',
          number | undefined
        > & { isAllData: boolean }
      )

      const options = {
        ...(maxQuoteBarsBufferAndCount.isAllData ? {} : maxQuoteBarsBufferAndCount),
        premarket,
        aftermarket,
        events: hasChartEvents,
        patterns: panesConfig.some((pane) =>
          pane.elements?.some((element) =>
            element.overlays?.some((overlay: ObjectHash) => overlay.type === 'overlays/patterns')
          )
        ),
      }

      const quote = await Quote.get({
        ticker,
        instrument,
        timeframe,
        options: {
          ...options,
          financialAttachments: getQuoteFinancialAttachmentsFromChartConfig(config),
        },
      })

      const panesWithPerfIndicator = panesConfig.filter(({ elements }) =>
        elements.some(({ type }) => type === IndicatorType.Perf)
      )

      if (panesWithPerfIndicator) {
        await Promise.allSettled(
          panesWithPerfIndicator.map((paneWithPerfIndicator) =>
            prefetchPerfIndicatorAllQuotes({ paneWithPerfIndicator, timeframe, options })
          )
        )
      }

      if (isModelDestroyedRef.current) {
        return
      }

      config.panes.forEach((pane) => {
        const paneProperties = merge({}, pane)
        paneProperties.chart = chartModel
        chartModel.createPaneCascade(paneProperties)
      })

      chartModel.updateAttributes({
        quote,
        premarket: options.premarket,
        aftermarket: options.aftermarket,
      })

      chartModel.setChartEvents(quote.chartEvents)

      if (props.shouldResize) {
        const chartsDimensions = getChartsDimensions(props.layoutModel.getHTMLElementId())
        props.layoutModel.updateAttributes(chartsDimensions)
      }

      if (chartModel.dateRange) {
        updateZoomAndLeftOffsetByDateRange({ chartModel, quote })
      }

      if (chartModel.leftOffset == null) {
        const leftOffset = getLeftOffset({ quote, chartModel: chartModel, config: props.config })
        chartModel.updateAttribute('leftOffset', leftOffset)
      }
    })

    React.useEffect(() => {
      // This prevents the being re-called on hotreload
      if (isModelDestroyedRef.current !== null) {
        return
      }

      isModelDestroyedRef.current = false

      const handleModelBeforeDestroy = () => {
        isModelDestroyedRef.current = true
      }

      model.bind('beforeDestroy', handleModelBeforeDestroy)
      void initChartRef.current()
      return () => {
        model.unbind('beforeDestroy', handleModelBeforeDestroy)
      }
      // We want to run it only on mount
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const isQuoteOrQuoteFinancials = [
      SpecificChartFunctionality.quotePage,
      SpecificChartFunctionality.quoteFinancials,
    ].includes(props.layoutModel.specificChartFunctionality)
    const isChartPage = props.layoutModel.specificChartFunctionality === SpecificChartFunctionality.chartPage
    const isFuturesForexCryptoPage = [
      SpecificChartFunctionality.futuresPage,
      SpecificChartFunctionality.forexPage,
      SpecificChartFunctionality.cryptoPage,
    ].includes(props.layoutModel.specificChartFunctionality)
    const isModelReady =
      quote && ((quote.fetchedAt === undefined && quote.close.length > 0) || quote.fetchedAt > 0 || quote)
    const loadingStyle =
      !isModelReady && isQuoteOrQuoteFinancials
        ? // 2px is border
          { width: model.width ? model.width + 2 : '100%', height: model.height + 2 }
        : undefined

    return (
      <WrapperComp
        className={classNames(CHART_CLASS_NAMES, {
          active: isQuoteOrQuoteFinancials,
          'overflow-hidden': isFuturesForexCryptoPage && isRedesignEnabled(),
          'outline outline-1 outline-blue-400': layoutModel.activeChart.eql(model) && props.hasOutline,
        })}
        {...(WrapperComp !== DEFAULT_WRAPPER_COMPONENT ? { gridArea } : {})}
        data-testid={`chart-${props.chartIndex}-container`}
        style={loadingStyle}
      >
        {isChartPage && (
          <ChartSpinnerOverlay
            className={classNames({
              'bg-white': isQuoteOrQuoteFinancials,
              'bg-gray-50 dark:bg-gray-800': isChartPage,
            })}
            shouldDisplay={!isModelReady}
          />
        )}
        {isModelReady && <Component {...props} />}
      </WrapperComp>
    )
  }
}
