import Spine from '@finviz/spine'
import { ChartGrid, ChartGridCell, DialogStateReturn } from '@finviz/website'
import classnames from 'classnames'
import flowRight from 'lodash.flowright'
import merge from 'lodash.merge'
import throttle from 'lodash.throttle'
import React from 'react'
import * as ReactDOM from 'react-dom'

import {
  ChartConfigObject,
  DrawingSpineOptionsEvent,
  IIdea,
  RootChartConfigObject,
  RootChartConfigObjectChart,
} from '../../../types/shared'
import { ChartEditorEnum, LAYOUTS, LayoutType, SpecificChartFunctionality, TIMEFRAME } from '../../constants/common'
import { getChartQuoteFetchBufferConfig } from '../../helpers/getGetChartBarsBuffer'
import ChartModel from '../../models/chart'
import ChartLayoutModel from '../../models/chart_layout'
import ChartLayout from '../../models/chart_layout'
import { AutoSaveState } from '../../models/constants'
import ElementModel from '../../models/element'
import utils, {
  getChartsDimensions,
  getFullscreenStatus,
  getIsMobilePortrait,
  isRedesignEnabled,
  isRedesignedPage,
} from '../../utils'
import {
  getIsPreserveDrawingsAndAutosaveAvailable,
  getIsSidebarEnabled,
  isQuoteForexFuturesCryptoPage,
} from '../../utils/chart'
import fetchApi from '../../utils/fetch_api'
import { getTickersAndContainerTypesInLayoutModel } from '../autosave/utils'
import { withDrawingsAutoSave } from '../autosave/with-auto-save'
import Chart from '../chart'
import { SidebarDirection } from '../sidebar/constants'
import { Sidebar } from '../sidebar/sidebar'
import Toolbar from '../toolbar'
import { AutoSaveNotesDialog } from '../toolbar/auto-save-notes'
import { ConfirmationDialog } from '../toolbar/confirmation_dialog'
import { DrawingActions, DrawingSetting, DrawingTool } from '../toolbar/interfaces'
import ToolbarMobile from '../toolbar/mobile/toolbar-mobile'
import { withDialogState } from '../with_dialog_state_hook'
import { AutoSaveStateIndicator } from './auto-save-state-indicator'
import { ChartLayoutAutoSaveNote } from './chart-layout-auto-save-note'
import { PublishOldButtonClickListener, getPublishChartHtmlElements } from './publish_old_button_click_listener'
import { withChartPageTitle } from './with-chart-page-title'
import { withChartRouteChange } from './with-chart-route-change'
import { withCompleteLayoutModelInit } from './with-complete-layout-model-init'
import { withIdeaModelState } from './with-idea-model-state'
import { withQuoteCacheManagement } from './with-quote-cache-management'
import { withQuotePolling } from './with-quote-polling'

interface ChartLayoutComponentProps {
  config: RootChartConfigObject
  confirmationDialogState: DialogStateReturn
  noteDialogState: DialogStateReturn
  shouldResize?: boolean
  chartLayoutModel: ChartLayoutModel
  idea?: IIdea
  deleteAllAutoSavedElements: () => void
  isAutoSaveActive: boolean
}

interface ChartLayoutComponentState {
  editable: boolean
  editors: ChartEditorEnum[]
  layout: LayoutType
  activeChartInteraction: string | null
  touchEventsDisabled: boolean
  isToolbarHorizontal: boolean
  activeTool: DrawingTool
  drawingSettings: Record<DrawingSetting, boolean>
}

class ChartLayoutComponentBase extends React.Component<ChartLayoutComponentProps, ChartLayoutComponentState> {
  // expects config, bindPublic
  model: ChartLayoutModel
  isMobile = Boolean(utils.isMobile(true))
  charts: Array<{ model: ChartModel; config: RootChartConfigObjectChart }>
  orientationChangeTimeout: number | undefined
  preserveDrawingAbortController = new AbortController()

  // These two are used on quote to check whether or not we should recompute toolbar position
  prevModelWidth?: number
  prevTimeframeActiveChart?: TIMEFRAME

  constructor(props: ChartLayoutComponentProps) {
    super(props)

    this.model = props.chartLayoutModel
    this.prevModelWidth = this.model.width
    this.prevTimeframeActiveChart = this.model.activeChart?.timeframe
    const config = props.config

    this.charts = []
    for (const chartConfig of config.charts) {
      const modelProperties: Partial<ChartConfigObject> = merge({}, chartConfig)

      modelProperties.quoteFetchBufferConfig = getChartQuoteFetchBufferConfig(chartConfig)
      modelProperties.chart_layout = this.model
      delete modelProperties.panes
      if (modelProperties.zoomFactor == null) {
        modelProperties.zoomFactor = this.model.defaultZoomFactor
      }
      if (modelProperties.refreshData == null) {
        modelProperties.refreshData = true
      }
      if (!modelProperties.dateRange) {
        modelProperties.dateRange = null
      }
      modelProperties.isHideDrawingsActive = this.model.isHideDrawingsActive
      modelProperties.isScrolled = false
      const model = this.model.charts().create<ChartModel>(modelProperties)
      this.charts.push({ model, config: chartConfig })
    }
    this.model.updateAttribute('activeChart', this.model.charts().first())

    this.state = {
      editable: this.model.editable,
      editors: this.model.editors,
      layout: this.model.layout,
      activeChartInteraction: null,
      activeTool: DrawingTool.Mouse,
      touchEventsDisabled: false,
      isToolbarHorizontal: true,
      drawingSettings: {
        [DrawingSetting.DrawingModeContinuous]: false,
        [DrawingSetting.PreserveDrawings]: this.handleInitialPreserveDrawingsState(),
        [DrawingSetting.HideDrawings]: false,
        [DrawingSetting.LockDrawings]: false,
      },
    }
  }

  componentDidMount() {
    if (this.state.editable) {
      const DELETE = 46
      window.addEventListener('keyup', (e) => {
        const selected = this.model.getPaneWithSelection()
        if (e.keyCode === DELETE && selected) {
          const element = ElementModel.findByAttribute<ElementModel>('instance', selected.selection)
          if (element && !element.instance.isEditInProgress) {
            const elementPane = element.pane()
            element.destroyCascade({ eventType: DrawingSpineOptionsEvent.Remove })
            elementPane.normalizeZIndexes()
          }
        }
      })
    }

    if (this.isMobile) {
      this.model.settings.ThumbSettings.size = this.model.settings.ThumbSettings.touchSize
    }

    // This is necessary because we need to have chart width to calculate correct bars count on initial quote load
    this.onWindowResize()
    this.onModelChange()

    this.model.bind('change', this.onModelChange)
    this.model.charts().bind('destroy', this.onChartDestroy)

    this.setToolbarPosition()
    window.addEventListener('resize', this.onWindowResize)
    window.addEventListener('orientationchange', this.handleOrientationChange)

    if (this.isMobile) {
      this.checkTouchEventsDisabled()
      window.addEventListener('touchend', this.checkTouchEventsDisabled)
    }
  }

  componentDidUpdate(prevProps: Readonly<ChartLayoutComponentProps>): void {
    if (prevProps.idea && !this.props.idea) {
      if (this.model.specificChartFunctionality === SpecificChartFunctionality.chartPage) {
        this.onWindowResize()
      }
      document.querySelector('#idea-title-container')?.remove()
    }

    /**
     * `setToolbarPosition` calls `flushSync` so we want to use it as sparingly as we can
     * It is only needed when chart resizes (or quote timeframe changes)
     */
    const quoteTimeframe = this.model.activeChart.quote()?.timeframe
    if (
      (quoteTimeframe && this.prevTimeframeActiveChart !== quoteTimeframe) ||
      this.prevModelWidth !== this.model.width
    ) {
      this.setToolbarPosition()
    }
    this.prevTimeframeActiveChart = quoteTimeframe
    this.prevModelWidth = this.model.width
  }

  componentWillUnmount() {
    this.preserveDrawingAbortController.abort()
    this.model.unbind('change', this.onModelChange)

    window.removeEventListener('resize', this.onWindowResize)
    window.removeEventListener('orientationchange', this.handleOrientationChange)

    if (this.isMobile) {
      window.removeEventListener('touchend', this.checkTouchEventsDisabled)
    }

    clearTimeout(this.orientationChangeTimeout)
    this.throttledResize.cancel()
    this.model.destroyCascade()
  }

  handleInitialPreserveDrawingsState = () => {
    let preserveDrawings = false
    if (getIsPreserveDrawingsAndAutosaveAvailable(this.model)) {
      if (window.FinvizSettings.toolsState?.[DrawingSetting.PreserveDrawings] !== undefined) {
        preserveDrawings = window.FinvizSettings.toolsState[DrawingSetting.PreserveDrawings]
      } else {
        void this.handlePreserveDrawingsSettings()
      }
    }
    this.model.updateAttribute('isPreserveDrawingsActive', preserveDrawings)
    return preserveDrawings
  }

  handlePreserveDrawingsSettings = async (value?: boolean) => {
    const setPreserveDrawings = (val: boolean) => {
      this.model.updateAttribute('isPreserveDrawingsActive', val)
      this.setState((prevState) => ({
        drawingSettings: {
          ...prevState.drawingSettings,
          [DrawingSetting.PreserveDrawings]: val,
        },
      }))
    }

    try {
      this.preserveDrawingAbortController.abort()
      this.preserveDrawingAbortController = new AbortController()
      const response = await fetchApi({
        location: '/api/tools_state.ashx',
        method: value !== undefined ? 'POST' : 'GET',
        body:
          value !== undefined
            ? new URLSearchParams({ tools_state: JSON.stringify({ [DrawingSetting.PreserveDrawings]: value }) })
            : undefined,
        throwOnStatusCodes: [404],
        abortController: this.preserveDrawingAbortController,
      })

      if (value === undefined && response?.[DrawingSetting.PreserveDrawings] !== undefined) {
        setPreserveDrawings(response[DrawingSetting.PreserveDrawings])
      }
    } catch {
      if (value !== undefined) {
        setPreserveDrawings(!value)
      }
    }
  }

  handleOrientationChange = () => {
    this.orientationChangeTimeout = window.setTimeout(() => {
      this.setToolbarPosition()
      if (this.props.shouldResize) {
        this.handleResize()
      }
    })
  }

  onWindowResize = () => {
    this.setToolbarPosition()

    if (this.props.shouldResize) {
      this.throttledResize()
    }
  }

  handleResize = () => {
    const modelAttrs: Partial<ChartLayoutModel> = getChartsDimensions(this.model.getHTMLElementId())
    this.model.updateAttributes(modelAttrs)
  }

  throttledResize = throttle(this.handleResize, 200)

  setToolbarPosition() {
    // Toolbar doesn’t change position on other pages
    if (!isQuoteForexFuturesCryptoPage(this.model.specificChartFunctionality)) return

    requestAnimationFrame(() =>
      /**
       * Fixes a problem where React (probably) pushes updates for <Chart /> to
       * the next render, which results in multiple re-renders of <Toolbar /> which
       * causes a flicker when <Toolbar /> changes between vertical and horizontal placement
       */
      ReactDOM.flushSync(() => {
        // quote page toolbar
        // 210px represents: (20px (toolbar left margin) + 80px (toolbar width) + 5px (toolbar rigth margin)) * 2 (both sides of chart)
        const newIsToolbarHorizontal = this.model.width + 210 > window.innerWidth || window.innerWidth < 1000
        if (newIsToolbarHorizontal !== this.state.isToolbarHorizontal) {
          this.setState({
            isToolbarHorizontal: newIsToolbarHorizontal,
          })
        }
      })
    )
  }

  checkTouchEventsDisabled = () => {
    if (!/\bcharts\b/.test(window.location.pathname) || getFullscreenStatus() || this.getVisualViewportScale() === 1) {
      this.state?.touchEventsDisabled && this.setState({ touchEventsDisabled: false })
    } else {
      !this.state?.touchEventsDisabled && this.setState({ touchEventsDisabled: true })
    }
  }

  getVisualViewportScale = () => {
    if (process.env.IS_E2E_TESTING) {
      return 1
    }
    if (window.visualViewport && this.isMobile) {
      return window.visualViewport.scale
    } else {
      return 1
    }
  }

  handleActiveToolChange = (activeTool: DrawingTool) => {
    if (!this.model.isLockDrawingsActive) {
      this.setState({ activeTool })
    }
  }

  handleDrawingAction = (actionId: DrawingActions) => {
    switch (actionId) {
      case DrawingActions.AddNote:
        this.props.noteDialogState.toggle()
        break
      case DrawingActions.DeleteDrawings:
      case DrawingActions.DeleteAutoSavedDrawings:
        this.props.confirmationDialogState.show()
        break
      default:
        return
    }
  }

  handleConfirmationDialogDeleteBtn = () => {
    if (this.model.drawingsInternalStore().autoSaveState !== AutoSaveState.Off) {
      this.props.deleteAllAutoSavedElements()
    }
    this.model.getAllElements().forEach((element) => {
      if (element.isDrawing()) {
        element.destroyCascade({ eventType: DrawingSpineOptionsEvent.Remove })
        const drawingToRemove = this.model
          .drawingsInternalStore()
          .elements.find(({ elementId }) => elementId === element.id)
        if (drawingToRemove) {
          drawingToRemove.changeType = 'destroy'
        }
      }
    })
    this.props.confirmationDialogState.hide()
  }

  handleDrawingsSetting = (settingId: DrawingSetting) => {
    const newObj = { ...this.state.drawingSettings, [settingId]: !this.state.drawingSettings[settingId] }

    switch (settingId) {
      case DrawingSetting.HideDrawings:
        this.handleActiveToolChange(DrawingTool.Mouse)
        this.toggleHideDrawings(newObj[DrawingSetting.HideDrawings])
        this.handleLockDrawings(newObj)
        break
      case DrawingSetting.PreserveDrawings:
        void this.handlePreserveDrawingsSettings(newObj[DrawingSetting.PreserveDrawings])
        this.model.updateAttribute('isPreserveDrawingsActive', newObj[DrawingSetting.PreserveDrawings])
        break
      case DrawingSetting.LockDrawings:
        this.handleActiveToolChange(DrawingTool.Mouse)
        this.handleLockDrawings(newObj)
        break
      case DrawingSetting.DrawingModeContinuous:
        this.model.updateAttribute('isDrawingModeContinuousActive', newObj[DrawingSetting.DrawingModeContinuous])
        newObj[DrawingSetting.DrawingModeContinuous] === false && this.handleActiveToolChange(DrawingTool.Mouse)
        break
      default:
        break
    }

    this.setState({ drawingSettings: newObj })
    window.gtag?.('event', 'drawings_settings_toggle', {
      event_category: newObj[settingId] ? 'enable' : 'disable',
      event_label: settingId,
    })
  }

  handleLockDrawings = (drawingSettings: Record<DrawingSetting, boolean>) => {
    this.model.updateAttribute(
      'isLockDrawingsActive',
      !(!drawingSettings[DrawingSetting.HideDrawings] && !drawingSettings[DrawingSetting.LockDrawings])
    )
  }

  toggleHideDrawings(value: boolean) {
    this.model.updateAttribute('isHideDrawingsActive', value)
    this.model.getAllCharts().forEach((chart) => chart.updateAttribute('isHideDrawingsActive', value))
  }

  renderToolbar(args: any) {
    const { activeTool, drawingSettings } = this.state
    return args.isOverlayToolbar ? (
      <ToolbarMobile
        chartLayoutModel={this.model}
        activeTool={activeTool}
        onActiveToolChange={this.handleActiveToolChange}
        onDrawingActionClick={this.handleDrawingAction}
        onDrawingSettingClick={this.handleDrawingsSetting}
        drawingSettings={drawingSettings}
        areToolsDisabled={drawingSettings[DrawingSetting.HideDrawings] || drawingSettings[DrawingSetting.LockDrawings]}
      />
    ) : (
      <Toolbar
        chartLayoutModel={this.model}
        activeTool={activeTool}
        onActiveToolChange={this.handleActiveToolChange}
        onDrawingActionClick={this.handleDrawingAction}
        onDrawingSettingClick={this.handleDrawingsSetting}
        drawingSettings={drawingSettings}
        areToolsDisabled={drawingSettings[DrawingSetting.HideDrawings] || drawingSettings[DrawingSetting.LockDrawings]}
        {...args}
      />
    )
  }

  render() {
    const { newCharts, useGrid } = this.props.config
    const { activeTool, editable, editors, isToolbarHorizontal } = this.state
    const publish = editors.includes(ChartEditorEnum.publish) && getPublishChartHtmlElements().length > 0
    const chartLayout = LAYOUTS[this.model.layout]
    const isMobilePortrait = getIsMobilePortrait(this.isMobile)
    const { tickers } = getTickersAndContainerTypesInLayoutModel(this.model)

    if (newCharts) {
      const isSidebarEnabled = getIsSidebarEnabled(SpecificChartFunctionality.chartPage)
      return (
        <>
          <div className="custom-scrollbar is-small flex grow overflow-hidden">
            {!isMobilePortrait &&
              // Due safari iOS flex-column bug not resizing height properly we need to render toolbar
              // outside of flex for portrait and inside for landscape
              // https://github.com/finvizhq/charts/pull/784
              this.renderToolbar({
                isSidebar: true,
                isHorizontal: false,
              })}
            <ConfirmationDialog
              state={this.props.confirmationDialogState}
              onAction={this.handleConfirmationDialogDeleteBtn}
              isAutosaveDialogContent={this.props.isAutoSaveActive}
              tickers={tickers}
            />
            <AutoSaveNotesDialog state={this.props.noteDialogState} chartLayoutModel={this.model} />
            <ChartGrid
              border
              id={this.model.getHTMLElementId()}
              data-testid="charts-container"
              columns={chartLayout.columns}
              rows={chartLayout.rows}
            >
              {this.charts.map(({ model, config }, index) => (
                <Chart
                  key={model.id}
                  hasOutline
                  chartIndex={index}
                  chartModel={model}
                  config={config}
                  layoutModel={this.model}
                  WrapperComp={ChartGridCell as React.ForwardRefRenderFunction<any>}
                  gridArea={chartLayout.gridAreas?.[index]}
                  setActiveChartInteraction={this.setActiveChartInteraction}
                  activeTool={activeTool}
                  activeChartInteraction={this.state.activeChartInteraction}
                  touchEventsDisabled={this.state.touchEventsDisabled}
                  shouldResize={this.props.shouldResize}
                />
              ))}
            </ChartGrid>
            <AutoSaveStateIndicator chartLayoutModel={this.model} />
          </div>
          {isMobilePortrait && isSidebarEnabled && (
            <Sidebar onShouldResize={this.throttledResize} direction={SidebarDirection.horizontal} />
          )}
          {isMobilePortrait &&
            this.renderToolbar({
              isSidebar: false,
              isHorizontal: true,
              isOverlayToolbar: isSidebarEnabled,
            })}
          {this.props.idea?.note && (
            <div className="shrink-0 border-t border-gray-300 p-4 dark:border-gray-700">
              <div className="border bg-gray-100 p-3 text-xs dark:border-gray-600 dark:bg-gray-700">
                {this.props.idea.note}
              </div>
            </div>
          )}
        </>
      )
    }

    const hasRedesign = isRedesignEnabled()
    const hasChartTools = editable && editors.includes(ChartEditorEnum.tools)
    const renderTools = isRedesignedPage(this.model) ? hasChartTools && !isToolbarHorizontal : hasChartTools

    const ideaTitleContainer = document.querySelector('#idea-title-container')
    const ideaTitle = this.props.idea?.date &&
      this.model.specificChartFunctionality === SpecificChartFunctionality.quotePage && (
        <h3 className="chart-idea-title">{`Your idea from ${this.props.idea.date}`}</h3>
      )

    return (
      <>
        {ideaTitleContainer ? ReactDOM.createPortal(ideaTitle, ideaTitleContainer) : ideaTitle}
        <PublishOldButtonClickListener isListening={!!publish} layoutModel={this.model} />
        {renderTools && (
          <div
            id="charts-drawing-tools"
            data-ishorizontal={isToolbarHorizontal ? 1 : 0}
            className={classnames({
              'border border-gray-50': isToolbarHorizontal,
              'absolute left-[-85px] w-20': !isToolbarHorizontal && !hasRedesign,
              'absolute -top-px left-[-5.5rem] -ml-px': !isToolbarHorizontal && hasRedesign,
            })}
            data-testid="charts-drawing-tools"
          >
            {this.renderToolbar({
              isHorizontal: isToolbarHorizontal,
              isAlternativeTheme: true,
            })}
          </div>
        )}
        <ConfirmationDialog
          state={this.props.confirmationDialogState}
          onAction={this.handleConfirmationDialogDeleteBtn}
          isAutosaveDialogContent={this.props.isAutoSaveActive}
          tickers={tickers}
        />
        <ChartGrid
          id={this.model.getHTMLElementId()}
          data-testid="charts-container"
          columns={chartLayout.columns}
          rows={chartLayout.rows}
          border={useGrid}
        >
          {this.charts.map(({ model, config }, index) => (
            <Chart
              key={model.id}
              chartIndex={index}
              chartModel={model}
              config={config}
              activeTool={activeTool}
              layoutModel={this.model}
              hasOutline={useGrid}
              setActiveChartInteraction={this.setActiveChartInteraction}
              activeChartInteraction={this.state.activeChartInteraction}
              touchEventsDisabled={this.state.touchEventsDisabled}
              renderChartToolbar={
                isToolbarHorizontal
                  ? () =>
                      this.renderToolbar({
                        isHorizontal: true,
                        isAlternativeTheme: true,
                      })
                  : undefined
              }
            />
          ))}
        </ChartGrid>
        <div className="clear"></div>
        <div className="left"></div>
        <div style={{ clear: 'both' }}></div>
        {this.model.specificChartFunctionality === SpecificChartFunctionality.quotePage && (
          <ChartLayoutAutoSaveNote chartLayoutModel={this.model} />
        )}
        <AutoSaveStateIndicator chartLayoutModel={this.model} />
      </>
    )
  }

  setActiveChartInteraction = (value: string | null) => {
    this.setState({ activeChartInteraction: value })
  }

  updateLayout() {
    const layout = LAYOUTS[this.model.layout]
    const charts = this.model.charts().all()
    if (charts.length === 0) {
      return
    }

    if (charts.length > layout.count) {
      this.removeCharts(layout.count)
    } else if (charts.length < layout.count) {
      this.addCharts(layout.count - charts.length)
    }

    this.model
      .charts()
      .all()
      .forEach((chart, index) => {
        const [rowStart, columnStart, rowEnd, columnEnd] =
          layout.gridAreas?.[index]
            .replace(/ /g, '')
            .split('/')
            .map((num) => Number.parseInt(num)) || []
        const numOfRowsCovered = [rowEnd, rowStart].every((value) => value !== undefined) ? rowEnd - rowStart : 1
        const numOfColumnsCovered = [columnStart, columnEnd].every((value) => value !== undefined)
          ? columnEnd - columnStart
          : 1
        const percentageOfRowsCovered = numOfRowsCovered / layout.rows
        const percentageOfColumnsCovered = numOfColumnsCovered / layout.columns
        const newWidth = ~~(this.model.width * percentageOfColumnsCovered)
        const isChartPage = this.model.specificChartFunctionality === SpecificChartFunctionality.chartPage
        const chartLayoutHeight = isChartPage
          ? this.model.height
          : this.model.charts().first().height || this.model.height
        let newHeight = ~~(chartLayoutHeight * percentageOfRowsCovered)

        if (this.model.editors.includes(ChartEditorEnum.settings) && isChartPage) {
          newHeight -= this.model.settings.ChartSettings.general.settingsHeight
        }
        if ((chart.width !== newWidth || chart.height !== newHeight) && newWidth > 0 && newHeight > 0) {
          chart.updateAttributes({ width: newWidth, height: newHeight })
        }
      })
  }

  addCharts(count: number) {
    const quote = this.model.charts().last().quote()
    if (!quote) return
    const config = {
      ...this.model.charts().last().toObject(),
      leftOffset: null,
      chart_layout: this.model,
      aftermarket: quote.aftermarket,
      premarket: quote.premarket,
    }
    for (let i = 0; i < count; i++) {
      const model = this.model.charts().create<ChartModel>(config)
      this.charts.push({ model, config })
    }
  }

  removeCharts(wantedCount: number) {
    while (this.model.charts().count() > wantedCount) {
      const chart = this.model.charts().last()
      chart.destroyCascade()
      this.charts.pop()
    }
  }

  onChartDestroy = (chartModel: ChartModel) => {
    this.updateLayout()
    if (this.model.activeChart?.eql(chartModel)) {
      this.model.updateAttribute('activeChart', this.model.charts().last())
    }
  }

  onModelChange = (layoutModel?: ChartLayout, event?: Spine.Event) => {
    if (event === 'refresh') {
      return
    }
    this.updateLayout()

    if (layoutModel && event === 'update') {
      this.setState({
        editable: layoutModel.editable,
        editors: layoutModel.editors,
        layout: layoutModel.layout,
      })
    }
  }
}

export const ChartLayoutComponent = flowRight(
  withQuoteCacheManagement,
  withDialogState,
  withCompleteLayoutModelInit,
  withChartPageTitle,
  withChartRouteChange,
  withIdeaModelState,
  withQuotePolling,
  withDrawingsAutoSave
)(ChartLayoutComponentBase)
