import { ContextMenu as ContextMenuComponent, useDropdownState } from '@finviz/website'
import React from 'react'
import * as ReactDOM from 'react-dom/client'

import { ObjectHash } from '../../types/shared'
import { useModelState } from '../model-hooks/use-model-state'
import model, { CallbacksType } from '../models/context_menu'
import { ready } from '../utils'

function ContextMenu() {
  const watchedModel = useModelState(model, {
    watchProperties: ['shown', 'anchorRect', 'config', 'callbacks'],
  })
  const state = useDropdownState({ open: watchedModel.shown })

  return (
    <ContextMenuComponent
      overlap
      // We need to ignore context menu event otherwise the dropdown would hide immediately on touch devices
      hideOnInteractOutside={(ev: Event) => ev.type !== 'contextmenu'}
      state={state}
      items={watchedModel.config?.map((item) => ({
        ...item,
        onClick: () => {
          watchedModel.callbacks?.onBeforeItemClick()
          if ('onClick' in item) {
            item.onClick?.()
          }
        },
      }))}
      getAnchorRect={() => watchedModel.anchorRect}
      onClose={() => model.updateAttribute('shown', false)}
      onFullyClosed={watchedModel.callbacks?.onFullyClosed}
      data-testid="charts-context-menu-body"
      backdrop={
        <div
          data-testid="charts-context-menu-overlay"
          onContextMenu={(ev) => {
            ev.preventDefault()
            ev.stopPropagation()
          }}
        />
      }
    />
  )
}

ready(() => {
  const rootEl = document.querySelector('.context-menu')
  if (rootEl) {
    const root = ReactDOM.createRoot(rootEl!)
    root.render(<ContextMenu />)
  }
})

const contextMenuControl = {
  show: (event: MouseEvent | React.MouseEvent, config: ObjectHash, callbacks: CallbacksType) => {
    model.updateAttributes({
      shown: true,
      config: config,
      callbacks,
      anchorRect: {
        x: event.clientX,
        y: event.clientY,
      },
    })
  },
}

export default contextMenuControl
