import { Button, Icon, decodeQueryString } from '@finviz/website'
import classNames from 'classnames'
import React from 'react'

import { ChartsOrQuotePageQueryParams, TIMEFRAME, TimeframeLabelShort } from '../../constants/common'
import { IIdea, getIdeaUrl } from './utils'

type IdeasListProps = {
  idea: IIdea
  onIdeaDeleted: (idea: IIdea) => void
}

export default function IdeaItem({ idea, onIdeaDeleted }: IdeasListProps) {
  const [isDeleting, setIsDeleting] = React.useState(false)
  const [isConfirmDelete, setIsConfirmDelete] = React.useState(false)
  const { i: currentIdea } = decodeQueryString<ChartsOrQuotePageQueryParams>()

  const onDeleteIdeaClick = React.useRef(async (idea: IIdea) => {
    setIsDeleting(true)

    let wasDeleted
    try {
      const response = await fetch(`/api/idea_delete.ashx?id=${idea.id}`, {
        method: 'DELETE',
      })

      wasDeleted = response.status === 200
    } catch {}

    if (!wasDeleted) {
      return
    }

    if (currentIdea === idea.id.toString()) {
      const newUrl = new URL(window.location.href)
      newUrl.searchParams.delete('i')
      window.location.href = newUrl.href
      return
    }

    onIdeaDeleted(idea)
  })

  return (
    <div
      key={idea.id}
      className={classNames('relative flex justify-between rounded border p-2', {
        'cursor-pointer border-transparent hover:bg-gray-200/30 dark:hover:bg-gray-500/30': !isConfirmDelete,
        'border-red-400': isConfirmDelete,
      })}
      onClick={isConfirmDelete ? undefined : () => (document.location = getIdeaUrl(idea))}
      data-testid="charts-notice-idea-item"
    >
      <>
        <div className="mr-2 flex flex-col space-y-0.5 overflow-hidden text-2xs leading-tight">
          <h1 className="m-0 space-x-3 text-sm leading-tight">
            <span className="font-medium">{idea.ticker}</span>
            {idea.company && <span className="text-muted-2">{idea.company}</span>}
          </h1>
          <span className="flex space-x-2" data-testid="charts-notice-idea-details">
            <span>{idea.date}</span>
            <span className="text-muted-2">{idea.time}</span>
            <span className="inline-flex text-gray-500">
              <Icon name="interval" width={14} className="mr-0.5" />
              {TimeframeLabelShort[idea.period as TIMEFRAME]}
            </span>
          </span>
          {idea.note !== '' ? (
            <p className="overflow-hidden text-ellipsis whitespace-nowrap text-muted-2">{idea.note}</p>
          ) : null}
        </div>

        <Button
          data-testid="charts-notice-idea-delete-btn"
          rightContent="trashCan"
          appearance="square"
          className="my-1"
          onClick={(ev: React.PointerEvent<HTMLButtonElement>) => {
            ev.stopPropagation()
            setIsConfirmDelete(true)
          }}
        />
      </>

      {isConfirmDelete && (
        <div className="absolute inset-0 flex flex-row items-center justify-between rounded bg-primary p-2">
          <div className="mr-2 self-center overflow-hidden">
            <h1 className="overflow-hidden text-ellipsis whitespace-nowrap text-sm font-medium">
              Delete {idea.ticker} Idea from {idea.date}?
            </h1>
          </div>

          <div className="my-1 flex space-x-1.5">
            <Button
              onClick={() => {
                setIsConfirmDelete(false)
              }}
            >
              Cancel
            </Button>
            <Button
              data-testid="charts-notice-idea-delete-confirm-btn"
              disabled={isDeleting}
              theme="red"
              onClick={() => {
                void onDeleteIdeaClick.current(idea)
              }}
            >
              Delete
            </Button>
          </div>
        </div>
      )}
    </div>
  )
}
