import React from 'react'

import { DrawingSpineOptions, DrawingSpineOptionsEvent } from '../../../types/shared'
import ElementCanvas from '../../canvas/element'
import { SpecificChartFunctionality } from '../../constants/common'
import { getCanvasElementByType } from '../../helpers/get-canvas-element-by-type'
import ChartLayout from '../../models/chart_layout'
import { AutoSaveState } from '../../models/constants'
import Element from '../../models/element'
import { compareHlc, incrementHlc, initHlc, receiveHlc } from '../../utils/hlc'
import { AutoSaveChangeType, AutoSaveElement, DRAWINGS_SAVE_INTERVAL_MS } from './constants'
import { useDrawingAutoSaveApi } from './use-drawing-autosave-api'
import { useIsAutoSaveActive } from './use-is-auto-save-active'
import {
  getContainerType,
  getNoteElementId,
  getTickerNoteText,
  getTickersAndContainerTypesInLayoutModel,
  mergeUpdatedDrawingsToInternalStore,
  updateInternalStoreWithChangedElement,
} from './utils'

export function useDrawingAutoSaveControls(chartLayout: ChartLayout) {
  const { fetchDrawings, saveDrawings, deleteAllDrawings } = useDrawingAutoSaveApi()
  const isAutoSaveActive = useIsAutoSaveActive(chartLayout)
  const drawingsInternalStore = React.useMemo(() => chartLayout.drawingsInternalStore(), [chartLayout])
  const saveTimeoutRef = React.useRef<number>()

  React.useEffect(
    () => () => {
      if (saveTimeoutRef.current) {
        clearTimeout(saveTimeoutRef.current)
      }
    },
    []
  )

  const saveLatestChanges = React.useCallback(
    (
      withDelayInMs = DRAWINGS_SAVE_INTERVAL_MS,
      saveQueueExtraFilterCallback: ((item: AutoSaveElement) => boolean) | null = null
    ) => {
      if (saveTimeoutRef.current) {
        clearTimeout(saveTimeoutRef.current)
      }
      const saveQueue = drawingsInternalStore.elements.filter(
        (item) =>
          compareHlc(item.lastChange, drawingsInternalStore.latestLocalUpdateTimestamp) > 0 &&
          item.lastChange.uuid === drawingsInternalStore.instanceUUID &&
          (saveQueueExtraFilterCallback === null || saveQueueExtraFilterCallback(item))
      )
      if (saveQueue.length === 0) {
        return
      }

      drawingsInternalStore.setAutoSaveState(AutoSaveState.Unsaved)

      const save = async () => {
        drawingsInternalStore.setAutoSaveState(AutoSaveState.Saving)
        const result = await saveDrawings(saveQueue)
        if (result) {
          drawingsInternalStore.updateAttributes({
            latestLocalUpdateTimestamp: receiveHlc(drawingsInternalStore.latestLocalUpdateTimestamp, result),
          })
        }
        drawingsInternalStore.setAutoSaveState(AutoSaveState.Saved)

        return result
      }

      if (withDelayInMs === 0) {
        return save()
      }

      saveTimeoutRef.current = window.setTimeout(save, withDelayInMs)
    },
    [saveDrawings, drawingsInternalStore]
  )

  const syncChartLayoutDrawings = React.useCallback(
    (drawing: AutoSaveElement) => {
      if (drawing.containerType === 'note') {
        return
      }
      const isRemove = drawing.changeType === 'destroy'

      chartLayout.getAllPanes().forEach((paneModel) => {
        const ticker = paneModel.getQuoteRawTicker()
        const paneContainerType = getContainerType(paneModel)
        if (!paneContainerType || drawing.ticker !== ticker) {
          return
        }
        const paneInternalStoreElements = drawingsInternalStore.elements.filter(
          (autoSaveElement) => autoSaveElement.ticker === ticker && autoSaveElement.containerType === paneContainerType
        )
        const paneElements = paneModel.getAllElements()
        let element: Element | undefined
        paneElements.forEach((paneElement) => {
          if (paneElement.isDrawing() && !paneElement.instance.isCreator) {
            const paneDrawing = paneInternalStoreElements.find(({ elementId }) => elementId === paneElement.elementId)
            if (!paneDrawing || paneDrawing.changeType === 'destroy') {
              paneElement.destroyCascade()
            } else if (paneElement.elementId && paneElement.elementId === drawing.elementId) {
              element = paneElement
            }
          }
        })

        const parsedElementAttrs = JSON.parse(drawing.elementAttrs)
        if (element) {
          if (drawing.elementAttrs !== JSON.stringify(element.instance.toAutosaveConfig())) {
            if (element.pane().selection !== element.instance && !element.hasOngoingInteraction()) {
              element.instance.set(parsedElementAttrs)
              element.instance.updateScales()
            }
          }
          if (drawing.zIndex !== element.zIndex) {
            element.updateAttributes({ zIndex: drawing.zIndex })
          }
          if (isRemove || paneContainerType !== drawing.containerType) {
            element.destroyCascade()
          }
        } else if (!isRemove && paneContainerType === drawing.containerType) {
          const instance = getCanvasElementByType(parsedElementAttrs.type)!.fromObject(
            parsedElementAttrs,
            paneModel
          ) as ElementCanvas
          paneModel.elements().create({ elementId: drawing.elementId, instance, zIndex: drawing.zIndex })
          instance.updateScales()
        }
      })
    },
    [chartLayout, drawingsInternalStore]
  )

  const handleAutoSaveNoteChange = React.useCallback(
    (note: string, ticker?: string) => {
      if (ticker) {
        const newNoteElement: AutoSaveElement = {
          elementId: getNoteElementId(ticker),
          zIndex: 0,
          ticker: ticker,
          lastChange: incrementHlc(drawingsInternalStore.latestLocalUpdateTimestamp, Date.now()),
          changeType: 'update',
          containerType: 'note',
          elementAttrs: JSON.stringify({ text: note }),
        }

        drawingsInternalStore.updateAttributes({
          elements: [
            ...drawingsInternalStore.elements.filter(({ elementId }) => elementId !== newNoteElement.elementId),
            newNoteElement,
          ],
        })
      }
    },
    [drawingsInternalStore]
  )

  const handleIdeaNoteChange = React.useCallback(
    (note: string) => {
      if (chartLayout.idea?.note !== note && (chartLayout.idea || note)) {
        chartLayout.updateAttribute('idea', { ...chartLayout.idea, note })
        handleAutoSaveNoteChange(note, chartLayout.activeChart?.getQuoteRawTicker() ?? undefined)
      }
    },
    [chartLayout, handleAutoSaveNoteChange]
  )

  const checkDrawingUpdate = React.useCallback(
    async (isRefetchAll = false) => {
      const { tickers, containerTypes } = getTickersAndContainerTypesInLayoutModel(chartLayout)
      if (isRefetchAll) {
        drawingsInternalStore.updateAttributes({
          latestRemoteUpdateTimestamp: initHlc(drawingsInternalStore.instanceUUID, 0),
        })
      }

      const updatedDrawings = await fetchDrawings({
        tickers,
        containerTypes,
        lastChange: drawingsInternalStore.latestRemoteUpdateTimestamp,
      })
      const { newLastUpdateTimestamp, updatedElementIds, newInternalStore } = mergeUpdatedDrawingsToInternalStore({
        updatedDrawings,
        currentInternalStore: drawingsInternalStore.elements,
        lastUpdateTimestamp: drawingsInternalStore.latestRemoteUpdateTimestamp,
        isRefetchAll,
      })

      drawingsInternalStore.updateAttributes({
        // Even if there are no changes in elements, newInternalStore is new array with the same content which may cause unnecessary rerenders
        ...(updatedElementIds.length > 0 ? { elements: newInternalStore } : {}),
        latestRemoteUpdateTimestamp: newLastUpdateTimestamp,
        latestLocalUpdateTimestamp:
          isRefetchAll && newInternalStore.length > 0
            ? receiveHlc(drawingsInternalStore.latestLocalUpdateTimestamp, newLastUpdateTimestamp)
            : drawingsInternalStore.latestLocalUpdateTimestamp,
      })

      if (updatedElementIds.length > 0 || isRefetchAll) {
        void saveLatestChanges(0)
        const allElements = chartLayout.getAllElements()
        updatedElementIds.forEach((elementId) => {
          const element = allElements.find((element) => element.elementId === elementId)

          if (element && element.isDrawing() && !element.hasOngoingInteraction()) {
            element.destroyCascade()
          }
        })

        drawingsInternalStore.elements.forEach(syncChartLayoutDrawings)
        chartLayout.getAllPanes().forEach((paneModel) => {
          paneModel.normalizeZIndexes()
        })

        if (chartLayout.specificChartFunctionality === SpecificChartFunctionality.quotePage) {
          const activeTicker = chartLayout.activeChart?.getQuoteRawTicker() ?? ''
          handleIdeaNoteChange(getTickerNoteText({ ticker: activeTicker, elements: newInternalStore }))
        }
      }
    },
    [
      chartLayout,
      drawingsInternalStore,
      syncChartLayoutDrawings,
      fetchDrawings,
      handleIdeaNoteChange,
      saveLatestChanges,
    ]
  )

  const updateInternalStoreWithElement = React.useCallback(
    (element: Element, changeType: AutoSaveChangeType) => {
      const updateResult = updateInternalStoreWithChangedElement({
        element,
        changeType,
        currentInternalStore: drawingsInternalStore.elements,
        lastUpdateTimestamp: incrementHlc(
          receiveHlc(
            drawingsInternalStore.latestLocalUpdateTimestamp,
            drawingsInternalStore.latestRemoteUpdateTimestamp
          ),
          Date.now()
        ),
      })

      if (updateResult) {
        const { shouldSyncAndSave, newInternalStore, newDrawing } = updateResult
        if (shouldSyncAndSave) {
          drawingsInternalStore.updateAttributes({ elements: newInternalStore })
          syncChartLayoutDrawings(newDrawing)
        }
      }
    },
    [syncChartLayoutDrawings, drawingsInternalStore]
  )

  const deleteAllAutoSavedElements = React.useCallback(async () => {
    const { tickers } = getTickersAndContainerTypesInLayoutModel(chartLayout)

    await deleteAllDrawings({ tickers, lastLocalChange: drawingsInternalStore.latestLocalUpdateTimestamp })
    await checkDrawingUpdate()
  }, [chartLayout, deleteAllDrawings, drawingsInternalStore, checkDrawingUpdate])

  const onElementChange = React.useCallback(
    (element: Element, _?: any, options?: DrawingSpineOptions) => {
      const elementPane = element.pane()
      // This may happen if pane had been removed already, in most cases "element.destroyed" should be true,
      // but we would need to check if it was destroyed by delete drawing action or not, and checking if pane exists covers all cases
      if (!element.isDrawing() || element.instance.isCreator || !elementPane) {
        return
      }
      const elementContainerType = getContainerType(elementPane)
      const elementPaneTicker = elementPane.getQuoteRawTicker()
      const isElementInCurrentChartLayout = chartLayout
        .getAllPanes()
        .some(
          (model) => getContainerType(model) === elementContainerType && model.getQuoteRawTicker() === elementPaneTicker
        )
      if (isElementInCurrentChartLayout) {
        const isRemove = options?.eventType === DrawingSpineOptionsEvent.Remove
        updateInternalStoreWithElement(element, isRemove ? 'destroy' : 'update')
      }
    },
    [updateInternalStoreWithElement, chartLayout]
  )

  return {
    drawingsInternalStore,
    onElementChange,
    handleIdeaNoteChange,
    deleteAllAutoSavedElements,
    isAutoSaveActive,
    handleAutoSaveNoteChange,
    syncChartLayoutDrawings,
    saveLatestChanges,
    checkDrawingUpdate,
  }
}
