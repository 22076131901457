import { IModalConfigInput, Instrument, RequireByKey } from '../../types/shared'
import { ChartElementType } from '../constants/common'
import PaneModel from '../models/pane'
import QuoteModel from '../models/quote'

export function getInstrumentLabel(instrument: Instrument) {
  switch (instrument) {
    case Instrument.Stock:
      return 'Stock'
    case Instrument.Futures:
      return 'Futures'
    case Instrument.Forex:
      return 'Forex'
    case Instrument.Crypto:
      return 'Crypto'
    case Instrument.MarketSentiment:
      return 'Market Sentiment'
  }
}

export function getChartAlt(paneModel: PaneModel, quoteModel: QuoteModel) {
  const element = paneModel.getChartOrIndicatorElement()
  let altText = `${quoteModel.name || quoteModel.ticker} - `
  if (element?.isIndicator()) {
    altText += `${element.instance.seoLabel} Indicator`
  } else {
    altText += `${getInstrumentLabel(quoteModel.instrument)} Price Chart`
  }
  return altText
}

export const getResponseChartType = (chartType: ChartElementType) => {
  switch (chartType) {
    case ChartElementType.HeikinAshi:
      return 'heikinashi'
    case ChartElementType.HollowCandleStick:
      return 'hollowcandlestick'
    case ChartElementType.LineChart:
      return 'line'
    case ChartElementType.OhlcChart:
      return 'ohlc'
    case ChartElementType.CandleStick:
    default:
      return 'candlestick'
  }
}

export const getChartElementTypeFromResponseType = (chartResponseType: string | null) =>
  chartResponseType === null
    ? null
    : Object.values(ChartElementType).find(
        (chartType: ChartElementType) => chartResponseType === getResponseChartType(chartType)
      )

type IModalConfigInputWithItems = RequireByKey<IModalConfigInput, 'items'>

export const getParsedModalInputs = (inputsArray: IModalConfigInput[]) => {
  const otherInputs: IModalConfigInput[] = []
  const selectInputs: IModalConfigInputWithItems[] = []
  const buttonSwitchInputs: IModalConfigInputWithItems[] = []
  const colorInputs: RequireByKey<IModalConfigInput, 'color'>[] = []

  inputsArray?.forEach((input) => {
    if (input.type === 'color') {
      colorInputs.push({ ...input, color: input.value as string })
    } else if (input.type === 'select') {
      selectInputs.push(input as IModalConfigInputWithItems)
    } else if (input.type === 'buttonSwitch') {
      buttonSwitchInputs.push(input as IModalConfigInputWithItems)
    } else {
      otherInputs.push(input)
    }
  })

  return {
    otherInputs,
    selectInputs,
    buttonSwitchInputs,
    colorInputs,
  }
}
