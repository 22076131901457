import Spine, { Collection } from '@finviz/spine'
import omit from 'lodash.omit'

import {
  ChartConfigChartPane,
  CustomSpineEvents,
  Instrument,
  ObjectHash,
  TodoObjectHashAnyType,
} from '../../types/shared'
import PerfChart from '../charts/perf_chart'
import {
  DateRangeType,
  IndicatorType,
  QuoteFinancialAttachmentType,
  QuotePollingIntervalInMs,
  ScaleType,
  SpecificChartFunctionality,
  TIMEFRAME,
} from '../constants/common'
import { getCanvasElementByType } from '../helpers/get-canvas-element-by-type'
import { getQuoteOptionsForDateRange } from '../helpers/getQuoteOptionsForDateRange'
import FinancialIndicator from '../indicators/financialIndicator'
import PricePerformance from '../indicators/perf'
import Utils, { getMaxValue, getMinValue } from '../utils'
import { getScaledBarWidthWithMarginByParts } from '../utils/chart'
import { IDividends, IEarnings, ISplit } from '../utils/chart-events-utils'
import { getChartLayoutSizeConfig } from '../utils/getChartLayoutSizeConfig'
import ChartEventElement from './chart-event-element'
import { ChartPartToAttrsSyncMap, ChartSyncablePart } from './chart/contstants'
import ChartLayout from './chart_layout'
import { CHART_BARS_BUFFER_IN_PX } from './constants'
import Pane from './pane'
import Quote from './quote'
import { getChartLayoutSettings } from './settings'

class Chart extends Spine.Model {
  static initClass(paneModel: typeof Pane, quoteModel: typeof Quote, chartLayoutModel: typeof ChartLayout) {
    this.configure(
      'Chart',
      'width',
      'height',
      'timeframe',
      'dateRange',
      'quoteFetchBufferConfig',
      'scale',
      'leftOffset',
      'ticker',
      'instrument',
      'fx',
      'fy',
      'zoomFactor',
      'refreshData',
      'stretch',
      'isHideDrawingsActive',
      'isScrolled',
      'firstBarClose',
      'premarket',
      'aftermarket',
      'hasChartEvents'
    )
    this.hasMany('panes', paneModel)
    this.belongsTo('quote', quoteModel)
    this.belongsTo('chart_layout', chartLayoutModel)
  }

  declare stretch: boolean
  declare chart_layout_id: string
  declare chart_layout: () => ChartLayout
  declare quote_id: string
  declare quote: () => Quote
  declare panes: () => Collection<Pane>
  declare refreshData: boolean | number
  declare dateRange: DateRangeType
  declare width: number
  declare height: number
  declare timeframe: TIMEFRAME
  declare leftOffset: number
  declare quoteFetchBufferConfig?: { barsBuffer: number; dateFrom?: number }
  declare ticker: string
  declare instrument: Instrument
  declare fx: (x: number) => number
  declare fy: (x: number) => number
  declare zoomFactor: number
  declare isHideDrawingsActive: boolean
  declare isScrolled: boolean
  declare scale: ScaleType
  declare firstBarClose: number
  declare premarket: boolean
  declare aftermarket: boolean
  declare hasChartEvents: boolean

  getChartPane() {
    return this.panes()
      .all()
      .find((pane: Pane) =>
        pane
          .elements()
          .all()
          .some((el) => el.isChart())
      )
  }

  getChartElement() {
    for (const pane of this.panes().all()) {
      for (const el of pane.elements().all()) {
        if (el.isChart()) {
          return el
        }
      }
    }
  }

  getChartType() {
    return this.getChartElement()?.instance.type
  }

  getRefreshInterval() {
    let defaultRefreshInterval: number | null = null
    const isPremium = window.FinvizSettings.hasUserPremium
    const refreshIntervals = QuotePollingIntervalInMs[isPremium ? 'Elite' : 'Free']

    if (typeof this.refreshData === 'number') {
      return this.refreshData
    } else if (this.refreshData === true) {
      defaultRefreshInterval = refreshIntervals.Default
    } else {
      // Refresh is turned off
      return null
    }

    switch (this.instrument) {
      case Instrument.Stock:
        if (process.env.IS_E2E_TESTING) {
          return QuotePollingIntervalInMs.Reduced
        }
        return Utils.isStockFastRefreshAvailable() ? refreshIntervals.Stock : QuotePollingIntervalInMs.Reduced
      case Instrument.Crypto:
        return refreshIntervals.Crypto
      case Instrument.Forex:
        return refreshIntervals.Forex
      case Instrument.Futures:
        return refreshIntervals.Futures
    }

    return defaultRefreshInterval
  }

  toObject() {
    const panes = this.panes()
      .all()
      .map((pane) => pane.toObject())
    return {
      width: this.width,
      dateRange: this.dateRange,
      height: this.height,
      timeframe: this.timeframe,
      scale: this.scale,
      leftOffset: this.leftOffset,
      ticker: this.ticker,
      instrument: this.instrument,
      zoomFactor: this.zoomFactor,
      refreshData: this.refreshData,
      stretch: this.stretch,
      chartId: this.cid,
      panes,
      isHideDrawingsActive: this.isHideDrawingsActive,
      isScrolled: this.isScrolled,
      premarket: this.premarket,
      aftermarket: this.aftermarket,
      hasChartEvents: this.hasChartEvents,
    }
  }

  toConfig(omitKeys = [] as string[]) {
    const panes = this.panes()
      .all()
      .map((pane) => pane.toConfig(omitKeys))
    return omit(
      {
        width: this.width,
        height: this.height,
        timeframe: this.timeframe,
        scale: this.scale,
        leftOffset: this.leftOffset,
        ticker: this.ticker,
        instrument: this.instrument,
        zoomFactor: this.zoomFactor,
        refreshData: this.refreshData,
        stretch: this.stretch,
        chartId: this.cid,
        panes,
        isHideDrawingsActive: this.isHideDrawingsActive,
        isScrolled: this.isScrolled,
        premarket: this.premarket,
        aftermarket: this.aftermarket,
        hasChartEvents: this.hasChartEvents,
      },
      omitKeys
    )
  }

  destroyCascade(options?: TodoObjectHashAnyType) {
    this.panes()
      .all()
      .forEach((pane) => {
        pane.destroyCascade()
      })
    return this.destroy(options)
  }

  getChartLayoutSettings() {
    return getChartLayoutSettings(this.chart_layout())
  }

  getIsDisabled() {
    return this.quote()?.close.length === 0
  }

  getIsScrollable() {
    return this.chart_layout().scrollable
  }

  getAllPanes() {
    return this.panes().all()
  }

  getAllValidPanes() {
    const cotKeys = Object.keys(this.quote().COTs ?? {})
    return this.getAllPanes().filter((pane) => {
      const mainElement = pane.mainElement()
      if (mainElement?.isIndicator() && mainElement.instance.type === IndicatorType.Cot) {
        return cotKeys.includes(mainElement.instance.attrs.code)
      }
      return true
    })
  }

  getAllElements() {
    return this.getAllPanes().flatMap((pane) => pane.getAllElements())
  }

  getAllQuotes(): Quote[] {
    const perfQuotes = this.getAllElements()
      .filter(({ instance }) => instance.type === IndicatorType.Perf)
      .flatMap(({ instance }) => Object.values((instance as unknown as PricePerformance).quotes))

    let quotePerfTickers: Quote[] = []
    if (this.chart_layout().specificChartFunctionality === SpecificChartFunctionality.quotePerf) {
      const perfChart = this.getChartElement()?.instance as PerfChart | undefined
      if (perfChart) {
        quotePerfTickers = Quote.select(
          (q: Quote) => perfChart.attrs.tickers.includes(q.ticker) && [TIMEFRAME.d, TIMEFRAME.m].includes(q.timeframe)
        )
      }
    }

    return [...perfQuotes, ...quotePerfTickers, this.quote()].filter(
      (quote, index, quotes) => quote && quotes.findIndex((q) => q?.id === quote.id) === index
    )
  }

  createPaneCascade(paneProperties: ChartConfigChartPane) {
    const paneModel = this.panes().create<Pane>(paneProperties)

    paneProperties.elements?.forEach(({ zIndex, elementId, ...element }) => {
      const instance = getCanvasElementByType(element.type)!.fromObject(element, paneModel)
      paneModel.elements().create({ instance, zIndex, elementId })
      paneModel.chart().trigger(CustomSpineEvents.IndicatorsChange)
    })

    const chartOrIndicator = paneModel.getChartOrIndicatorElement()
    if (paneModel.mainElement()?.elementId !== chartOrIndicator?.elementId) {
      paneModel.updateAttributes({ mainElement: chartOrIndicator })
    }

    return paneModel
  }

  updateAttributesAndSync<T extends ObjectHash = ObjectHash>(value: T) {
    const attrsInSync = Object.entries(ChartPartToAttrsSyncMap)
      .filter(([key]) => this.getIsChartPartInSync(key as unknown as ChartSyncablePart))
      .flatMap(([_, modelAttr]) => modelAttr)
    this.updateAttributes(value)

    if (attrsInSync.length > 0) {
      this.chart_layout()
        .getAllCharts()
        .forEach((chartModel) => {
          if (this.eql(chartModel)) {
            return
          }
          const newAttrs: ObjectHash = {}
          attrsInSync.forEach((modelAttr) => {
            if (value.hasOwnProperty(modelAttr)) {
              newAttrs[modelAttr] = value[modelAttr]
            }
          })
          chartModel.updateAttributes(newAttrs)
        })
    }
  }

  setSyncChartParts(chartParts: ChartSyncablePart | ChartSyncablePart[], isInSync: boolean) {
    this.chart_layout().setSyncChartParts(chartParts, isInSync)
  }

  getIsChartPartInSync(chartPart: ChartSyncablePart) {
    return this.chart_layout().getIsChartPartInSync(chartPart)
  }

  getHasPatterns() {
    return this.getAllElements().some((element) => {
      if (element.isChart()) {
        return element.instance.hasOverlay('patterns')
      }

      return false
    })
  }

  getQuoteFinancialAttachments() {
    const attachments = this.getAllElements()
      .filter((el) => el.isFinancialIndicator())
      .flatMap((indicatorElement) =>
        (indicatorElement.instance as unknown as FinancialIndicator).getQuoteFinancialAttachments()
      )
      .filter((quoteFinancialAttachment, index, arr) => arr.indexOf(quoteFinancialAttachment) === index)

    return attachments as QuoteFinancialAttachmentType[]
  }

  getQuoteRawTicker(): string | null {
    return this.quote()?.getRawTicker() ?? null
  }

  setChartEvents(chartEvents?: Array<IEarnings | IDividends | ISplit>) {
    const chartPane = this.getChartPane()
    const allChartEvents = chartPane?.chartEvents().all()
    const quote = chartPane?.chart()?.quote()
    if (
      !chartPane ||
      !allChartEvents ||
      (chartEvents?.length &&
        allChartEvents.length === chartEvents.length &&
        !allChartEvents.some(
          ({ updateOhlcVersion, updateChartEventsVersion }) =>
            updateOhlcVersion !== quote?.updateOhlcVersion ||
            updateChartEventsVersion !== quote?.updateChartEventsVersion
        ))
    ) {
      return
    }

    allChartEvents.forEach((chartEvent) => chartEvent.destroyCascade())

    chartEvents?.forEach(({ elementId, eventType, dateTimestamp, updateOhlcVersion, updateChartEventsVersion }) => {
      const newChartEvent = chartPane.chartEvents().create<ChartEventElement>({
        instance: getCanvasElementByType(eventType)!.fromObject(
          { positionTimestamps: { x: dateTimestamp } },
          chartPane
        ),
        elementId,
        updateOhlcVersion,
        updateChartEventsVersion,
      })
      newChartEvent.instance.updateScales()
    })

    chartPane.updateChartEventsZIndexes()
  }

  getDateRangeBars() {
    const isPremium = window.FinvizSettings.hasUserPremium

    return getQuoteOptionsForDateRange({
      dateRange: this.dateRange,
      instrument: this.instrument,
      isPremium,
      hasPremarket: this.premarket,
      hasAftermarket: this.aftermarket,
    })
  }

  getQuoteBarsBufferAndCount() {
    /*
     * This has to be in sync with the logic
     * in getChartQuotes in offScreenCanvasRender.ts
     * & initChartRef in with-chart-init.tsx
     * & getQuoteBarsBufferAndCount in chart.ts
     */
    const emptyOptions = { barsCount: undefined, dateFrom: undefined, leftBuffer: undefined }

    if (this.quoteFetchBufferConfig?.barsBuffer === Infinity) {
      return emptyOptions
    }

    const chartLayout = this.chart_layout()
    const { barMargin, barWidth } = getChartLayoutSizeConfig(chartLayout.toConfig(), this.timeframe)
    const { barMarginWidth, barFillWidth, barBorderWidth } = getScaledBarWidthWithMarginByParts({
      zoomFactor: this.zoomFactor,
      barFillWidth: barWidth,
      barMarginWidth: barMargin,
      borderWidth: chartLayout.settings.ChartSettings.center.border,
    })

    const barWidthWithMargin = barMarginWidth + barFillWidth + barBorderWidth * 2

    const scrollBuffer = Math.ceil(CHART_BARS_BUFFER_IN_PX / barWidthWithMargin)
    const leftBuffer = Math.ceil(this.quoteFetchBufferConfig?.barsBuffer ?? 0) + scrollBuffer
    const dateFrom = this.quoteFetchBufferConfig?.dateFrom

    if (this.dateRange) {
      const dateRangeOptions = this.getDateRangeBars()
      const options = {
        barsCount: dateRangeOptions?.barsCount,
        leftBuffer: getMaxValue(dateRangeOptions?.leftBuffer, leftBuffer),
        dateFrom: getMinValue(dateRangeOptions?.dateFrom, dateFrom),
        dateTo: dateRangeOptions?.dateTo,
      }
      return options.leftBuffer === Infinity ? emptyOptions : options
    }

    return { barsCount: Math.ceil(this.width / barWidthWithMargin), leftBuffer, dateFrom }
  }
}

export default Chart
