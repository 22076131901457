import { ChartConfigChart } from '../../server/chartConfig'
import { IndicatorType } from '../constants/common'
import { indicatorsByType } from '../indicators/indicators'

export function getQuoteFinancialAttachmentsFromChartConfig({ panes }: Pick<ChartConfigChart, 'panes'>) {
  return panes
    .flatMap((pane) =>
      pane.elements?.flatMap((element) => {
        const indicatorObject = indicatorsByType[element.type as IndicatorType]
        if (!indicatorObject || !('getQuoteFinancialAttachments' in indicatorObject)) return []
        return indicatorObject.getQuoteFinancialAttachments(element) ?? []
      })
    )
    .filter((quoteFinancialAttachment, index, arr) => arr.indexOf(quoteFinancialAttachment) === index)
}
