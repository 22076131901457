import { Theme } from '../../types/shared'
import twColors from '../constants/colors'
import { ChartEventType } from '../constants/common'
import math, { BIG_NUMBER_VALUES, BigNumber } from '../helpers/math'
import utils from '../utils'
import { formatDateToStringUS, getEarningsDateSuffix } from './helpers'

interface IChartEventBase<T extends ChartEventType> {
  elementId: string
  eventType: T
  dateTimestamp: number // represent 'earningsDate' column of earnings and 'exdate' column for dividends & split
  updateOhlcVersion?: number
  updateChartEventsVersion?: number
}

export interface IEarnings extends IChartEventBase<ChartEventType.Earnings> {
  epsActual?: number
  epsEstimate?: number
  epsReportedActual?: number
  epsReportedEstimate?: number
  fiscalEndDate: number
  fiscalPeriod: string
  salesActual?: number
  salesEstimate?: number
}

export interface IDividends extends IChartEventBase<ChartEventType.Dividends> {
  ordinary: number
  special: number
}

export interface ISplit extends IChartEventBase<ChartEventType.Split> {
  factorFrom: number
  factorTo: number
}

export enum ChartEventBadgeState {
  default = 'default',
  hover = 'hover',
  selected = 'selected',
}

export interface IChartEventBadgeProps {
  theme: Theme
  state: ChartEventBadgeState
  width: number
}

interface IGetChartEventBadgeColors {
  theme: Theme
  state: ChartEventBadgeState
  colors: IBadgeColors
}

interface IBadgeStateColors {
  default: string
  hover: string
  selected: string
}

interface IBadgeThemeColors {
  outerStroke: string
  innerStroke: string
  innerFill: IBadgeStateColors
  textFill: IBadgeStateColors
}

interface IBadgeColors {
  light: IBadgeThemeColors
  dark: IBadgeThemeColors
}

export function getChartEventBadgeColors({ theme, state, colors }: IGetChartEventBadgeColors) {
  return {
    outerStroke: colors[theme].outerStroke,
    innerStroke: colors[theme].innerStroke,
    innerFill: colors[theme].innerFill[state],
    textFill: colors[theme].textFill[state],
  }
}

export function getFormattedEventDate(
  exdate: number,
  hasEarningsDateSuffix = false,
  options: ObjectHash = {
    month: 'short',
  }
) {
  const parsedDate = utils.dateFromUnixTimestamp(exdate)

  const formattedDate = formatDateToStringUS(parsedDate, options)

  if (hasEarningsDateSuffix) {
    return `${formattedDate} ${getEarningsDateSuffix(parsedDate)}`
  }

  return formattedDate
}

export function getFormattedValue({
  value,
  bigNumberUnit,
  maxDecimalPlaces = 3,
}: {
  value: number
  bigNumberUnit?: BigNumber
  maxDecimalPlaces?: number
}) {
  let numberString = ''
  let suffix = ''

  if (bigNumberUnit === undefined) {
    numberString = math.round({ value, overridePlaces: 3 })
  } else {
    const bigNumber = math.formatBigNumber(value * BIG_NUMBER_VALUES[bigNumberUnit], 3, BigNumber.Million)
    numberString = parseFloat(bigNumber.substring(0, bigNumber.length - 1)).toString() // parseFloat with toString are removing trailing zeroes
    suffix = bigNumber.substring(bigNumber.length - 1)
  }

  const [wholeNumber, decimalNumber] = numberString.split('.')

  if (decimalNumber === undefined) return `${wholeNumber}${suffix}`

  let formattedDecimalNumber = decimalNumber
  if (maxDecimalPlaces === 2 || decimalNumber[decimalNumber.length - 1] === '0') {
    formattedDecimalNumber = decimalNumber.slice(0, -1)
  }

  return `${[wholeNumber, formattedDecimalNumber].join('.')}${suffix}`
}

export function getChartEventBageColors(colorKey: 'violet' | 'blue' | 'red' | 'gray' | 'purple' | 'green') {
  return {
    light: {
      outerStroke: twColors.white,
      innerStroke: twColors[colorKey][400],
      innerFill: {
        default: twColors[colorKey][50],
        hover: twColors[colorKey][100],
        selected: twColors[colorKey][400],
      },
      textFill: {
        default: twColors[colorKey][500],
        hover: twColors[colorKey][600],
        selected: twColors.white,
      },
    },
    dark: {
      outerStroke: twColors.gray[900],
      innerStroke: twColors[colorKey][400],
      innerFill: {
        default: twColors[colorKey][800],
        hover: twColors[colorKey][600],
        selected: twColors[colorKey][400],
      },
      textFill: {
        default: twColors.white,
        hover: twColors.white,
        selected: twColors.white,
      },
    },
  } as IBadgeColors
}
