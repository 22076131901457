import { intervalCallbackOnWindowVisible } from '@finviz/website'
import debounce from 'lodash.debounce'
import React from 'react'

import { ObjectHash } from '../../../types/shared'
import { EXTRA_MARKET_BUFFER_MINUTES, QUOTE_FETCH_EVENT, QuotePollingIntervalInMs } from '../../constants/common'
import Chart from '../../models/chart'
import ChartLayoutModel from '../../models/chart_layout'
import Pane from '../../models/pane'
import { QuoteFetchType } from '../../models/quote/constants'

interface Props {
  chartLayoutModel: ChartLayoutModel
}

type PollingIntervalToChartsType = ObjectHash<string[], number>

/**
 * Get the max refresh interval when the tab is in the background. The interval
 * is slowly decayed from regular refresh interval
 */
function getMaxDecayInterval() {
  return QuotePollingIntervalInMs.Reduced
}

/*
 * This HOC loops through all charts and checks if they are supposed to be refetch in some interval
 * It creates groups of charts to be refreshed grouped by the refresh interval
 * */
export const withQuotePolling = (Component: any) => (props: Props) => {
  const [pollingIntervalToCharts, setPollingIntervalToCharts] = React.useState<PollingIntervalToChartsType>({})
  const checkForChartsWithPolling = React.useCallback(() => {
    // check for charts which have some refresh interval and group chart ids by the interval value e.g. { interval_in_ms: [id1, id2] }
    const newPollingIntervalToCharts: PollingIntervalToChartsType = {} // example { 60000: ['c-0', 'c-1'], 9000: ['c-4'] }
    props.chartLayoutModel.getAllCharts().forEach((chart) => {
      const chartRefreshInterval = chart.getRefreshInterval()

      if (chartRefreshInterval) {
        newPollingIntervalToCharts[chartRefreshInterval] = newPollingIntervalToCharts[chartRefreshInterval] ?? []
        newPollingIntervalToCharts[chartRefreshInterval].push(chart.id)
      }
    })

    if (JSON.stringify(newPollingIntervalToCharts) !== JSON.stringify(pollingIntervalToCharts)) {
      setPollingIntervalToCharts(newPollingIntervalToCharts)
    }
  }, [props.chartLayoutModel, pollingIntervalToCharts])
  const intervalSubscriptions = React.useRef<Array<() => void>>([])

  React.useEffect(() => {
    const checkForChartsWithPollingDebounced = debounce(checkForChartsWithPolling)
    const chartsCheckerIntervalId = window.setInterval(
      checkForChartsWithPollingDebounced,
      EXTRA_MARKET_BUFFER_MINUTES * 1000
    )

    // listen on any changes in all charts & panes and call debounced check for charts/indicators with quotes that needs to be refetched periodically
    Chart.bind('change', checkForChartsWithPollingDebounced)
    Pane.bind('change', checkForChartsWithPollingDebounced)

    return () => {
      checkForChartsWithPollingDebounced.cancel()
      Chart.unbind('change', checkForChartsWithPollingDebounced)
      Pane.unbind('change', checkForChartsWithPollingDebounced)
      window.clearInterval(chartsCheckerIntervalId)
    }
  }, [checkForChartsWithPolling])

  React.useEffect(() => {
    const timeoutDurations = Object.keys(pollingIntervalToCharts).map(Number)
    const allCharts = props.chartLayoutModel.getAllCharts()
    const timeoutDurationsMin = Math.min(...timeoutDurations)

    timeoutDurations.forEach((duration) => {
      const timeoutSubscription = intervalCallbackOnWindowVisible(
        { interval: duration, decay: true, maxDecayInterval: getMaxDecayInterval },
        () => {
          const chartIds = pollingIntervalToCharts[duration]
          // get all UNIQUE quotes used in charts and refetch them
          allCharts
            .filter((chart) => chartIds.includes(chart.id))
            .flatMap((chart) => chart.getAllQuotes())
            .filter(
              (quote, index, quotesToUpdate) =>
                !quote.getIsFetching([
                  QuoteFetchType.Refetch,
                  QuoteFetchType.DataChangeRefetch,
                  QuoteFetchType.NewerData,
                ]) && index === quotesToUpdate.findIndex((quoteToUpdate) => quote.eql(quoteToUpdate))
            )
            .forEach((quote) => {
              void quote.fetchData({ fetchType: QuoteFetchType.NewerData })
            })
          if (duration === timeoutDurationsMin) {
            document.dispatchEvent(new Event(QUOTE_FETCH_EVENT))
          }
        }
      )
      intervalSubscriptions.current.push(timeoutSubscription)
    })

    return () => {
      if (intervalSubscriptions.current.length > 0) {
        intervalSubscriptions.current.forEach((unsubscribe) => {
          unsubscribe()
        })
        intervalSubscriptions.current = []
      }
    }
  }, [props.chartLayoutModel, pollingIntervalToCharts])

  return <Component {...props} />
}
