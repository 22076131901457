import {
  Button,
  ButtonComponentProps,
  ButtonProps,
  Dropdown,
  DropdownItem,
  Spinner,
  Tooltip,
  TooltipTrigger,
  useDialogState,
  useTooltipState,
} from '@finviz/website'
import classnames from 'classnames'
import * as React from 'react'

import IdeasDialog from '../../modals/ideas/Ideas'
import { saveIdea } from '../../modals/ideas/utils'
import ChartLayout from '../../models/chart_layout'
import Quote from '../../models/quote'
import { getIsMobileScreenSize } from '../../utils'
import { encodeQueryString } from '../../utils/query_string'

interface IdeaDropdownProps {
  model: ChartLayout
  rounding?: 'none' | 'regular'
  theme?: ButtonProps['theme']
  size?: ButtonProps['size']
  dropdownButtonClassName?: string
}

export function IdeasDropdown({
  model,
  rounding,
  dropdownButtonClassName,
  ...props
}: React.PropsWithChildren<IdeaDropdownProps>) {
  const tooltipState = useTooltipState({ placement: 'right-start' })
  const [isAnyQuoteLoading, setIsAnyQuoteLoading] = React.useState(false)
  const ref = React.useRef(null)
  const dialog = useDialogState()
  const hideTooltip = tooltipState.hide

  const dropdownItemProps = isAnyQuoteLoading
    ? ({
        as: TooltipTrigger,
        state: tooltipState,
      } as ButtonComponentProps<typeof TooltipTrigger>)
    : ({} as ButtonComponentProps)

  React.useEffect(() => {
    const checkIfAnyQuoteIsLoading = () => {
      const fetchingQuotes = Quote.select<Quote>((quote) => quote.getIsFetching() && quote.fetchedAt === -1)
      setIsAnyQuoteLoading(fetchingQuotes.length > 0)
    }

    Quote.bind('change', checkIfAnyQuoteIsLoading)
    return () => {
      Quote.unbind('change', checkIfAnyQuoteIsLoading)
    }
  }, [])

  React.useEffect(() => {
    if (!isAnyQuoteLoading) hideTooltip()
  }, [isAnyQuoteLoading, hideTooltip])

  if (!window.FinvizSettings.hasUserPremium) {
    return (
      <Button
        {...props}
        as="a"
        href={`/elite.ashx?${encodeQueryString({
          utm_source: 'finviz',
          utm_medium: 'banner',
          utm_campaign: 'quote-ideas-button',
        })}`}
        leftContent="idea"
        className={dropdownButtonClassName}
        data-testid="chart-toolbar-ideas"
        contentClass="hidden sm:flex"
        appearance={props.children ? undefined : 'square'}
        title={props.children ? undefined : 'Ideas'}
        rounding={rounding}
      />
    )
  }

  return (
    <>
      <Dropdown
        ref={ref}
        aria-label="Idea"
        rounding={rounding}
        trigger={
          <Button
            {...props}
            leftContent="idea"
            className={dropdownButtonClassName}
            data-testid="chart-toolbar-ideas"
            contentClass="hidden sm:flex"
            appearance={props.children ? undefined : 'square'}
            title={props.children ? undefined : 'Ideas'}
            rounding={rounding}
          />
        }
      >
        <DropdownItem
          {...dropdownItemProps}
          as={dropdownItemProps.as}
          rounding={rounding}
          leftContent="ideaSave"
          data-testid="charts-layout-save-idea-btn"
          hideOnClick={false}
          className="cursor-pointer"
          onClick={() => {
            if (isAnyQuoteLoading) {
              tooltipState.show()
              return
            }
            void saveIdea(model)
          }}
        >
          Save idea
        </DropdownItem>
        <DropdownItem
          leftContent="ideaLoad"
          data-testid="charts-layout-load-idea-btn"
          rounding={rounding}
          onClick={() => dialog.show()}
        >
          Load idea
        </DropdownItem>
      </Dropdown>
      <IdeasDialog state={dialog} />
      {isAnyQuoteLoading && (
        <Tooltip state={tooltipState} className="flex">
          <div className="mt-1">
            <Spinner width={12} />
          </div>
          <span className={classnames('ml-2', { 'w-40': getIsMobileScreenSize() })}>
            Some data is still loading. Please wait.
          </span>
        </Tooltip>
      )}
    </>
  )
}
