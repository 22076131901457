import { Instrument } from '../../types/shared'
import {
  DateRangeType,
  MARKET_END,
  MARKET_START,
  PREMARKET_AFTERMARKET_HOURS,
  TIMEFRAME,
  TIMEFRAME_SECONDS,
} from '../constants/common'
import { getChartStartEndDates } from '../controllers/renderUtils'

interface Props {
  dateRange: string
  instrument: Instrument
  isPremium: boolean
  hasPremarket: boolean
  hasAftermarket: boolean
}

export function getQuoteOptionsForDateRange({ dateRange, instrument, hasPremarket, hasAftermarket, isPremium }: Props) {
  const is1DayRange = dateRange === DateRangeType.d1
  const is5DayRange = dateRange === DateRangeType.d5
  if (is1DayRange || is5DayRange) {
    const timeframeMinutes = TIMEFRAME_SECONDS[is5DayRange ? TIMEFRAME.i5 : TIMEFRAME.i1] / 60
    const preMarketMarketMinutes = hasPremarket && isPremium ? PREMARKET_AFTERMARKET_HOURS * 60 : 0
    const afterMarketMinutes = hasAftermarket && isPremium ? PREMARKET_AFTERMARKET_HOURS * 60 : 0

    const startMs = new Date().setHours(MARKET_START.hours, MARKET_START.minutes, 0, 0)
    const endMs = new Date().setHours(MARKET_END.hours, MARKET_END.minutes, 0, 0)
    const marketMinutes =
      instrument === Instrument.Stock ? (endMs - startMs) / 60_000 + preMarketMarketMinutes + afterMarketMinutes : 1440
    const barsInADay = marketMinutes / timeframeMinutes
    let barsCount = 0

    if (is1DayRange) {
      barsCount = barsInADay
    } else if (is5DayRange) {
      barsCount = barsInADay * 5
    }

    return {
      barsCount,
    }
  }
  const { start } = getChartStartEndDates({ dateRange })

  const dateFrom = start ? Math.floor(start.getTime() / 1000) : undefined
  // Original code left commented out as a reference for when/if we revisit whether we can start using dateTo again
  const dateTo = undefined // end && getIsSSr() ? Math.ceil(end.getTime() / 1000) : undefined

  // This didn't work correctly on SSR when we allowed dateTo to be defined,
  // because on DR MAX dateTo wasn't undefined and thus leftBuffer wasn't Infinity.
  // Because of this and the fact that left buffer was always set, we never fetched all data.
  // also, undefined serves as a HOTFIX for patterns, patterns needs data up to newest
  if (!dateFrom && !dateTo) {
    return {
      leftBuffer: Infinity,
    }
  }

  return {
    dateFrom,
    dateTo,
  }
}
