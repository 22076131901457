import { PaneArea, ResizeByThumbWithTypeAndDifs } from '../../types/shared'
import { CanvasElementType, TextBaseline } from '../constants/common'
import { getRoundedObject } from '../controllers/renderUtils'
import PaneModel from '../models/pane'
import { numberToStringWithUnitsSuffix } from '../utils'
import { getRoundedString } from '../utils/helpers'
import Element from './element'
import Text, { ITextAttrs } from './text'
import Thumb from './thumb'

interface Coordinates {
  x1: number
  x2: number
  y1: number
  y2: number
}

export interface IMeasureAttrs extends Coordinates {
  fill: string
}

class Measure<Attrs extends IMeasureAttrs = IMeasureAttrs> extends Element<Attrs> {
  static type = CanvasElementType.measure

  name = 'Measure'
  declare scaled: Pick<Attrs, 'x1' | 'x2' | 'y1' | 'y2'>

  x1Dif: number
  x2Dif: number
  startingX1: number
  startingX2: number

  constructor(values: Partial<Attrs>, model: PaneModel) {
    super(values, model)
    this.resize = this.resize.bind(this)
    this._thumbs = [
      new Thumb(
        'tl',
        () => this.attrs.x1,
        () => this.attrs.y1,
        this.resize,
        this.model
      ),
      new Thumb(
        'br',
        () => this.attrs.x2,
        () => this.attrs.y2,
        this.resize,
        this.model
      ),
    ]
    this.scale(this.getBoundingPointKeys())
    this.x1Dif = this.x2Dif = 0

    this.startingX1 = this.attrs.x1
    this.startingX2 = this.attrs.x2
  }

  getBoundingPointKeys = () => ({ x: ['x1', 'x2'], y: ['y1', 'y2'] })

  getVolumeSumString = () => {
    const { volume, barIndex }: { volume: number[]; barIndex: number[] } = this.model.chart().quote()
    const roundedX1 = Math.round(this.attrs.x1)
    const roundedX2 = Math.round(this.attrs.x2)
    // min/max values are offset by 1 so there's no value on such index
    // and at the same time it won't iterate over unlimited number of nonexisting indexes
    const minIndexLimit = barIndex[0] - 1
    const maxIndexLimit = barIndex[barIndex.length - 1] + 1
    const volMinIndex = Math.min(Math.max(Math.min(roundedX1, roundedX2), minIndexLimit), maxIndexLimit)
    const volMaxIndex = Math.min(Math.max(Math.max(roundedX1, roundedX2), minIndexLimit), maxIndexLimit)

    let volumeSum = 0
    for (let i = volMinIndex; i <= volMaxIndex; i++) {
      const volumeIndex = barIndex.indexOf(i)
      if (volumeIndex >= 0) {
        volumeSum += volume[volumeIndex]
      }
    }

    return `Vol ${numberToStringWithUnitsSuffix(volumeSum)}`
  }

  renderContent(context: CanvasRenderingContext2D) {
    context.set('fillStyle', this.attrs.fill)
    const { x1, x2, y1, y2 } = this.scaled
    const roundedXY = getRoundedObject({ x1, x2, y1, y2 })

    const width = roundedXY.x2 - roundedXY.x1
    const height = roundedXY.y2 - roundedXY.y1
    context.fillRect(x1, roundedXY.y1, width, height)

    const priceDif = this.attrs.y2 - this.attrs.y1
    const percentage = (100 / this.attrs.y1) * priceDif
    const heightStr = `${priceDif.toFixed(2)} (${percentage.toFixed(2)}%)`

    const text = new Text(
      {
        text: `${getRoundedString({ number: Math.abs(this.attrs.x2 - this.attrs.x1), numOfDecimalPlaces: 2 })} bars`,
        fillStyle: '#333',
        font: { size: 10, weight: 'bold' },
        textBaseline: TextBaseline.middle,
      },
      this.model
    )
    const baseX = x1 + width / 2
    const baseY = roundedXY.y1 + height / 2
    let textWidth = text.measure(context)
    text.set({
      x: baseX - textWidth / 2,
      y: baseY - text.getHeight(),
    } as Partial<ITextAttrs>)
    text.render(context)

    text.set({ text: heightStr })
    textWidth = text.measure(context)
    text.set({
      x: baseX - textWidth / 2,
      y: baseY,
    } as Partial<ITextAttrs>)
    text.render(context)

    text.set({ text: this.getVolumeSumString() })
    textWidth = text.measure(context)
    text.set({
      x: baseX - textWidth / 2,
      y: baseY + text.getHeight(),
    } as Partial<ITextAttrs>)
    text.render(context)

    if (this.getShouldRenderThumbs()) {
      this.renderThumbs(context)
    }
  }

  addToCoordinates(difs: Partial<Coordinates>) {
    this.attrs.y1 += difs.y1 || 0
    this.attrs.y2 += difs.y2 || 0

    this.x1Dif += difs.x1 || 0
    this.x2Dif += difs.x2 || 0

    // The reason for adding +1 is to "ceil" the number when moving to the left (decreasing bar index)
    const newX1 = ~~(this.startingX1 + this.x1Dif) + (this.x1Dif < 0 ? 1 : 0)
    if (~~this.attrs.x1 - newX1 !== 0) {
      this.attrs.x1 = newX1
    }
    const newX2 = ~~(this.startingX2 + this.x2Dif) + (this.x2Dif < 0 ? 1 : 0)
    if (~~this.attrs.x2 - newX2 !== 0) {
      this.attrs.x2 = newX2
    }
  }

  moveBy(x: number, y: number) {
    return this.addToCoordinates({
      x1: x,
      y1: y,
      x2: x,
      y2: y,
    })
  }

  resize({ type, difX, difY }: ResizeByThumbWithTypeAndDifs) {
    if (type === 'tl') {
      this.addToCoordinates({ x1: difX, y1: difY })
    } else {
      this.addToCoordinates({ x2: difX, y2: difY })
    }
  }

  isInArea(area: PaneArea) {
    if (super.isDrawingElementLockedOrInvisible()) return false
    const x1 = Math.round(this.attrs.x1)
    const x2 = Math.round(this.attrs.x2)

    const left = Math.min(x1, x2)
    const right = Math.max(x1, x2)
    const top = Math.min(this.attrs.y1, this.attrs.y2)
    const bottom = Math.max(this.attrs.y1, this.attrs.y2)
    if (left < area.x && area.x < right && top < area.y && area.y < bottom) {
      return true
    }
    return super.isInArea(area)
  }

  onMouseDown(paneArea: PaneArea) {
    super.onMouseDown(paneArea)
    this.startingX1 = this.attrs.x1
    this.startingX2 = this.attrs.x2
  }

  onMouseUp(paneArea?: PaneArea) {
    super.onMouseUp(paneArea)
    this.x1Dif = this.x2Dif = 0
  }
}

Measure.prototype.defaults = { fill: 'rgba(180,220,255,0.8)' }

Measure.prototype.modalConfig = {
  inputs: [{ type: 'background', name: 'fill' }],
}

export default Measure
