import { PaneArea } from '../../types/shared'
import Element, { DefaultAttrs } from '../canvas/element'
import BaseChart from '../charts/base_chart'
import { ChartEventBadgeType, ChartEventType, TIMEFRAME } from '../constants/common'
import { getRoundedObject } from '../controllers/renderUtils'
import Chart from '../models/chart'
import ChartEventElement from '../models/chart-event-element'
import { CHART_EVENT_BADGE_SIZE } from '../models/constants'
import type PaneModel from '../models/pane'
import Utils from '../utils'
import { ChartEventBadgeState } from '../utils/chart-events-utils'

const isMobile = Utils.isMobile()

interface ChartEventAttrs extends DefaultAttrs {
  x: number
}

function getChartEventState(chartEvent: ChartEvent): ChartEventBadgeState {
  if (chartEvent.isOpen) {
    return ChartEventBadgeState.selected
  }
  if (chartEvent.isHovered) {
    return ChartEventBadgeState.hover
  }
  return ChartEventBadgeState.default
}

class ChartEvent<Attrs extends ChartEventAttrs = ChartEventAttrs> extends Element<Attrs> {
  static type = ChartEventType.ChartEvent
  static label = 'ChartEvent'

  declare scaled: Pick<Attrs, 'x'>

  declare chart: BaseChart

  declare model: PaneModel

  declare chartEventModel: ChartEventElement | undefined

  isOpen = false

  left = 0
  top = 0

  constructor(values: Partial<Attrs>, model: PaneModel) {
    super(values, model)
    this.scale(this.getBoundingPointKeys())
  }

  getBoundingPointKeys = () => ({ x: ['x'], y: [] })

  get type() {
    return (this.constructor as typeof ChartEvent).type
  }

  get label() {
    return (this.constructor as typeof ChartEvent).label
  }

  getChartEvenBadgeType(): ChartEventBadgeType {
    switch (this.type) {
      case ChartEventType.Dividends:
        return ChartEventBadgeType.Dividends
      case ChartEventType.Split:
        return ChartEventBadgeType.Split
    }

    return ChartEventBadgeType.EarningsNeutral
  }

  getChartEventModel(): ChartEventElement | undefined {
    if (!this.chartEventModel) {
      this.chartEventModel = this.model.chartEvents()?.findByAttribute('instance', this)
    }

    return this.chartEventModel
  }

  getXYPosition() {
    const { top, bottom } = this.getChartLayoutSettings().ChartSettings
    const chartEventModel = this.getChartEventModel()
    const { x } = this.scaled
    const roundedXY = getRoundedObject({ x })
    const y =
      (this.model as PaneModel).height -
      top.height -
      bottom.height -
      CHART_EVENT_BADGE_SIZE -
      (chartEventModel?.yIndex ?? 0) * CHART_EVENT_BADGE_SIZE

    return { x: roundedXY.x - CHART_EVENT_BADGE_SIZE / 2, y }
  }

  getIsVisible() {
    return ![TIMEFRAME.w, TIMEFRAME.m].includes(this.model.chart().quote()?.timeframe)
  }

  renderContent(context: CanvasRenderingContext2D) {
    if (!this.getIsVisible() || !this.chartEventModel) return

    this.attrs.x = Math.round(this.attrs.x)
    const chart = this.model.chart()
    const chartLayout = chart.chart_layout()
    const { x, y } = this.getXYPosition()
    const buffer = 10 + CHART_EVENT_BADGE_SIZE
    if (x + chart.leftOffset < -buffer || x + chart.leftOffset > chart.width + buffer) {
      return
    }
    const item = this.chartEventModel.getChartEventBadgeImage({
      theme: chartLayout.theme,
      state: getChartEventState(this),
      badgeType: this.getChartEvenBadgeType(),
    })

    if (!item) return

    const { left, top } = this.getChartLayoutSettings().ChartSettings
    this.left = x + chart.leftOffset + left.width + CHART_EVENT_BADGE_SIZE / 2
    this.top = y + top.height
    context.drawImage(item, x, y, CHART_EVENT_BADGE_SIZE, CHART_EVENT_BADGE_SIZE)
  }

  setIsHovered(isHovered: boolean) {
    if (!this.getIsVisible()) return

    this.isHovered = isHovered
    this.model.updateChartEventsZIndexes()
    this.trigger('change')
  }

  // If isOpenOverride is undefined, the function works as toggle
  toggleIsOpen(isOpenOverride?: boolean) {
    const chartLayout = this.model.chart().chart_layout()
    const chartEventModel = this.getChartEventModel()

    const isCurrentEventActive = chartLayout.activeChartEvent?.id === chartEventModel?.id
    const isOpen = isOpenOverride ?? (isCurrentEventActive ? !chartEventModel?.instance.isOpen : true)
    this.isOpen = isOpen
    // Due to the fact that chart events logic depends heavily on hover state which isn't really present on touch devices
    // we need to mimic that state for touch devices, this enables chart event to be open, and counterpart in
    // chart-event-popover-with-state.tsx allow chart event to be closed on click outside
    if (isMobile) {
      this.isHovered = isOpen
    }
    if (chartLayout.activeChartEvent?.instance.isOpen && !isCurrentEventActive) {
      chartLayout.activeChartEvent?.instance.toggleIsOpen(false)
    }
    if (isOpen || isCurrentEventActive) {
      chartLayout.updateAttribute('activeChartEvent', !isOpen && isCurrentEventActive ? undefined : chartEventModel)
    }

    this.model.updateChartEventsZIndexes()
    this.trigger('change')
  }

  isInArea(area: PaneArea) {
    if (!this.getIsVisible()) return false

    const scaledArea = area.scaled
    const { x, y } = this.getXYPosition()
    const isInArea =
      x <= scaledArea.x &&
      x + CHART_EVENT_BADGE_SIZE >= scaledArea.x &&
      y <= scaledArea.y &&
      y + CHART_EVENT_BADGE_SIZE >= scaledArea.y
    return isInArea
  }

  getIsInChartView(chart: Chart) {
    if (!this.getIsVisible()) return false

    const { x } = this.getXYPosition()
    const isInView = x + CHART_EVENT_BADGE_SIZE >= -chart.leftOffset && x <= -chart.leftOffset + chart.width
    return isInView
  }
}

export default ChartEvent
